/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import {SpeakerphoneIcon, XIcon} from '@heroicons/react/outline'

export default function RebrandBanner() {
  return (<div className="bg-indigo-600">
    <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between flex-wrap">
        <div className="w-0 flex-1 flex items-center">
          <span className="flex p-2 rounded-lg bg-indigo-800">
            <SpeakerphoneIcon className="h-6 w-6 text-white"
                              aria-hidden="true"/>
          </span>
          <p className="ml-3 font-medium text-white truncate">
            <span className="">
              West Coast Dynamics is rebranding to Aerbits.
            </span>
          </p>
        </div>
        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
          <a href="https://www.aerbits.com"
             className="flex items-center justify-center
               px-4 py-2 border border-transparent rounded-md shadow-sm text-sm
               font-medium text-indigo-600 bg-white hover:bg-indigo-50">Check us out at Aerbits.com </a>
        </div>
      </div>
    </div>
  </div>)
}
