import React from "react";
import {useEffect, useRef} from "react";
import {createMap} from "maplibre-gl-js-amplify";
import {createAmplifyGeocoder, AmplifyGeofenceControl} from "maplibre-gl-js-amplify";
import {} from "mapbox-gl"
import "maplibre-gl/dist/maplibre-gl.css";
import "./maps.css";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import "maplibre-gl-js-amplify/dist/public/amplify-ctrl-geofence.css";


function SignupMap({center}) {
  const mapRef = useRef(null);

  useEffect(() => {
    let map;

    async function initializeMap() {
      if (mapRef.current != null) {
        map = await createMap({
          container: mapRef.current,
          center: [
            center.long,
            center.lat
          ],
          zoom: 15,
        });
        map.resize();
        let hoveredStateId = null;
        map.on("load", function () {
          map.addSource("street", {
            type: "geojson",
            data: "/assets/bayview.geojson",
          });
          map.addLayer({
            id: "street-layer",
            type: "line",
            source: "street",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-width": 20,
              "line-opacity": [
                "case",
                [
                  "boolean",
                  [
                    "feature-state",
                    "selected"
                  ],
                  false
                ],
                1,
                0.5,
              ],
              "line-color": [
                "case",
                [
                  "boolean",
                  [
                    "feature-state",
                    "selected"
                  ],
                  false
                ],
                "#0F0",
                "#0G0",
              ],
            },
          });
          map.on("mouseover", "street-layer", function (e) {
            console.log(e);
            if (e.features.length > 0) {
              if (hoveredStateId) {
                map.setFeatureState({
                  source: "street",
                  id: hoveredStateId
                }, {hover: false});
              }
              hoveredStateId = e.features[0].id;
              map.setFeatureState({
                source: "street",
                id: hoveredStateId
              }, {hover: true});
            }
          });
          map.on("mouseleave", "street-layer", () => {
            if (hoveredStateId !== null) {
              map.setFeatureState({
                source: "street",
                id: hoveredStateId
              }, {hover: false});
            }
            hoveredStateId = null;
          });
          map.on("click", "street-layer", function (e) {
            console.log(e);
            if (e.features.length > 0) {
              if (!e.features[0].state.selected) {
                map.setFeatureState({
                  source: "street",
                  id: e.features[0].id
                }, {selected: true});
              } else {
                map.setFeatureState({
                  source: "street",
                  id: e.features[0].id
                }, {selected: false});
              }
            }
          });
        });
        map.addControl(createAmplifyGeocoder());
      }
    }

    initializeMap();
    return function cleanup() {
      if (map != null) map.remove();
    };
  }, []);

  return (<div className="max-w-full mx-auto px-0 h-full">
      <div className="bg-white h-full rounded-full">
        <div className="py-0 h-full rounded-full">
          <div ref={mapRef}
               id="map"
               className="h-full rounded-lg"/>
        </div>
      </div>
    </div>);
}

export default SignupMap;
