import {Popover } from "@headlessui/react";
import {Link, Outlet} from "react-router-dom";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import React from "react";
import RebrandBanner from "./RebrandBanner";

export default function Public() {
  return (
    <>
      <header>
        <RebrandBanner/>
        <Popover className="relative bg-gray-50">
          <div
            className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link
                to="/"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Logo-Home"
              >
                <span className="sr-only">
                    West Coast Dynamics - Neighborhood Intelligence
                </span>
                <img
                  className="h-32 w-auto sm:h-32"
                  src="/assets/west%20coast%20dynamics-logos_black_small.png"
                  alt="West Coast Dynamics"
                />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Open-Menu"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center
                                text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none
                                focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
              </Popover.Button>
            </div>
            {/*Desktop menu*/}
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <Link
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Menu-Home"
                to="/"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >Home
              </Link>
              <Link
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Menu-About Us"
                to="/about"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                About Us
              </Link>
              <Link
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Menu-Blog"
                to="/blog"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Blog
              </Link>
              <Link
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Menu-Pricing-SF"
                to="/availability"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Availability
              </Link>
              <Link
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Menu-About-Us"
                to={"/contact"}
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Contact Us
              </Link>
            </Popover.Group>
          </div>
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                      alt="West Coast Dynamics"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="Menu-Close-Menu"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400
                    hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset
                    focus:ring-indigo-500"
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true"/>
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                {/*Mobile menu*/}
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="Menu-Home"
                    to={"/"}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >

                    Home
                  </Link>
                  <Link
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="Menu-About-Us"
                    to={"/about"}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >

                    About Us
                  </Link>
                  <Link
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="Menu-Blog"
                    to={"/blog"}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >

                    Blog
                  </Link>
                  <Link
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="Menu-Pricing-SF"
                    to={"/availability"}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >

                    Availability
                  </Link>
                  <Link
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="Menu-Contact-Us"
                    to={"/contact"}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >

                    Contact Us
                  </Link>

                </div>
              </div>
            </div>
          </Popover.Panel>
        </Popover>
      </header>
      <Outlet/>
      <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto pt-2 pb-8 px-4 sm:px-6 lg:pt-2 lg:px-8">
          <div className="xl:grid xl:gap-8">
            <div className="mt-4 xl:mt-0">
              <div className="mt-2 border-gray-200 pt-2 items-center justify-between lg:mt-2 content-center">
                <p className="mt-8 text-gray-400 md:mt-0 md:order-1 text-center">
                  &copy; 2022 Everyone Codes, Inc. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
