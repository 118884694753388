function euclideanDistance(a, b) {
  const latDiff = a.lat - b.lat;
  const lngDiff = a.long - b.long;
  return Math.sqrt(latDiff * latDiff + lngDiff * lngDiff);
}

export function findCentroid(points) {
  const totalPoints = points.length;
  const centroid = { lat: 0, long: 0 };

  for (const point of points) {
    centroid.lat += point.lat;
    centroid.long += point.long;
  }

  centroid.lat /= totalPoints;
  centroid.long /= totalPoints;
  return centroid;
}

export function kMeansCluster(points, k, maxIterations = 100) {
  if (points.length < k) {
    k = points.length;
  }
  const centroids = [];
  const clusters = Array.from({ length: k }, () => []);

  for (let i = 0; i < k; i++) {
    centroids.push(points[i]);
  }

  let iterations = 0;
  let shouldContinue = true;

  while (shouldContinue && iterations < maxIterations) {
    shouldContinue = false;

    // Clear clusters
    for (let i = 0; i < k; i++) {
      clusters[i] = [];
    }

    // Assign each point to the nearest centroid
    for (const point of points) {
      let minDist = Infinity;
      let closestCentroidIndex = 0;

      for (let i = 0; i < k; i++) {
        const dist = euclideanDistance(point, centroids[i]);
        if (dist < minDist) {
          minDist = dist;
          closestCentroidIndex = i;
        }
      }

      clusters[closestCentroidIndex].push(point);
    }

    // Update centroids
    for (let i = 0; i < k; i++) {
      const newCentroid = findCentroid(clusters[i]);
      if (!Object.is(centroids[i], newCentroid)) {
        shouldContinue = true;
        centroids[i] = newCentroid;
      }
    }

    iterations++;
  }

  return { centroids, clusters };
}
