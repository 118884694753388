import React from "react";

export default function NotAvailable() {
  return (
    <main className="overflow-hidden bg-gray-100">
      {/* Header */}
      <div className="bg-gray-100">
        <div className="py-24 lg:py-20">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
              We're not available there, yet!
            </h1>
            <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              Thanks for checking. If we get enough interest we might just fly
              out and do some test flights in your area.
            </p>
            <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              If you want to chat with me, Brian, the Founder, use this link:
            </p>
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <a href="https://westcoastdynamics.pipedrive.com/scheduler/lp2dGDTo/west-coast-dynamics">
                <button
                  type="submit"
                  className="mt-6 flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                >
                  Reserve a time on my calendar
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
