/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "c510958dec27448c9a825a1a0f851e63",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "c510958dec27448c9a825a1a0f851e63",
            "region": "us-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0u6han1hkc.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://r6j4gjhegvdrzj5sqs6tx3c33q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-apxpxvnnnnhzddpy5fwcunqqua",
    "aws_cognito_identity_pool_id": "us-west-2:1c8c5e4e-f572-4183-95b6-773b7ca74927",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_fogoEJTc7",
    "aws_user_pools_web_client_id": "508mn2fkdqs2o330oohnkd1kq0",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "TrainingDatapoint-prod",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "westcoastdynamicsf1a2a5a2330347fb85a581221838aa83941-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "geo": {
        "amazon_location_service": {
            "region": "us-west-2",
            "maps": {
                "items": {
                    "WCDdashmap-prod": {
                        "style": "VectorEsriStreets"
                    },
                    "map7f17b3b9-prod": {
                        "style": "VectorEsriStreets"
                    },
                    "mapfe7504e7-prod": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "mapfe7504e7-prod"
            },
            "geofenceCollections": {
                "items": [
                    "geofenceCollection01f215b9-prod"
                ],
                "default": "geofenceCollection01f215b9-prod"
            },
            "search_indices": {
                "items": [
                    "westcoastdynamicsearch-prod"
                ],
                "default": "westcoastdynamicsearch-prod"
            }
        }
    }
};


export default awsmobile;
