import {useState, useCallback, useEffect} from 'react'

function getTimeDifferenceInHours(date1, date2) {
    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = date2 - date1;
    // Convert the difference in milliseconds to hours
    return differenceInMilliseconds / 100000 / 60 / 60;
}


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const formatDate = (date) => {
    const dateFormatToTime = new Date(date);
    return dateFormatToTime.toLocaleDateString()
}

const calculateClosedTime = (d) => {
    const cleanup = d?.cleanupRequest
    if (!cleanup?.status || cleanup?.status === 'closed')
        return ''
    const date1 = new Date(Date.parse(cleanup.createdAt));
    const date2 = new Date(Date.parse(cleanup.updatedAt));
    const timeDifference = getTimeDifferenceInHours(date1, date2); // returns 12
    return `Closed in ${Math.round(timeDifference)} hours`;
}

export default function ReportedDumpsite({detection}) {

    return (
        <div
            className={classNames(detection.current ? 'relative ring-2 ring-offset-2 ring-indigo-500' : 'relative focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500', 'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden')}>
                                            <span
                                                className={"w-200 h-5 left-0 top-0 absolute p-5 block z-50 text-sm font-medium text-white text-left truncate pointer-events-none"}
                                                style={{
                                                    background: `rgba(100, 100, 100, 0.5)`,
                                                }}>
                                            <p>Reported {formatDate(detection.captureDatetime)} {calculateClosedTime(detection)}</p>
                                            </span>
            <img
                src={"https://d2cwu4hex8j4mq.cloudfront.net/public/digest/" + detection.imageURL}
                alt=""
                className={classNames(detection.current ? '' : 'group-hover:opacity-75', 'object-cover pointer-events-none')}/>
            <button type="button"
                    className="absolute inset-0 focus:outline-none">
                <span className="sr-only">View details for {detection.name}</span>
            </button>
        </div>
    )
}