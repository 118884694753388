import React from "react";
import { Link } from "react-router-dom";

export default function WereAvailable() {
  return (
    <main className="overflow-hidden bg-gray-100">
      {/* Header */}
      <div className="bg-gray-100">
        <div className="py-24 lg:py-20">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
              Awesome! We are available in 94124!
            </h1>
            <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              Sign up for one of our subscriptions now. We'll be in touch over
              email to learn about your specific needs.
            </p>
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <Link to="/pricing">
                <button
                  type="submit"
                  className="mt-6 flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 
                  bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm
                  text-white hover:from-purple-700 hover:to-indigo-700"
                >
                  Sign up now
                </button>
              </Link>
            </div>
            <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              Or, if you want to chat about options, I am available to talk
              through the program with you.
            </p>
            <a href="https://usemotion.com/meet/everyone-codes/f9239n6?d=30">
              <button
                type="submit"
                className="mt-6 flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600
                bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm
                text-white hover:from-purple-700 hover:to-indigo-700"
              >
                Reserve a video chat
              </button>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
