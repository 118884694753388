import React from "react";

export default function Thanks() {
  return (
    <main className="overflow-hidden bg-gray-100">
      {/* Header */}
      <div className="bg-gray-100">
        <div className="py-24 lg:py-20">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
              We'll be in touch
            </h1>
            <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              Thank you for joining our mission to clean up San Francisco! We
              truly appreciate your commitment and dedication to improving this
              amazing city.{" "}
            </p>
            <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              As a volunteer, you will receive notifications about upcoming
              events and opportunities to help out with the cleanup effort. You
              can also check back on westcoastdynamics.com for updates on what’s
              happening with the project.
            </p>
            <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              If you want to chat face to face, or via Zoom, schedule and
              appointment with me, Brian, the Founder, using this link:
            </p>
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <a href="https://usemotion.com/meet/everyone-codes/f9239n6?d=30">
                <button
                  type="submit"
                  className="mt-6 flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                >
                  Book a one on one chat with Brian
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
