import React from "react";
import { Link } from "react-router-dom";
import SubscribeForm from "./SubscribeForm";
import SignupMap from "./SignupMap";
import { Analytics } from "@aws-amplify/analytics";

export default function Signup() {
  Analytics.record({
    name: "View Bayview Signup",
  });
  return (
    <>
      <main>
        <div className="bg-white">
          <div className="w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div className="rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-3 lg:gap-4 bg-yellow-400 ">
              <div className="col-span-2 pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-6 lg:pr-0 xl:py-10 xl:px-20">
                <div className="lg:self-center">
                  <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
                    <span className="block">Ready for a clean sidewalk?</span>
                    <span className="block mt-12">
                      Automatically report illegal dumping.
                    </span>
                  </h2>
                  <p className="mt-12 text-xl leading-6 text-black">
                    Having clean streets and sidewalks can feel amazing!
                  </p>
                  <p className="mt-4 text-xl leading-6 text-black">
                    Yet, making 311 requests to SF DPW every week, or sometimes
                    every day feels tiresome.
                  </p>
                  <p className="mt-4 text-xl leading-6 text-black">
                    It doesn't have to, because this little bot can do it for
                    you!
                  </p>
                  <img className="margin-auto" src="assets/mavic_v_open.png" />
                </div>
                <div className="w-full h-1/2 text-center">
                  <Link
                    to="/pricing"
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="Click How Can I Help"
                    className="mt-2 bg-red-700 border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white hover:bg-gray-700"
                  >
                    Sign up now!
                  </Link>
                </div>
              </div>
              <div className="-mt-6 aspect-w-9 aspect-h-6 md:aspect-w-4 md:aspect-h-3">
                <img
                  className="transform translate-x-1 translate-y-14 rounded-md object-cover object-right-top sm:translate-x-10 lg:translate-y-10"
                  src="assets/Trash_visual.png"
                  alt="App screenshot"
                />
              </div>
            </div>
          </div>
          
        </div>
      </main>
    </>
  );
}
