/* This example requires Tailwind CSS v2.0+ */
import {PhoneIcon, PhotographIcon} from '@heroicons/react/solid'
import {API, graphqlOperation} from "aws-amplify";
import {searchTrainingDatapoints} from "./graphql/queries";
import {useCallback, useEffect, useState} from "react";
import {ImageAnnotator} from "./ImageAnnotator";
import {useNavigate, useParams} from "react-router-dom";

export default function Dataset() {
  const {id} = useParams();
  const history = useNavigate();
  const [datapoints, setDatapoints] = useState([]);
  const [datapoint, setDatapoint] = useState(null);

  const getDatasetEntries = useCallback(async () => {
    let results = [];
    let nextToken = null;
    const limit = 50;
    let loops = 0;
    while (true) {
      const trainingData = await API.graphql(graphqlOperation(searchTrainingDatapoints, {
        nextToken,
        limit: 25
      }));
      const datapoints = trainingData?.data?.searchTrainingDatapoints?.items;
      results = [].concat(results, datapoints);
      nextToken = trainingData?.data?.searchTrainingDatapoints?.nextToken;
      if (!nextToken || loops >= limit) {
        break;
      }
      loops++;
    }
    setDatapoints(results);

  }, []);
  useEffect(() => {
    getDatasetEntries()
  }, []);

  useEffect(() => {
    if (id) {
      setDatapoint(id)
    }
  }, [
    id,
    datapoints
  ]);

  const handleKeyDown = useCallback((event) => {
    const index = datapoints.findIndex((d) => d.id === datapoint);
    let nextDatapoint = null;
    switch (event.key) {
      case "ArrowRight":
        nextDatapoint = datapoints[(index + 1) <= datapoints.length - 1 ? index + 1 : 0];
        history(`/in/dataset/${nextDatapoint.id}`);
        break;
      case "ArrowLeft":
        nextDatapoint = datapoints[(index - 1) >= 0 ? index - 1 : datapoints.length - 1];
        history(`/in/dataset/${nextDatapoint.id}`);
        break;
    }
  }, [
    datapoints,
    datapoint
  ]);

  return (<div onKeyDown={handleKeyDown}>
    {datapoint ? <ImageAnnotator id={datapoint}/> : null} {!datapoint ? <ul role="list"
                                                                            className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    {datapoints
      .map((datapoint) => (<li key={datapoint.id}
                               className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div className="flex-1 flex flex-col p-0">
          <img className="w-full h-auto flex-shrink-0 mx-auto"
               src={"https://d2cwu4hex8j4mq.cloudfront.net/" + datapoint.manifestURL
                                                                        .replace(/-large-/, '-thumb-')
                                                                        .replace(/json/, 'jpg')}
               alt=""/>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <button onClick={() => history(`/in/dataset/${datapoint.id}`)}
                      className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                <PhotographIcon className="w-5 h-5 text-gray-400"
                                aria-hidden="true"/>
                <span className="ml-3">Label</span>
              </button>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <button href={`tel:${datapoint.telephone}`}
                 className={`${datapoint.isApproved ? 'bg-green-600' : ''} relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700" +
                   " font-medium border border-transparent rounded-br-lg hover:text-gray-500`}>
                <PhoneIcon className="w-5 h-5 text-gray-400"
                           aria-hidden="true"/>
                <span className="ml-3">{datapoint.isApproved ? 'Approved' : 'Approve'}</span>
              </button>
            </div>
          </div>
        </div>
      </li>))}
  </ul> : null}
  </div>)
}
