import {Fragment, useEffect, useState} from 'react'
import {SearchIcon} from '@heroicons/react/solid'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {Geo} from "aws-amplify";

const getSuggestionsFromText = async (text) => {
  const searchOptions = {
    searchAreaConstraints: [-122.52, 37.70, -122.36, 37.79], countries: ['USA'], maxResults: 5
  };
  return await Geo.searchForSuggestions(text, searchOptions);
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AddressSearch({setAddress, initOpen, propogateOpen = () => true}) {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(initOpen)
  const [addresses, setAddresses] = useState([])

  const setOpenAndPropogate = (o) => {
    setOpen(o)
    propogateOpen(o)
  }

  useEffect(() => {
    if (!query || query?.length <= 3) return
    (async () => {
      const suggs = await getSuggestionsFromText(query);
      if (suggs) setAddresses(suggs);
    })();
  }, [query])

  return (<Transition.Root show={open}
    as={'div'}
    afterLeave={() => setOpenAndPropogate(false)}
    appear>
    <Dialog as="div"
    className="relative z-10"
    onClose={() => setOpenAndPropogate(false)}>
    <Transition.Child as={'div'}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
  </Transition.Child>
    <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20 m-auto">
      <Transition.Child as={'div'}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95">
        <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
          <Combobox onChange={(address) => {setAddress(address.text); setOpenAndPropogate(false);}}>
            <div className="relative">
              <SearchIcon className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                aria-hidden="true"/> <Combobox.Input className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Enter an address..."
              onChange={(event) => setQuery(event.target.value)}/>
            </div>

            {addresses.length > 0 && (<Combobox.Options static
              className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
              {addresses.map((address) => (<Combobox.Option key={address.placeId}
                value={address}
                className={({active}) => classNames('cursor-default select-none px-4 py-2', active && 'bg-indigo-600 text-white')}>
                {address.text}
              </Combobox.Option>))}
            </Combobox.Options>)}

            {query !== '' && addresses.length === 0 && (<p className="p-4 text-sm text-gray-500">No addresses found.</p>)}
          </Combobox> </Dialog.Panel> </Transition.Child>
    </div>
  </Dialog> </Transition.Root>)
}
