import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { createAmplifyGeocoder, createMap } from "maplibre-gl-js-amplify";
import "maplibre-gl/dist/maplibre-gl.css";
import "./maps.css";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";

const rotatePoint =
  ([cx, cy], [x, y]) =>
  (degrees) => {
    const pi = Math.PI;
    degrees = degrees * (pi / 180.0);
    const s = Math.sin(degrees);
    const c = Math.cos(degrees);
    const newX = x * c - y * s;
    const newY = x * s + y * c;
    return [newX + cx, newY + cy];
  };

const mapImage = (map, coordinates, image_url, rotation, cleanupUrl) => {
  rotation = rotation ? -rotation : 0.0;
  const [lat, lng] = coordinates;
  const height = 0.0001;
  const width = 1.3347 * height;
  const imCoordinates = [
    rotatePoint([lat, lng], [-width, height])(rotation),
    rotatePoint([lat, lng], [width, height])(rotation),
    rotatePoint([lat, lng], [width, -height])(rotation),
    rotatePoint([lat, lng], [-width, -height])(rotation),
  ];
  const mySource = map.getSource(image_url);
  if (mySource) return;
  map.addSource(image_url, {
    type: "image",
    url: image_url,
    coordinates: imCoordinates,
  });
  map.addLayer(
    {
      id: image_url,
      source: image_url,
      type: "raster",
      paint: {
        "raster-opacity": cleanupUrl ? 0.8 : 1,
        "raster-hue-rotate": cleanupUrl ? 0 : 0,
      },
    },
    "trash"
  );
};

const transformTrash = (detections) => ({
  type: "FeatureCollection",
  cluster: false,
  features: detections.map((detection) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [detection.long, detection.lat],
    },
    properties: {
      cleanup: detection?.cleanupRequest?.url,
      color: detection?.cleanupRequest?.url ? "green" : "white",
      imageURL: detection?.imageURL,
      count: detection?.count,
    },
  })),
});

const transformCenterDetection = (detection) => ({
  type: "FeatureCollection",
  cluster: false,
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [detection.long, detection.lat],
      },
    },
  ],
});

function ThreeOneOneMapSpread({ detections, centerDetection, clusters = [] }) {
  const mapRef = useRef(null);
  const [trashMap, setTrashMap] = useState({});

  useEffect(() => {
    let map;
    async function initializeMap() {
      if (mapRef.current != null) {
        map = await createMap({
          container: mapRef.current,
          center: [centerDetection.long, centerDetection.lat],
          zoom: 15,
        });
        setTrashMap(map);
        map.resize();
        map.addControl(createAmplifyGeocoder());
        map.on("load", function () {
          map.addSource("clusters", {
            type: "geojson",
            cluster: false,
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });
          map.addSource("detections", {
            type: "geojson",
            cluster: false,
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });
          map.addLayer({
            id: "detections",
            type: "circle",
            source: "detections",
            paint: {
              "circle-color": "#008800",
              "circle-radius": 5,
              "circle-stroke-width": 1,
              "circle-stroke-color": "#fff",
            },
          });
          map.addSource("center-point", {
            type: "geojson",
            cluster: false,
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });
          map.addLayer({
            id: "clustered-trash",
            type: "circle",
            source: "clusters",
            paint: {
              "circle-color": "#000088",
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                ["max", 12, ["^", ["get", "count"], 1.001]],
                20,
                ["min", 100, ["max", 12, ["^", ["get", "count"], 1.001]]],
              ],
              "circle-stroke-width": 1,
              "circle-stroke-color": "#fff",
            },
          });
          map.addLayer({
            id: "center-detection",
            type: "circle",
            source: "center-point",
            paint: {
              "circle-color": "#880000",
              "circle-radius": 10,
              "circle-stroke-width": 1,
              "circle-stroke-color": "#fff",
            },
          });
          map.addLayer({
            id: "cluster-count",
            type: "symbol",
            source: "clusters",
            layout: {
              "text-field": "{count}",
              "text-font": ["Arial Regular"],
              "text-size": 16,
            },
            paint: {
              "text-color": "#FFFFFF",
            },
          });

          map.on("click", "clustered-trash", (e) => {
            const { top, left } = document
              .getElementById(e.features[0].properties.imageURL)
              .getBoundingClientRect();
            window.scroll(left, top + window.scrollY);
          });
          map?.getSource("clusters")?.setData(transformTrash(clusters));
          map?.getSource("detections")?.setData(transformTrash(detections));
          map
            ?.getSource("center-point")
            ?.setData(transformCenterDetection(centerDetection));
        });
      }
    }
    initializeMap();
    return function cleanup() {
      if (map != null) map.remove();
    };
  }, []);

  useEffect(() => {
    if (
      !!trashMap &&
      trashMap.getSource &&
      trashMap !== undefined &&
      mapRef.current != null
    ) {
      trashMap?.getSource("clusters")?.setData(transformTrash(clusters));
      trashMap?.getSource("detections")?.setData(transformTrash(detections));
      trashMap
        ?.getSource("center-point")
        ?.setData(transformCenterDetection(centerDetection));
    }
  }, [trashMap, mapRef, clusters, detections, centerDetection]);

  return (
    <div className="max-w-full mx-auto px-0 h-full">
      <div className="bg-white h-full rounded-full">
        <div className="py-0 h-full rounded-full">
          <div ref={mapRef} id="map" className="h-full rounded-lg" />
        </div>
      </div>
    </div>
  );
}

export default ThreeOneOneMapSpread;
