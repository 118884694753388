import React from "react";
import {withAuthenticator} from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";

export const UserContext = React.createContext({})

function Authenticated({children, signOut, user}) {
    return (
        <UserContext.Provider value={user}>
            <Outlet/>
        </UserContext.Provider>
    );
}

export default withAuthenticator(Authenticated);