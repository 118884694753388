import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API } from "aws-amplify";
import { createSubscriber } from "./graphql/mutations";

export default function SubscribeForm() {
  const history = useNavigate();

  const { register, handleSubmit } = useForm();

  const submitHandler = async (data) => {
    data.subscribed = true;

    await API.graphql({
      query: createSubscriber,
      variables: { input: data },
      authMode: "API_KEY",
    });
    history("/thanks");
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="mt-4 sm:flex sm:max-w-md"
      >
        <label htmlFor="email-address" className="sr-only">
          {" "}
          Email address{" "}
        </label>{" "}
        <input
          type="email"
          name="email-address"
          id="email-address"
          {...register("email")}
          autoComplete="email"
          required
          className="appearance-none min-w-0 w-full
                bg-white border border-gray-300 rounded-md
                shadow-sm py-2 px-4 text-base text-gray-900
                placeholder-gray-500 focus:outline-none
                focus:ring-indigo-500 focus:border-indigo-500
                focus:placeholder-gray-400"
          placeholder="An email address"
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            data-amplify-analytics-on="click"
            data-amplify-analytics-name={`Click Subscribe`}
            className="w-full flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600
                bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm
                text-white hover:from-purple-700 hover:to-indigo-700"
          >
            Subscribe
          </button>
        </div>
      </form>
    </>
  );
}
