/* This example requires Tailwind CSS v2.0+ */
import { API, graphqlOperation } from "aws-amplify";
import { searchTrainingDatapoints } from "./graphql/queries";
import { useCallback, useEffect, useState } from "react";
import { ImageAnnotator } from "./ImageAnnotator";
import { useNavigate, useParams } from "react-router-dom";

export default function AnnotationWheel() {
  const { from, approved } = useParams();

  const history = useNavigate();
  const [id, setId] = useState(null);

  const getNextDatasetEntry = useCallback(
    async (from, direction = "asc") => {
      const isApproved = {
        isApproved: approved === "true" ? { eq: true } : { ne: true },
      };
      const filter = approved !== "both" ? { isApproved } : {};

      const trainingData = await API.graphql(
        graphqlOperation(searchTrainingDatapoints, {
          from,
          filter,
          limit: 1,
          sort: [
            {
              direction: direction,
              field: "id",
            },
          ],
          aggregate: {},
        })
      );
      return trainingData.data.searchTrainingDatapoints.items[0];
    },
    [approved]
  );

  useEffect(() => {
    (async () => {
      const dp = await getNextDatasetEntry(from);
      setId(dp.id);
    })();
  }, [from]);

  const handleKeyDown = useCallback(
    async (event) => {
      const approvedPath = approved + "";
      switch (event.key) {
        case "t":
          history(`/in/annowheel/${approvedPath}/${parseInt(from, 10) + 1}`);
          break;
        case "s":
          history(`/in/annowheel/${approvedPath}/${parseInt(from, 10) - 1}`);
          break;
      }
    },
    [from]
  );

  return (
    <div onKeyDown={handleKeyDown}>
      <ImageAnnotator id={id} />
    </div>
  );
}
