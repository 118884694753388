import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  getTrainingDatapoint,
  searchTrainingDatapoints,
} from "./graphql/queries";
import { Annotorious } from "@recogito/annotorious";
import "@recogito/annotorious/dist/annotorious.min.css";
import { Storage } from "aws-amplify";
import Panzoom from "@panzoom/panzoom";
import "./AnnotationTool.css";
import {
  deleteTrainingDatapoint,
  updateTrainingDatapoint,
} from "./graphql/mutations";
import { CheckIcon } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";

const mapManifestToAnnotation = (annotation) => {
  const { left: x, top: y, width: w, height: h } = annotation;
  return {
    "@context": "http://www.w3.org/ns/anno.jsonld",
    id: `annotation-${annotation.left}-${annotation.top}-${annotation.width}-${annotation.height}`,
    type: "Annotation",
    body: [
      {
        type: "TextualBody",
        value: "large_pile",
      },
    ],
    target: {
      selector: {
        type: "FragmentSelector",
        conformsTo: "http://www.w3.org/TR/media-frags/",
        value: `xywh=pixel:${x},${y},${w},${h}`,
      },
    },
  };
};

const stopOtherEvents = (event) => {
  event.detail.originalEvent.stopPropagation();
  event.detail.originalEvent.preventDefault();
};

const allowOtherEvents = () => {};

const panOnOptions = {
  handleStartEvent: (event) => {},
  canvas: true,
  contain: "outside",
  overflow: "show",
  maxScale: 20,
  minScale: 1,
  disablePan: true,
};

const mapAnnotationToManifest = (annotation) => {
  const {
    target: {
      selector: { value },
    },
  } = annotation;
  const [, left, top, width, height] = value.match(
    /xywh=pixel:([\.\d]+),([\.\d]+),([\.\d]+),([\.\d]+)/
  );
  return {
    left: parseInt(left, 10),
    top: parseInt(top, 10),
    width: parseInt(width, 10),
    height: parseInt(height, 10),
    class_id: 1,
  };
};

const getDatapointCount = async (approved) => {
  const filter = approved !== "both" ? { isApproved: { eq: approved } } : {};
  const results = await API.graphql(
    graphqlOperation(searchTrainingDatapoints, { filter })
  );
  return results?.data?.searchTrainingDatapoints?.total;
};

export const ImageAnnotator = ({ id }) => {
  const { from, approved } = useParams();
  const history = useNavigate();

  const imgRef = useRef(null);
  const annotatorRef = useRef(null);
  const imgPanZoom = useRef(null);
  const manifestRef = useRef(null);
  const manifestURLRef = useRef(null);

  const [imageURL, setImageURL] = useState(null);
  const [dataPoint, setDataPoint] = useState(null);
  const [panzoomOn, setPanzoomOn] = useState(true);
  const [numAnnotations, setNumAnnotations] = useState(0);
  const [count, setCount] = useState(0);

  const handleAnnotationUpdates = () => {
    if (!!!annotatorRef.current || !!!manifestURLRef.current) return;
    const nowAnnotations = annotatorRef.current.getAnnotations();
    setNumAnnotations(nowAnnotations.length);
    updateManifest(nowAnnotations, manifestURLRef.current);
  };

  useEffect(() => {
    (async () => {
      setCount(await getDatapointCount(approved));
    })();
  }, [imageURL]);

  useEffect(() => {
    if (!imgRef?.current) return;
    imgPanZoom.current = Panzoom(imgRef.current.parentElement, panOnOptions);
    imgRef.current.parentElement.addEventListener("wheel", function (event) {
      imgPanZoom.current.zoomWithWheel(event);
    });
    return () => {
      imgPanZoom.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!imgRef.current) return;
    annotatorRef.current = new Annotorious({
      image: imgRef.current,
      disableEditor: true,
    });
    annotatorRef.current.on("createSelection", async (selection) => {
      selection.body = [
        {
          type: "TextualBody",
          value: "large_pile",
        },
      ];
      await annotatorRef.current.updateSelected(selection);
      annotatorRef.current.saveSelected();
      handleAnnotationUpdates();
    });
    annotatorRef.current.on("createAnnotation", async () => {
      handleAnnotationUpdates();
    });
    annotatorRef.current.on("deleteAnnotation", async () => {
      handleAnnotationUpdates();
    });
    annotatorRef.current.on("updateAnnotation", async () => {
      handleAnnotationUpdates();
    });
    annotatorRef.current.on("clickAnnotation", async (annotation) => {
      console.log(annotation);
    });
    return () => {
      annotatorRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!imgRef?.current) return;
    if (panzoomOn) {
      imgRef.current.parentElement.removeEventListener(
        "panzoomstart",
        allowOtherEvents
      );
      imgRef.current.parentElement.addEventListener(
        "panzoomstart",
        stopOtherEvents
      );
    } else {
      imgRef.current.parentElement.removeEventListener(
        "panzoomstart",
        stopOtherEvents
      );
      imgRef.current.parentElement.addEventListener(
        "panzoomstart",
        allowOtherEvents
      );
    }
  }, [panzoomOn]);

  const reloadDatapoints = async () => {
    let storagePromise = Storage.get(
      manifestURLRef.current?.replace(/public\//, "")
    );
    const manifestURL = await storagePromise;
    const manifestResponse = await fetch(manifestURL);
    manifestRef.current = await manifestResponse.json();
    annotatorRef.current.setAnnotations(
      manifestRef.current?.BB?.annotations?.map(mapManifestToAnnotation)
    );
    setNumAnnotations(manifestRef.current?.BB?.annotations?.length);
  };

  useEffect(() => {
    if (!!!id) return;
    let promise = API.graphql(graphqlOperation(getTrainingDatapoint, { id }));
    let storagePromise;
    (async () => {
      try {
        const dataPointResult = await promise;
        const dp = dataPointResult?.data?.getTrainingDatapoint;
        setDataPoint(dp);
        manifestURLRef.current = dp.manifestURL;
        setImageURL(
          "https://d2cwu4hex8j4mq.cloudfront.net/" +
            dp?.manifestURL?.replace(/json/, "jpg")
        );
        storagePromise = Storage.get(
          manifestURLRef.current?.replace(/public\//, "")
        );
        const manifestURL = await storagePromise;
        const manifestResponse = await fetch(manifestURL);
        manifestRef.current = await manifestResponse.json();
        annotatorRef.current.setAnnotations(
          manifestRef.current?.BB?.annotations?.map(mapManifestToAnnotation)
        );
        setNumAnnotations(manifestRef.current?.BB?.annotations?.length);
      } catch (e) {
        if (API.isCancel(e)) {
          console.log("Cancelled API");
        }
      }
    })();
    return () => {
      Storage.cancel(storagePromise, "Cancelling manifest request");
      API.cancel(promise, "Cancelling API Call");
    };
  }, [id]);

  const updateDatapointXHR = async (dp) => {
    delete dp.isFinished;
    await API.graphql(
      graphqlOperation(updateTrainingDatapoint, {
        input: dp,
      })
    );
    setDataPoint(dp);
  };

  const deleteDatapointXHR = async (dp) => {
    await API.graphql(
      graphqlOperation(deleteTrainingDatapoint, {
        input: {
          id: dp.id,
        },
      })
    );
  };

  const saveManifest = useCallback(() => {
    Storage.put(
      manifestURLRef.current.replace(/public\//, ""),
      JSON.stringify(manifestRef.current),
      {
        contentType: "application/json",
      }
    );
  }, []);

  const handleShortcuts = useCallback(
    (event) => {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if ((event.ctrlKey || event.metaKey) && charCode === "s") {
        event.preventDefault();
        saveManifest();
      }
      if (charCode === "c") {
        if (panzoomOn) {
          imgPanZoom.current.setOptions({ disablePan: true });
        } else {
          imgPanZoom.current.setOptions({ disablePan: false });
        }
        setPanzoomOn(!panzoomOn);
      }
      if (charCode === "p") {
        (async () => {
          delete dataPoint.status;
          await updateDatapointXHR({
            id: dataPoint.id,
            isApproved: !dataPoint.isApproved,
          });
        })();
      }
      if (charCode === "x" && (event.metaKey || event.ctrlKey)) {
        clearAnnotations();
      }
      if (charCode === "w") {

        const selected = annotatorRef.current.getSelected();
        if(selected) {
          annotatorRef.current.removeAnnotation(selected)
        }
      }
      if (charCode === "f") {
        (async () => {
          await updateDatapointXHR({
            ...dataPoint,
          });
        })();
      }
      if (charCode === "a") {
        (async () => {
          await deleteDatapointXHR(dataPoint);
          document
            .getElementById("annotating")
            .parentElement.dispatchEvent(
              new KeyboardEvent("keypress", { key: "t" })
            );
          const approvedPath = approved === "true" ? "true" : "false";
          history(`/in/annowheel/${approvedPath}/${parseInt(from, 10) + 1}`);
        })();
      }
      if (charCode === "r") {
        (async () => {
          await reloadDatapoints();
        })();
      }
    },
    [panzoomOn, dataPoint]
  );

  const annotationsToManifest = (annotations) => {
    const newManifest = {
      ...manifestRef.current,
    };
    newManifest.BB.annotations = annotations.map(mapAnnotationToManifest);
    newManifest["BB-metadata"].objects = annotations.map((a) => ({
      confidence: 1,
    }));
    return newManifest;
  };

  const updateManifest = (annotationsToSet) => {
    if (!annotatorRef.current) return;
    manifestRef.current = annotationsToManifest(annotationsToSet);
    saveManifest();
  };

  const clearAnnotations = () => {
    annotatorRef.current.setAnnotations([]);
    updateManifest([]);
  };

  return (
    <div onKeyDown={(event) => handleShortcuts(event)}>
      <div className="relative">
        <img
          id="annotating"
          ref={imgRef}
          src={imageURL}
          className="left-0 top-0 relative w-full h-full"
          alt="sample"
        />
      </div>
      <div className="p-5 bg-gray-300 opacity-75 fixed bottom-0 right-0">
        <div className="mt-0 p-0">
          <div className="flex">
            <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
              {count}
            </span>
            <button
              type="button"
              className={`ml-2 relative inline-flex items-center px-2 py-1 
                  rounded-md border bg-green-500 hover:bg-green-600 text-gray-800
                  text-sm font-medium hover:z-10 hover:outline-none
                  hover:ring-1 hover:ring-green-500 hover:border-green-500`}
              onClick={() => updateDatapointXHR(dataPoint)}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Get-Results"
            >
              {" "}
              ({numAnnotations}){" "}
              {dataPoint?.isApproved ? "Approved (p)" : "Approve (p)"}{" "}
              {dataPoint?.isApproved ? (
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              ) : null}
            </button>
            <button
              type="button"
              className={`ml-2 relative inline-flex items-center px-2 py-1 
                  rounded-md border bg-green-500 hover:bg-green-600 text-gray-800
                  text-sm font-medium hover:z-10 hover:outline-none
                  hover:ring-1 hover:ring-green-500 hover:border-green-500`}
              onClick={() => clearAnnotations()}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Get-Results"
            >
              Clear Annotations (x)
            </button>
            <button
              type="button"
              className={`ml-2 relative inline-flex items-center px-2 py-1 
                  rounded-md border bg-green-500 hover:bg-green-600 text-gray-800
                  text-sm font-medium hover:z-10 hover:outline-none
                  hover:ring-1 hover:ring-green-500 hover:border-green-500`}
              onClick={() => saveManifest()}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Get-Results"
            >
              Save Manifest (p)
            </button>
            <button
              type="button"
              className={`ml-2 relative inline-flex items-center px-2 py-1 
                  rounded-md border bg-green-500 hover:bg-green-600 text-gray-800
                  text-sm font-medium hover:z-10 hover:outline-none
                  hover:ring-1 hover:ring-green-500 hover:border-green-500`}
              onClick={() => deleteDatapointXHR(dataPoint)}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Get-Results"
            >
              Delete Datapoint (a)
            </button>
            <button
              type="button"
              className={`ml-2 relative inline-flex items-center px-2 py-1 
                  rounded-md border bg-green-500 hover:bg-green-600 text-gray-800
                  text-sm font-medium hover:z-10 hover:outline-none
                  hover:ring-1 hover:ring-green-500 hover:border-green-500`}
              onClick={() => reloadDatapoints()}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Get-Results"
            >
              Reload Datapoint (r)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
