import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {API, Geo, graphqlOperation} from "aws-amplify";
import {getDetection} from "./graphql/queries";
import {useState} from "react";
import {onUpdateCleanupRequest, onUpdateDetection} from "./graphql/subscriptions";

export default function DetectionFromRouter({children}) {
    const {id} = useParams();
    const [detection, setDetection] = useState(null);

    useEffect(() => {
        const sub = API.graphql(graphqlOperation(onUpdateCleanupRequest)).subscribe(
            {
                next: async ({provider, value}) => {
                    setDetection({
                        ...detection,
                        ...value.data.onUpdateCleanupRequest.detection
                    });
                },
            }
        );
        return () => sub.unsubscribe();
    }, [id]);

    useEffect(() => {
        const sub = API.graphql(graphqlOperation(onUpdateDetection)).subscribe({
            next: async ({provider, value}) => {
                setDetection({...detection, ...value.data.onUpdateDetection});
            },
        });
        return () => sub.unsubscribe();
    }, [id]);

    useEffect(() => {
        (async () => {
            const d = await API.graphql(graphqlOperation(getDetection, {id: id}));
            setDetection(d.data.getDetection);
        })();
    }, [id]);

    return <>{detection && React.cloneElement(children, {t: detection})}</>
}