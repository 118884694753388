/* This example requires Tailwind CSS v2.0+ */
import { API, graphqlOperation } from "aws-amplify";
import { searchCleanupRequests } from "./graphql/queries";
import React, { useEffect, useState } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  OfficeBuildingIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { IconBedtime } from "@aws-amplify/ui-react";

const cleanupTypes = {
  Other_loose_garbage_debris_yard_waste: {
    code: "Other_loose_garbage_debris_yard_waste",
    description: "Other Loose Garbage / Debris",
    icon: TrashIcon,
  },
  Other_bagged_boxed_contained_garbage: {
    code: "Other_bagged_boxed_contained_garbage",
    description: "Other Contained Garbage",
    icon: TrashIcon,
  },
  Mattress: {
    code: "Mattress",
    description: "Mattress",
    icon: IconBedtime,
  },
  Furniture: {
    code: "Furniture",
    description: "Furniture",
    icon: TrashIcon,
  },
  City_garbage_can_overflowing: {
    code: "City_garbage_can_overflowing",
    description: "Overflowing Public Garbage Can",
    icon: TrashIcon,
  },
  Transit_shelter_platform: {
    code: "Transit_shelter_platform",
    description: "Trash on transit shelter",
    icon: TrashIcon,
  },
  Electronics: {
    code: "Electronics",
    description: "Electronics/E-Waste",
    icon: TrashIcon,
  },
  Glass: {
    code: "Glass",
    description: "Glass",
    icon: TrashIcon,
  },
  Human_waste_or_urine: {
    code: "Human_waste_or_urine",
    description: "Human / Animal Waste",
    icon: TrashIcon,
  },
  Needles_less_than_20: {
    code: "Needles_less_than_20",
    description: "Medical Waste",
    icon: TrashIcon,
  },
  Blight_Building: {
    code: "Blight_Building",
    description: "Neglected Building (Blight)",
    icon: TrashIcon,
  },
  Oil_paint_other_liquid_spill_wet: {
    code: "Oil_paint_other_liquid_spill_wet",
    description: "Oil / Paint / Other Spill",
    icon: TrashIcon,
  },
  Blight_Lot: {
    code: "Blight_Lot",
    description: "Overgrown Lot (Blight)",
    icon: OfficeBuildingIcon,
  },
  Refrigerator_appliance: {
    code: "Refrigerator_appliance",
    description: "Refrigerator / Appliance",
    icon: TrashIcon,
  },
  Shopping_cart: {
    code: "Shopping_cart",
    description: "Shopping Cart",
    icon: TrashIcon,
  },
};

const embedTrends = (one, two) => {
  return one.map((b) => ({
    ...b,
    changeType:
      b.doc_count > two.find((b2) => b2.key === b.key)?.doc_count
        ? "increase"
        : "decrease",
  }));
};

const getStats = async (start, end) => {
  const results = await API.graphql(
    graphqlOperation(searchCleanupRequests, {
      aggregates: {
        name: "Totals",
        type: "terms",
        field: "code",
      },
      filter: {
        createdAt: {
          range: [start, end],
        },
      },
    })
  );
  return results?.data?.searchCleanupRequests?.aggregateItems[0]?.result
    ?.buckets;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const addDay = (dateInput, increment) => {
  const dateFormatToTime = new Date(dateInput);
  const newDate = new Date(dateFormatToTime.getTime() + increment * 86400000);
  return newDate.toISOString().split("T")[0];
};

export default function GarbageStatistics() {
  const [windowOneStartDate, setWindowOneStartDate] = useState(
    addDay(new Date().toISOString().replace(/T.*/, ""), -7)
  );
  const [windowOneEndDate, setWindowOneEndDate] = useState(
    addDay(new Date().toISOString().replace(/T.*/, ""), 1)
  );

  const [windowTwoStartDate, setWindowTwoStartDate] = useState(
    addDay(new Date().toISOString().replace(/T.*/, ""), -14)
  );
  const [windowTwoEndDate, setWindowTwoEndDate] = useState(
    addDay(new Date().toISOString().replace(/T.*/, ""), -7)
  );

  const [windowOneRequestBuckets, setWindowOneRequestBuckets] = useState([]);
  const [windowTwoRequestBuckets, setWindowTwoRequestBuckets] = useState([]);

  useEffect(() => {
    setWindowOneRequestBuckets(
      embedTrends(windowOneRequestBuckets, windowTwoRequestBuckets)
    );
  }, [windowTwoRequestBuckets]);

  useEffect(() => {
    (async () => {
      const buckets = await getStats(windowOneStartDate, windowOneEndDate);
      setWindowOneRequestBuckets(buckets);
    })();
  }, [windowOneStartDate, windowOneEndDate]);

  useEffect(() => {
    (async () => {
      const buckets = await getStats(windowTwoStartDate, windowTwoEndDate);
      setWindowTwoRequestBuckets(buckets);
    })();
  }, [windowTwoEndDate, windowTwoStartDate]);

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900"></h3>
      <div
        className="bg-white top-0 shadow sm:rounded-lg"
        style={{ zIndex: 200 }}
      >
        <div className="px-0 py-0 sm:p-0">
          <form className="mt-0 sm:flex sm:items-center">
            <div className="mt-0 bg-white rounded-md shadow-sm -space-y-px">
              <div className="flex -space-x-px">
                <div className="w-1/2 flex-1 min-w-0">
                  <label htmlFor="date" className="sr-only">
                    {" "}
                    Start date{" "}
                  </label>{" "}
                  <input
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="311-Date-Change"
                    onSelect={(event) =>
                      setWindowOneStartDate(event.target.value)
                    }
                    type="date"
                    name="date"
                    id="date"
                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block
                                                w-3/4 rounded-none rounded-l-md bg-transparent focus:z-10 sm:text-sm
                                                border-gray-300"
                    defaultValue={windowOneStartDate}
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <label htmlFor="endDate" className="sr-only">
                    {" "}
                    End date{" "}
                  </label>{" "}
                  <input
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="311-End-Date-Change"
                    onSelect={(event) =>
                      setWindowOneEndDate(event.target.value)
                    }
                    type="date"
                    name="endDate"
                    id="endDate"
                    defaultValue={windowOneEndDate}
                    className="focus:ring-indigo-500 focus:border-indigo-500
                                                                       relative block w-3/4 rounded-none bg-transparent
                                                                       focus:z-10 sm:text-sm border-gray-300"
                  />
                </div>
              </div>
              <div className="flex -space-x-px">
                <div className="w-1/2 flex-1 min-w-0">
                  <label htmlFor="date" className="sr-only">
                    {" "}
                    Start date{" "}
                  </label>{" "}
                  <input
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="311-Date-Change"
                    onSelect={(event) =>
                      setWindowTwoStartDate(event.target.value)
                    }
                    type="date"
                    name="date"
                    id="date"
                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block
                                                w-3/4 rounded-none rounded-l-md bg-transparent focus:z-10 sm:text-sm
                                                border-gray-300"
                    defaultValue={windowTwoStartDate}
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <label htmlFor="endDate" className="sr-only">
                    {" "}
                    End date{" "}
                  </label>{" "}
                  <input
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="311-End-Date-Change"
                    onSelect={(event) =>
                      setWindowTwoEndDate(event.target.value)
                    }
                    type="date"
                    name="endDate"
                    id="endDate"
                    defaultValue={windowTwoEndDate}
                    className="focus:ring-indigo-500 focus:border-indigo-500
                                                                       relative block w-3/4 rounded-none bg-transparent
                                                                       focus:z-10 sm:text-sm border-gray-300"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="border-b border-gray-200 p-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {new Date(windowOneStartDate).toDateString()} to{" "}
          {new Date(windowOneEndDate).toDateString()}
        </h3>
      </div>
      <dl className="mt-16 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {windowOneRequestBuckets
          .map((b) => ({
            ...b,
            icon: cleanupTypes[b.key].icon,
          }))
          .map((item) => (
            <div
              key={item.key}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {cleanupTypes[item.key].description}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.doc_count}
                </p>
                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}
                >
                  {item.changeType === "increase" ? (
                    <ArrowUpIcon
                      className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}
                  <span className="sr-only">
                    {" "}
                    {item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}
                </p>
              </dd>
            </div>
          ))}
      </dl>
      <div className="border-b border-gray-200 p-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {new Date(windowTwoStartDate).toDateString()} to{" "}
          {new Date(windowTwoEndDate).toDateString()}
        </h3>
      </div>
      <dl className="mt-16 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {windowTwoRequestBuckets
          .map((b) => ({
            ...b,
            icon: cleanupTypes[b.key].icon,
          }))
          .map((item) => (
            <div
              key={item.key}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {cleanupTypes[item.key].description}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.doc_count}
                </p>
                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}
                >
                  {item.change}
                </p>
              </dd>
            </div>
          ))}
      </dl>
    </div>
  );
}
