/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { Analytics } from "@aws-amplify/analytics";
import PaymentNotYetModal from "./PaymentNotYetModal";
import { useState } from "react";

const plans = [
  {
    title: "Low Frequency",
    featured: false,
    priceMonthly: 75,
    link: 'https://buy.stripe.com/dR69D25VFdxA2pG4gp',
    cta: 'Purchase Low',
    mainFeatures: [
      { id: 1, value: "Periodic automatic trash pile reporting (Low frequency)" },
      { id: 3, value: "Monthly trash pile report" },
    ],
  },
  {
    title: "High Frequency",
    featured: true,
    priceMonthly: 150,
    link: "https://buy.stripe.com/00g5mMabVgJM3tKdR0",
    cta: 'Purchase High',
    mainFeatures: [
      { id: 1, value: "Periodic automatic trash pile reporting (High frequency)" },
      { id: 3, value: "Monthly trash pile report" },
    ],
  },
  {
    title: "Support Bayview",
    featured: false,
    priceMonthly: 10,
    link: 'https://buy.stripe.com/dR69D297R1OS1lC14f',
    cta: 'Supporter',
    mainFeatures: [
      { id: 1, value: "Be a supporter"}
    ],
  },
];
const faqs = [
  {
    id: 1,
    question: "How does this work?",
    answer:
      "An unnanned aerial vehicle flies over your street periodically. Using machine vision and AI to detect and" +
      " report piles of garbage, furniture, large enough for DPW to clean up. ",
  },
  {
    id: 7,
    question: "What happens next?",
    answer:
      "When you choose a plan, we will send you an email with instructions for selecting your frontage, block, or street for reporting trash piles.",
  },
  {
    id: 2,
    question: "How often do you report trash?",
    answer: "Periodically based on the coverage plan for the area.",
  },
  {
    id: 3,
    question: "What does reporting trash do to solve the problem?",
    answer:
      "Reporting trash consistently ensures that the department of public works, the city, and our neighbors all know how much trash is being dumped on our streets. The San Francisco Department of Public Works will deal with Street and Sidewalk cleaning requests (4-24 hours).",
  },
  {
    id: 4,
    question: "How quickly does DPW come?",
    answer:
      "Other Loose Garbage/Debris: 24 to 48 hour(s); Other Contained Garbage: 4 business hour(s)",
  },
  {
    id: 5,
    question: "Is this safe?",
    answer:
      "We use FAA licensed unmanned aerial vehicle pilots, certified by the FAA part 107 commercial pilot's licence. We are ensured for liability, and we fly a commercial grade, high reliability vehicle. We follow FAA guidelines and avoid hovering or flying directly over people, to be extra safe.",
  },
  {
    id: 6,
    question: "Are you allowed to fly a UAV over the city?",
    answer:
      "We request clearance for each flight directly with the FAA before we take off. The FAA automatically approves flights when within the parameters we fly.",
  },
  {
    id: 8,
    question: "What about privacy?",
    answer:
      "Our machine vision platform actually removes everything except the street and sidewalk from the images, so that we protect the privacy of your home.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingPlans() {
  const [initOpen, setInitOpen] = useState(false);

  Analytics.record({
    name: "View PricingPlans",
  });

  return (
    <div className="bg-gray-50">
      <main>
        {/* Pricing section */}
        <div>
          <div className="relative bg-gradient-to-r from-purple-800 to-indigo-700">
            {/* Overlapping background */}
            <div
              aria-hidden="true"
              className="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block"
            />
            <div className="relative max-w-2xl mx-auto pt-16 px-4 text-center sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-black sm:text-6xl">
                <span className="block lg:inline">
                  Clean and Simple Pricing
                </span>
              </h1>
              <p className="mt-4 text-xl text-black">
                Keep your sidewalk and street clean. Choose a
                plan to feel the clean!
              </p>
            </div>
            <h2 className="sr-only">Plans</h2>
            {/* Cards */}
            <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
              {/* Decorative background */}
              <div
                aria-hidden="true"
                className="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-purple-200 rounded-tl-lg rounded-tr-lg lg:block"
              />
              <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
                {plans.map((plan) => (
                  <div
                    key={plan.title}
                    className={classNames(
                      plan.featured
                        ? "bg-white ring-2 ring-purple-700 shadow-md"
                        : "bg-purple-700 lg:bg-transparent",
                      "pt-6 px-6 pb-6 rounded-lg lg:px-8 lg:pt-12"
                    )}
                  >
                    <div>
                      <h3
                        className={classNames(
                          plan.featured ? "text-black" : "text-black",
                          "text-sm font-semibold uppercase tracking-wide"
                        )}
                      >
                        {plan.title}
                      </h3>
                      <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                        <div className="mt-3 flex items-center">
                          <p
                            className={classNames(
                              plan.featured ? "text-black" : "text-black",
                              "text-4xl font-extrabold tracking-tight"
                            )}
                          >
                            ${plan.priceMonthly}
                          </p>
                          <div className="ml-4">
                            <p
                              className={classNames(
                                plan.featured ? "text-gray-700" : "text-black",
                                "text-sm"
                              )}
                            >
                              USD / month
                            </p>
                          </div>
                        </div>
                        <a
                          href={plan.link}
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name={`Click Buy ${plan.title}`}
                          className={classNames(
                            plan.featured
                              ? "bg-purple-400 text-black hover:bg-purple-300"
                              : "bg-white text-black hover:bg-purple-300",
                            "mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                          )}
                        >
                          {plan.cta}
                        </a>
                      </div>
                    </div>
                    <h4 className="sr-only">Features</h4>
                    <ul
                      role="list"
                      className={classNames(
                        plan.featured
                          ? "border-gray-200 divide-gray-200"
                          : "border-purple-500 divide-purple-500 divide-opacity-75",
                        "mt-7 border-t divide-y lg:border-t-0"
                      )}
                    >
                      {plan.mainFeatures.map((mainFeature) => (
                        <li
                          key={mainFeature.id}
                          className="py-3 flex items-center"
                        >
                          <CheckIcon
                            className={classNames(
                              plan.featured
                                ? "text-purple-500"
                                : "text-purple-200",
                              "w-5 h-5 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              plan.featured ? "text-gray-600" : "text-black",
                              "ml-4 text-sm font-medium"
                            )}
                          >
                            {mainFeature.value}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* FAQs */}
        <section aria-labelledby="faq-heading" className=" bg-white-500">
          <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="max-w-2xl lg:mx-auto lg:text-center">
              <h2
                id="faq-heading"
                className="text-3xl font-extrabold tracking-tight text-black sm:text-4xl"
              >
                Frequently asked questions
              </h2>
              <p className="mt-4 text-gray-700">
                We follow FAA guidelines and are licensed and insured for
                commercial drone flight. We want to keep the streets clean, by
                daily reporting trash piles, so that the Department of Public
                Works knows where to clean.
              </p>
            </div>
            <div className="mt-20">
              <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="font-semibold text-black">{faq.question}</dt>
                    <dd className="mt-3 text-gray-700">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>
      </main>
      {initOpen ? <PaymentNotYetModal plan={initOpen} /> : null}
    </div>
  );
}
