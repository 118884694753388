export default function ImperfectF1Score() {
  return (<div className="relative overflow-hidden bg-white py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true">
          <svg className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384">
            <defs>
              <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"/>
              </pattern>
            </defs>
            <rect width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
          </svg>
          <svg className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"/>
              </pattern>
            </defs>
            <rect width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
          </svg>
          <svg className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384">
            <defs>
              <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"/>
              </pattern>
            </defs>
            <rect width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-indigo-600">Case Study</span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Uncovering the Power of a .5 F1 Score: How to Succeed Even with Imperfect Results
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            When a leader or builder is determining the success of an AI initiative, it can be easy to focus on the outcome that looks best on paper - the one with a perfect score.
            But what if there was another way to measure your success? What if you could still come away from something triumphant even when results weren't as perfect as expected?
            Welcome to "Uncovering the Power of a .5 F1 Score: How to Succeed Even with Imperfect Results". In this insightful blog post, we'll explore how leaders and builders can
            use calculated imperfection in order to gain valuable insights and emerge successful. By developing an outlook that is both inspiring and rooted in facts, you'll be
            able to reach your goals much more quickly. Let's talk about why this approach works! With these tips and tricks in hand, you're sure to uncover a whole new load of
            positive energy when dealing with imperfections along your journey – so let’s get started! </p>
          <div className="prose max-w-prose mx-auto mt-6 text-gray-500">
            <h2>
              <span className="mt-2  mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                Introducing the concept of an F1-Score in machine learning - a measure of success that takes into account precision and recall.
              </span>
            </h2>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              An F1-Score, or Fisher Score, is a measure of success in machine learning that takes into account both precision and recall. Precision measures how many of the items returned by a search are actually what you were looking for. Recall measures how many of the items you were looking for are actually found by the search. The F1-Score is the harmonic mean of precision and recall, which gives it a higher weighting for accuracy than either metric on its own. This makes it an ideal measure of success for a system that is trying to find the right information as accurately as possible.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              To avoid false positives, precision should be your main priority. Say you're constructing a spam email classifier; you don't want any genuine emails to get lost in the junk mail filter. In those scenarios, maximizing precision is key to success.
            </p>
            <figure className="m-6">
              <img className="w-full rounded-lg"
                src="/assets/imperfect-score.png"
                alt=""
                width={1310}
                height={873}/>
              <figcaption className="text-center">An F1-Score around .5 is still very useful.</figcaption>
            </figure>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              In fields such as medical science, it is imperative to recall correctly since a false negative can have serious consequences. This means that you should try your best to minimize the chance of missing positive cases and predicting them accurately. When considering this fact, missing out on a positive case has much higher costs than wrongly labeling something as positive.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              Both precision and recall are essential to get a comprehensive overview of the situation - neither can be used in isolation.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              The F1-score is an exemplary metric, as it factors both precision and recall into its calculation. This means that even when datasets are imbalanced or there's a small number of positive cases vs negative ones, the formula will still accurately evaluate them if the precision or recall values for the positives are low.
            </p>

            <h2>
              <span className="mt-2 mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                A suboptimal machine learning F1-Score can be useful when your product strategy embraces imperfection.
              </span>
            </h2>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              Oftentimes, having pinpoint recall isn't always necessary for specific purposes. For example, if a drone is used to detect and track illegal dumping sites in San Francisco- discovering more than initially known will still be marked as progress.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              On the accuracy side, even though mistakenly reporting non-trash as illegal dumping is a risk, there are strategies that utilize humans in the loop to maintain performance increases while avoiding false reports.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              In business, we often strive for perfection. We want to create the best product possible and achieve the best results. However, in the field of machine learning, there is no such thing as a perfect algorithm. Even the most sophisticated models can produce suboptimal results due to inaccuracies in data or incorrect assumptions about how the world works.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              This can be a frustrating reality for leaders and builders who want to use machine learning to power their products. However, it's important to remember that these imperfect models still have value. In fact, embracing imperfection can be a valuable strategy for achieving goals more quickly.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              There are several ways to take advantage of imperfect machine learning models. One approach is to use techniques like active learning to improve model performance over time. With active learning, you can identify which data is most important for improving your model and focus on that data first. This helps you get the most out of your model while still benefiting from its inaccuracies.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              Embracing imperfection can be advantageous for those in a leadership position, however, it is not always an easy endeavor. Certain situations require accuracy and precision which cannot have room for error--in such cases, relying on well-performing models instead of attempting to make do with an inadequate one is the best option. When practical, though, you may use imperfect models.
            </p>
            <figure className="m-6">
              <img className="w-full rounded-lg"
                src="/assets/detection-results.png"
                alt=""
                width={1310}
                height={873}/>
              <figcaption className="text-center">Even with a ~.5 F1-Score, the results can be compelling.</figcaption>
            </figure>
            <h2>
              <span className="mt-2 mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                Tips and tricks for embracing a progressive mindset when using a .5 F1 Score
              </span>
            </h2>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              When it comes to embracing a progressive mindset in product development, there are a few key things to keep in mind. First, be prepared for imperfect results – sometimes you'll have to settle for getting 50% of the way there. This can be done by setting up fixtures for suboptimal results in your product, using techniques like active learning or unsupervised learning to help you improve continuously, and identifying use cases where 50% of the way is good enough.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              Second, remember that progress is not always linear. There will be times when you take two steps forward and one step back, or when you hit a plateau and can't seem to make any more progress. Don't get discouraged – these are natural parts of the learning process. Persevere and keep trying different techniques until you find what works best for you.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              Third, AI isn't here to replace humans. It can be employed to enhance them instead! When product design is carefully done, it allows suboptimal calculations from the AI systems to be presented for human review and evaluation - ultimately improving performance overall whilst guaranteeing satisfying outcomes in the process.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              Finally, be patient and don't expect overnight results. Product development is a long process, and there's no magic bullet that will instantly make your product perfect. It takes time, effort, and patience to get it right. But if you're willing to put in the work, you'll eventually see results.
            </p>
            <h2>
              <span className="mt-2 mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                The power of calculated imperfection
              </span>
            </h2>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              The power of calculated imperfection cannot be understated. By embracing our mistakes and using them as a learning tool, we can unlock our potential to create beautiful things. Art is full of mistakes, and yet it is one of the most perfect forms of expression. We can learn from these mistakes and use them to make something new and better. This is the magic of calculated imperfection – it allows us to push boundaries and create something truly unique.
            </p>
            <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
              A .5 F1 Score offers a realistic and achievable measure of success that can be incredibly valuable for leaders and builders. When we're open to new possibilities and focus on the positives even when things don't go as planned, we set ourselves up for success. By taking risks and learning from our mistakes, we build toward a brighter future. Embracing a .5 F1 Score mindset is a powerful way to achieve our goals more quickly and effectively.
            </p>
          </div>
        </div>
      </div>
    </div>)
}


