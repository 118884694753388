import React, {useState} from "react";
import {Storage} from "aws-amplify";
import {CheckCircleIcon, UploadIcon} from "@heroicons/react/solid";

export default function UploadPhotos() {

    const [upload, setUpload] = useState({uploading: false, uploaded: false});
    const [progress, setProgress] = useState({});

    const uploadFiles = async (files) => {
        files.map(f => setProgress(progress => ({
            ...progress,
            [`ingest/${f.name}`]: {key: `ingest/${f.name}`, loaded: 0, total: 100},
        })));
        setUpload({uploading: true, uploaded: false});
        await Promise.all(files.map(c => Storage.put(`ingest/${c.name}`, c, {
            progressCallback(p) {
                if (p.key) {
                    setProgress(progress => ({...progress, [p.key]: p}))
                }
            },
            contentType: c.type
        })));
        setUpload({uploading: false, uploaded: true});
    }

    const calcPercentage = (p) => Math.round(100.0 * p.loaded / p.total);

    return (
        <div className="px-4 mt-6 sm:px-6 lg:px-8">
            <form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div
                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="flex items-center">
                                        <input
                                            onChange={(e) => uploadFiles(Array.from(e.target.files))}
                                            data-amplify-analytics-on='click'
                                            data-amplify-analytics-name='Click-Upload-Photos'
                                            type="file"
                                            multiple
                                            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm
                                        text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="bg-indigo-50 shadow overflow-hidden sm:rounded-md mt-6 border-gray-300">
                <ul className="divide-y divide-gray-200">
                    {Object.keys(progress).map((key, index) => (
                        <li key={index}>
                            <div className="block">
                                <div className="flex items-center px-4 py-4 sm:px-6 relative">
                                    <div className="min-w-0 flex-1 flex items-center ">
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                                            <div>
                                                <p className={`${calcPercentage(progress[key]) < 100 ? 'text-indigo-600' : 'text-green-500'} text-sm font-medium `}>
                                                    <span className="mr-1 text-lg font-extrabold">{key} -</span>
                                                    <span className="mr-1">{calcPercentage(progress[key])}%</span>
                                                    <span className="mr-1 truncate">
                                                        {calcPercentage(progress[key]) === 100 ? "uploaded!" : "uploading..."}
                                                    </span>
                                                    {calcPercentage(progress[key]) === 100 &&
                                                    <span className="mr-1">
                                                        <CheckCircleIcon
                                                            className="absolute left-0 ml-3 flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                            aria-hidden="true"/>
                                                    </span>
                                                    }
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <UploadIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                aria-hidden="true"/>
                                                    <div className={`w-full bg-gray-100 h-4 rounded-full`}>
                                                        <div
                                                            className={`${calcPercentage(progress[key]) < 100 ? 'bg-indigo-500' : 'bg-green-500'} h-4 rounded-full`}
                                                            style={{width: `${calcPercentage(progress[key])}%`}}/>
                                                    </div>

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
