import React, {useCallback, useEffect, useState} from "react";
import {
  BeakerIcon,
  CameraIcon,
  CheckIcon,
  EyeOffIcon,
  LocationMarkerIcon,
  MapIcon, PhotographIcon,
  SelectorIcon,
  TagIcon,
  TrashIcon,
  TruckIcon,
  ZoomInIcon,
} from "@heroicons/react/solid";

import {API, graphqlOperation} from "aws-amplify";

import {
  createCleanupRequest,
  updateDetection,
  createTrainingDatapoint,
  deleteTrainingDatapoint,
} from "./graphql/mutations";
import {onUpdateDetection} from "./graphql/subscriptions";

const cleanupTypes = [
  {
    code: "Other_loose_garbage_debris_yard_waste",
    description: "Other Loose Garbage / Debris",
  },
  {
    code: "Other_bagged_boxed_contained_garbage",
    description: "Other Contained Garbage",
  },
  {
    code: "Mattress",
    description: "Mattress",
  },
  {
    code: "Furniture",
    description: "Furniture",
  },
  {
    code: "City_garbage_can_overflowing",
    description: "Overflowing Public Garbage Can",
  },
  {
    code: "Transit_shelter_platform",
    description: "Trash on transit shelter",
  },
  {
    code: "Electronics",
    description: "Electronics/E-Waste",
  },
  {
    code: "Glass",
    description: "Glass",
  },
  {
    code: "Human_waste_or_urine",
    description: "Human / Animal Waste",
  },
  {
    code: "Needles_less_than_20",
    description: "Medical Waste",
  },
  {
    code: "Blight_Building",
    description: "Neglected Building (Blight)",
  },
  {
    code: "Oil_paint_other_liquid_spill_wet",
    description: "Oil / Paint / Other Spill",
  },
  {
    code: "Blight_Lot",
    description: "Overgrown Lot (Blight)",
  },
  {
    code: "Refrigerator_appliance",
    description: "Refrigerator / Appliance",
  },
  {
    code: "Shopping_cart",
    description: "Shopping Cart",
  },
];

const descriptions = [
  "a small pile containing",
  "a medium pile containing",
  "a large pile containing",
  "more than a truckload containing",
  "mattress",
  "garbage bags",
  "loose debris",
  "toxic waste",
  "furniture",
  "construction debris",
  "appliances",
  "cardboard boxes",
  "pallets",
  "blocking handicap access",
  "behind barricade",
  "in tree well",
  "near encampment",
  "on the road",
  "on the curb",
  "on the sidewalk",
  "by the city boxes",
  "on the grass/field",
  "by the power pole",
  "by the sign post",
  "by the fire hydrant",
  "by the city garbage can",
  "between parked cars",
  "behind fence",
  "touching building",
  "touching fence",
  "covering drain",
  "near dumpster",
  "full-photo",
];

const addDetectionToDataset = async (detection, imageURL) => {
  if (!detection?.id) {
    return;
  }
  const manifestKey = imageURL.replace(
    /size-large-detection.*/,
    "size-large-training"
  );
  const manifestURL = `public/training/${manifestKey}.json`;
  const variables = {
    input: {
      manifestURL,
      trainingDatapointDetectionId: detection.id,
    },
  };
  const result = await API.graphql(
    graphqlOperation(createTrainingDatapoint, variables)
  );
  const detectionTrainingDatapointId =
    result?.data?.createTrainingDatapoint?.id;

  const detectionResult = await API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id: detection.id,
        detectionTrainingDatapointId,
      },
    })
  );
  return {
    result,
  };
};

const removeDetectionFromDataset = async (detection) => {
  const remove = await API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id: detection.id,
        detectionTrainingDatapointId: null,
      },
    })
  );
  const del = await API.graphql(
    graphqlOperation(deleteTrainingDatapoint, {
      input: {id: detection.detectionTrainingDatapointId},
    })
  );
};

const createCleanup = async (detection, code, description) => {
  const result = await API.graphql(
    graphqlOperation(createCleanupRequest, {
      input: {
        code,
        description,
        cleanupRequestDetectionId: detection.id,
      },
    })
  );
  const cleanupRequest = result.data.createCleanupRequest;
  const updateResult = await updateDetect(
    detection.id,
    cleanupRequest.id,
    true
  );
  return [result, updateResult];
};

const markIsTrash = async (id, isTrash) => {
  const result = await API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id,
        isTrash,
        status: "processed",
      },
    })
  );
  return result;
};

const updateDetect = (id, detectionCleanupRequestId, isTrash) =>
  API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id,
        detectionCleanupRequestId,
        isTrash,
        status: "processed",
      },
    })
  );

export default function RequestMaker({t}) {
  const [detection, setDetection] = useState(t);
  const [chosenDescriptions, setChosenDescriptions] = useState(
    descriptions.reduce((carries, description) => {
      carries[description] = {
        description,
        selected: false,
      };
      return carries;
    }, {})
  );

  const updateDetectionCallback = useCallback(async (d, key, value) => {
    const results = {
      ...d,
      [key]: value,
    };
    setDetection(results);
  }, []);

  const getDescription = () =>
    Object.keys(chosenDescriptions).reduce((desc, d) => {
      if (chosenDescriptions[d].selected)
        desc += chosenDescriptions[d].description + ", ";
      return desc;
    }, "");

  useEffect(() => {
    const sub = API.graphql(graphqlOperation(onUpdateDetection)).subscribe({
      next: async ({provider, value}) => {
        setDetection(value.data.onUpdateDetection);
      },
    });
    return () => sub.unsubscribe();
  }, [setDetection]);

  const closed = detection?.cleanupRequest?.status === "closed";

  return (
    <div key={detection.id} id={detection.imageURL} className="relative">
      <span
        className="w-full top right absolute mt-2 pr-5 block z-50 text-sm font-medium text-white text-right truncate pointer-events-none">
        {new Date(detection.captureDatetime).toDateString()}
      </span>
      <span
        className="w-full top-10 left-10 absolute mt-2 pr-5 block z-50 text-sm font-medium text-white left truncate pointer-events-none">
        {Number(detection?.confidence?.toFixed(2))}
      </span>
      <span
        className="w-full bottom left absolute mt-7 pr-5 block z-50 text-sm font-medium text-white text-right truncate pointer-events-none"
        style={{
          background: `rgba(${
            closed ? "200, 100, 100" : "100, 200, 100"
          }, 0.5)`,
        }}
      >
        <p>
          {detection?.cleanupRequest?.status
            ? detection?.cleanupRequest?.status
            : ""}
        </p>
        <p>
          {detection?.cleanupRequest?.statusNotes
            ? detection?.cleanupRequest?.statusNotes
            : ""}
        </p>
      </span>
      <span
        style={{zIndex: 50}}
        className="absolute bottom-0 right-0 z-0 inline-flex shadow-sm rounded-md overflow-visible"
      >
        <button onClick={() => createCleanup(detection, "City_garbage_can_overflowing", detection.description)}
                className={`-ml-px rounded-l-md inline-flex items-center px-2 py-2
                            border border-gray-300 text-sm font-medium
                            focus:z-10 focus:outline-none focus:ring-1
                            focus:ring-gray-300 focus:border-gray-300
                            bg-gray-500 hover:bg-gray-400 text-white`}>
          overflow
        </button>
        <button onClick={() => createCleanup(detection, "Other_bagged_boxed_contained_garbage", detection.description)}
                className={`-ml-px inline-flex items-center px-2 py-2
                            border border-gray-300 text-sm font-medium
                            focus:z-10 focus:outline-none focus:ring-1
                            focus:ring-gray-300 focus:border-gray-300
                            bg-gray-500 hover:bg-gray-400 text-white`}>
          bagged
        </button>
        <button onClick={() => createCleanup(detection, "Other_loose_garbage_debris_yard_waste", detection.description)}
                className={`-ml-px inline-flex items-center px-2 py-2
                            border border-gray-300 text-sm font-medium
                            focus:z-10 focus:outline-none focus:ring-1
                            focus:ring-gray-300 focus:border-gray-300
                            bg-gray-500 hover:bg-gray-400 text-white`}>
          loose
        </button>
        <a
          href={`/in/panzoom/${detection.id}`}
          target="zoomImage"
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="311-List-Ignore"
          type="button"
          className={`-ml-px inline-flex items-center px-2 py-2
                      border border-gray-300 text-sm font-medium
                      focus:z-10 focus:outline-none focus:ring-1
                      focus:ring-gray-300 focus:border-gray-300
                      bg-gray-500 hover:bg-gray-400 text-white`}
        >
          <CameraIcon className="ml-1 mr-1 h-5 w-5"/>{" "}
        </a>
        {detection.detectionTrainingDatapointId ? (
          <a
            href={`/in/annotator/${detection.detectionTrainingDatapointId}`}
            target="annotate"
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="311-List-Ignore"
            type="button"
            className={`-ml-px inline-flex items-center px-2 py-2
                    border border-gray-300 text-sm font-medium
                    focus:z-10 focus:outline-none focus:ring-1
                    focus:ring-gray-300 focus:border-gray-300
                    bg-green-500 hover:bg-gray-400 text-white`}
          >
            <CheckIcon className="ml-1 mr-1 h-5 w-5"/>{" "}
          </a>
        ) : null}
        <button
          onClick={async () =>
            window.open(
              `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${detection.lat},${detection.long}`,
              "map"
            )
          }
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="Detection-Learn"
          type="button"
          className={`inline-flex
                      items-center px-2 py-2
                      border border-gray-300 text-sm font-medium
                      focus:z-10 focus:outline-none focus:ring-1
                      focus:ring-gray-300 focus:border-gray-300
                      bg-gray-500 hover:bg-gray-400 text-white`}
        >
          <MapIcon className="ml-1 mr-1 h-5 w-5"/>
        </button>
        <button
          onClick={() =>
            window.open(
              `https://d2cwu4hex8j4mq.cloudfront.net/public/detections/${detection.imageURL.replace(
                /detection.*/,
                "detection.json"
              )}`
            )
          }
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="Detection-Learn"
          type="button"
          className={`inline-flex
                                              items-center px-2 py-2
                                              border border-gray-300 text-sm font-medium
                                              focus:z-10 focus:outline-none focus:ring-1
                                              focus:ring-gray-300 focus:border-gray-300
                                              bg-gray-500 hover:bg-gray-400 text-white`}
        >
          <ZoomInIcon className="ml-1 mr-1 h-5 w-5"/>
        </button>
        {detection.detectionTrainingDatapointId ? (
          <button
            onClick={() => removeDetectionFromDataset(detection)}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="Detection-Learn"
            type="button"
            className={`inline-flex
                      items-center px-2 py-2
                      border border-gray-300 text-sm font-medium
                      focus:z-10 focus:outline-none focus:ring-1
                      focus:ring-gray-300 focus:border-gray-300
                      bg-green-500 hover:bg-gray-400 text-white`}
          >
            <BeakerIcon className="ml-1 mr-1 h-5 w-5"/>
          </button>
        ) : (
          <button
            onClick={() => addDetectionToDataset(detection, detection.imageURL)}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="Detection-Learn"
            type="button"
            className={`inline-flex
                                                items-center px-2 py-2
                                                border border-gray-300 text-sm font-medium
                                                focus:z-10 focus:outline-none focus:ring-1
                                                focus:ring-gray-300 focus:border-gray-300
                                                bg-gray-300 hover:bg-gray-400 text-white`}
          >
            <BeakerIcon className="ml-1 mr-1 h-5 w-5"/>
          </button>
        )}{" "}
        {detection.isTrash ? (
          <button
            onClick={() => markIsTrash(detection.id, false)}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="311-List-Ignore"
            type="button"
            className={`-ml-px inline-flex items-center px-2 py-2
                                    border border-gray-300text-sm font-medium
                                    focus:z-10 focus:outline-none focus:ring-1
                                    focus:ring-gray-300 focus:border-gray-300
                                    bg-green-500 hover:bg-gray-400 text-white`}
          >
            <TrashIcon className="ml-1 mr-1 h-5 w-5"/>
          </button>
        ) : (
          <button
            onClick={() => markIsTrash(detection.id, true)}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="311-List-Ignore"
            type="button"
            className={`-ml-px inline-flex items-center px-2 py-2
                                    border border-gray-300 text-sm font-medium
                                    focus:z-10 focus:outline-none focus:ring-1
                                    focus:ring-gray-300 focus:border-gray-300
                                    bg-gray-300 hover:bg-gray-400 text-white`}
          >
            <TrashIcon className="ml-1 mr-1 h-5 w-5"/>
          </button>
        )}
        <button
          onClick={() => updateDetect(detection.id, null, false)}
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="311-List-Ignore"
          type="button"
          className={`-ml-px
                                inline-flex items-center px-2 py-2
                            border border-gray-300 text-sm font-medium
                            focus:z-10 focus:outline-none focus:ring-1
                            focus:ring-gray-300 focus:border-gray-300
                            bg-gray-300 hover:bg-gray-400 text-white`}
        >
          <EyeOffIcon className="ml-1 mr-1 h-5 w-5"/>
        </button>
        {(!detection.cleanupRequest?.id ||
          detection.cleanupRequest.url ===
          "https://mobile311.sfgov.org/reports") && (
          <>
            <button
              onClick={() =>
                updateDetectionCallback(detection, "showCleanup", true)
              }
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Cleanup"
              type="button"
              className={`inline-flex
                                        items-center px-2 py-2
                                        border border-gray-300 text-sm font-medium
                                        focus:z-10 focus:outline-none focus:ring-1
                                        focus:ring-gray-300 focus:border-gray-300
                                        bg-gray-300 hover:bg-gray-400 text-white`}
            >
              <TruckIcon className="ml-1 mr-1 h-5 w-5"/>
            </button>
          </>
        )}{" "}
        {!!detection.cleanupRequest?.id &&
          detection.cleanupRequest?.serviceToken && (
            <a
              href={`https://mobile311.sfgov.org/reports/${detection.cleanupRequest?.serviceToken}`}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Check-Status"
              target={"external"}
              type="button"
              className={`-ml-px relative inline-flex
                                       items-center px-2 py-1 border
                                       bg-green-500 hover:bg-gray-400 text-white
                                        text-sm font-medium hover:z-10 hover:outline-none
                                        hover:ring-1 hover:ring-gray-300
                                        hover:border-gray-300`}
            >
              {" "}
              <TruckIcon
                className="ml-1 mr-1 h-5 w-5"
                aria-hidden="true"
              />{" "}
            </a>
          )}
        <button onClick={() => {
          window
            .open(
              "https://d2cwu4hex8j4mq.cloudfront.net/public/digest/" +
              detection.imageURL,
              "_new"
            )
            .focus();
        }}
                className={`-ml-px relative inline-flex
                             items-center px-2 py-1 border rounded-r-md
                             bg-green-500 hover:bg-gray-400 text-white
                              text-sm font-medium hover:z-10 hover:outline-none
                              hover:ring-1 hover:ring-gray-300
                              hover:border-gray-300`}>
          <PhotographIcon
            className="ml-1 mr-1 h-5 w-5" aria-hidden="true"></PhotographIcon>
        </button>
      </span>
      <div
        className="relative block w-full aspect-w-10 aspect-h-7
                  rounded-lg bg-gray-100 focus-within:ring-2
                  focus-within:ring-offset-2 focus-within:ring-offset-gray-100
                  focus-within:ring-gray-300 overflow-hidden"
      >
        <div
          className="hover:h-1/2 hover:opacity-60 z-50 text-white p-20 opacity-0 bg-black h-1/2 overflow-hidden cursor-pointer">
          {detection.description}
        </div>
        <img
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="311-List-Click-Image"
          style={{
            border: detection.detectionCleanupRequestId
              ? "5px #00FF00 solid"
              : detection.isTrash
                ? "10px #00AA00 dotted"
                : "2px #FF0000 dotted",
            opacity: detection.isTrash === false ? "0.5" : "1.0",
          }}
          src={
            "https://d2cwu4hex8j4mq.cloudfront.net/public/digest/" +
            detection.imageURL
          }
          alt=""
          className="pointer-events-none group-hover:opacity-75"
        />
      </div>
    </div>
  );
}
