const posts = [
    {
        title: 'Uncovering the Power of a .5 F1 Score: How to Succeed Even with Imperfect Results\n',
        href: '/imperfect-f1-score',
        category: { name: 'Article', href: '/imperfect-f1-score' },
        description:
          'When a leader or builder is determining the success of an AI initiative, it can be easy to focus on the outcome that looks best on paper - the one with a perfect score. But what if there was another way to measure your success? ',
        date: 'Jan 3, 2023',
        datetime: '2023-01-03',
        imageUrl:
          '/assets/imperfect-score.png',
        readingTime: '15 min',
        author: {
            name: 'Brian Johnson',
            href: '#',
            imageUrl:
              '/assets/Brian.jpg',
        },
    },
    {
        title: 'How 60 days of daily reporting led to a drastic decrease in illegal dumping in San Francisco\'s Bayview neighborhood',
        href: '/sixty-days',
        category: { name: 'Case Study', href: '/sixty-days' },
        description:
            'West Coast Dynamics, a community organization in San Francisco\'s Bayview neighborhood, decided to take action against the illegal dumping that was plaguing their community.For 60 days, they reported every dumpsite they came across to the city\'s Public Works Department. The result?',
        date: 'June 18, 2022',
        datetime: '2022-06-18',
        imageUrl:
            '/assets/5-20-22_piles.png',
        readingTime: '8 min',
        author: {
            name: 'Brian Johnson',
            href: '#',
            imageUrl:
                '/assets/Brian.jpg',
        },
    },
    {
        title: 'Preventing Illegal Dumping: The Importance of Prompt Cleanup',
        href: '/illegal-dumping',
        category: { name: 'Article', href: '/illegal-dumping' },
        description:
            'Keeping our environment clean is a joint effort. We can all do our part by properly disposing of our waste and recycling as much as possible.',
        date: 'Nov 25, 2022',
        datetime: '2022-11-25',
        imageUrl:
            '/assets/img.png',
        readingTime: '6 min',
        author: {
            name: 'Brian Johnson',
            href: '/about',
            imageUrl:
                '/assets/Brian.jpg',
        },
    },
    {
        title: 'Drones and AI: A Revolutionary Combination',
        href: '/drone-ai-data',
        category: { name: 'Article', href: '/drone-ai-data' },
        description:
            'The potential uses for drones are almost limitless. They can be used for everything from aerial photography to delivering packages. But perhaps their most important use is in data collection.',
        date: 'Nov 27, 2022',
        datetime: '2022-11-27',
        imageUrl:
            '/assets/m3e.jpg',
        readingTime: '4 min',
        author: {
            name: 'Brian Johnson',
            href: '/about',
            imageUrl:
                '/assets/Brian.jpg',
        },
    },
]

export default function Blog() {
    return (
        <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
            <div className="absolute inset-0">
                <div className="h-1/3 bg-white sm:h-2/3" />
            </div>
            <div className="relative mx-auto max-w-7xl">
                <div className="text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Drones + AI + Data Blog</h2>
                    <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">

                    </p>
                </div>
                <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                    {posts.map((post) => (
                        <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                            <div className="flex-shrink-0">
                                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                            </div>
                            <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-indigo-600">
                                        <a href={post.category.href} className="hover:underline">
                                            {post.category.name}
                                        </a>
                                    </p>
                                    <a href={post.href} className="mt-2 block">
                                        <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                                        <p className="mt-3 text-base text-gray-500">{post.description}</p>
                                    </a>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <div className="flex-shrink-0">
                                        <a href={post.author.href}>
                                            <span className="sr-only">{post.author.name}</span>
                                            <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                                        </a>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">
                                            <a href={post.author.href} className="hover:underline">
                                                {post.author.name}
                                            </a>
                                        </p>
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            <time dateTime={post.datetime}>{post.date}</time>
                                            <span aria-hidden="true">&middot;</span>
                                            <span>{post.readingTime} read</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

