/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
export default function DroneAIData() {
    return (
        <div className="relative overflow-hidden bg-white py-16">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-prose text-lg">
                    <h1>
                        <span className="block text-center text-lg font-semibold text-indigo-600">Prevention</span>
                        <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            Drones and AI:
:</span>
                    </h1>
                    <h2>
                        <span className="mt-2  mb-2 block text-center text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-3xl">
                            A Revolutionary Combination                            </span>
                    </h2>
                    <p className="mt-8 text-xl leading-8 text-gray-500">
                        The potential uses for drones are almost limitless. They can be used for everything from aerial photography to delivering packages. But perhaps their most important use is in data collection. Thanks to recent advances in drone and artificial intelligence (AI) technology, drones are now able to collect data faster than ever before. Here's a look at how this revolutionary combination is changing the way we collect data.
                    </p>
                    <div className="prose max-w-prose mx-auto mt-6 text-gray-500">
                        <h2>
                            <span className="mt-2  mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                                How Drones and AI Collect Data Faster Than Ever Before
                                </span>
                        </h2>
                        <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
                            Traditionally, data collection has been a slow and tedious process. Researchers would have to physically go to the location where they wanted to collect data and collect it by hand. This process was not only time-consuming, but it was also subject to human error.

                        </p>
                        <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
                            Drones, on the other hand, can cover a lot of ground quickly and efficiently. They can also be equipped with sophisticated sensors and AI software that can automatically detect and collect data. This combination of speed and accuracy means that drones can collect data much faster than traditional methods.
                        </p>
                        <figure>
                            <img
                                className="w-full rounded-lg"
                                src="/assets/m3e.jpg"
                                alt=""
                                width={1310}
                                height={873}
                            />
                            <figcaption className="text-center">Enterprise drones are compact and capable.</figcaption>
                        </figure>
                        <h2>
                            <span className="mt-2 mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                                What Does This Mean for The Future?
                                
                                </span>
                        </h2>
                        <p className="mt-8 text-xl leading-8 text-gray-500">
                            The possibilities for this technology are endless. In the future, we will likely see drones being used for everything from surveying land to monitoring traffic patterns. The amount of data that can be collected with drones is staggering, and it will only continue to grow as the technology improves.

                            </p>
                        <h2>
                            <span className="mt-2 mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                                Conclusion
                                </span>
                        </h2>
                        <p className="mt-8 text-xl leading-8 text-gray-500">
                            Drones and AI are a revolutionary combination that is changing the way we collect data. This new method is much faster and more accurate than traditional methods, which means that we will be able to collect vast amounts of data very quickly in the future. The possibilities for this technology are endless, and we will only continue to see more amazing applications for it as time goes on.

                            </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


