/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
import {Link} from "react-router-dom";
import React from "react";

export default function BudgetRequest() {
  return (<div className="relative bg-white">
    <div className="lg:absolute lg:inset-0">
      <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img className="h-56 w-full object-cover lg:absolute lg:h-full"
             src="/assets/large_pile_example.jpg"
             alt=""/>
      </div>
    </div>

    <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
      <div className="lg:col-start-2 lg:pl-8">
        <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
          <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Help us help everyone</h2>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Ask M333: Automatic Trash Reporting Pilot </h3>

          <p className="mt-8 text-lg text-gray-500">
            We are requesting funding for a six month pilot program to use aerial technology to detect, locate, and
            report trash and illegal dumping on public streets and sidewalks in D10 and D11, and reporting them to SF311
            for cleanup. The technology is safe, non-intrusive, not collecting any personally identifiable
            information </p>
          <p className="mt-8 text-lg text-gray-500">
            District 10 and District 11 neighborhoods in San Francisco, have a significant illegal dumping problem that
            deteriorates the quality of life and compromises health and safety of all residents. As stated by D10
            Supervisor Shamann Walton, “Illegal dumping is a huge problem and really hurts the community,” “it’s not
            fair for our families to walk or drive past trash every day. It’s not fair for our community to be dumped
            on. </p>

          <div className="bg-white">
            <div className="max-w-4xl mx-auto py-5 px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                <span className="block">Want to show support?</span>
                <span className="block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">Reach out to Supervisors
                </span>
              </h2>
              <div className="ml-3 mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                <Link to="/leaders"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-200 hover:bg-indigo-100"> Send an Email </Link>
              </div>
            </div>
          </div>
          <p className="mt-8 text-lg text-gray-500">
            Currently, there are a number of city cleaning programs that clean main street corridors (3rd Street
            corridor street cleaning), and various volunteer organizations (RefuseRefuseSF.org) that organize volunteer
            cleanups, along with fines. It is still not enough, however, to keep the streets clean and free of illegal
            dumping. </p>
          <p className="mt-8 text-lg text-gray-500">
            Garbage is dumped daily at many locations, ranging from industrial, construction materials, toxic items, and
            large and small household items. The Department of Public Works and Recology are not equipped or staffed to
            find and remove all illegally dumped garbage. Currently it is up to residents to either file 311 reports
            directly or hope that a nearby neighbor cleans it up. </p>
        </div>
      </div>
    </div>
  </div>)
}
