/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/

import {useForm} from "react-hook-form";
import React, {useState} from "react";
import {API} from "aws-amplify";
import {Link, useNavigate} from "react-router-dom";
import {createSubscriber} from "./graphql/mutations";

const emailBody = (firstName, lastName, email, neighborhood) => `
Dear Representatives Shamann Walton and Ahsha Safai, and DPW Director Carla Short,

My name is ${firstName} ${lastName} and I am a resident of ${neighborhood} neighborhood. You are my representative. I want to support you in making our neighborhood a great place to live.

I'm writing to let you know my priorities for the City's 2022-2023 budget. I'd like the city to invest in early detection and reporting of illegal dump-sites, litter, and trash piles on our streets. Funding early detection helps to keep public spaces clean. Public spaces are important to my and my family's health and safety.

There is a proposed budget to fund a pilot program for an Automated Trash Detecting and Reporting system in your Districts. The project ensures that illegally dumped trash in our district is reported automatically. It relieves a substantial burden on residents like me, to constantly report garbage on the streets and sidewalks myself.
The project pilot program is being proposed "Ask M333: Automatic Trash Reporting Pilot."

https://www.westcoastdynamics.com/budget

I see an impact being made by the self-funded project already, and I support this project getting city funding. I see the value of using technology like this in novel ways to provide solutions to city problems.

Thanks for your time and everything you do for our city.

Thanks,

${firstName} ${lastName}
${neighborhood} Resident
`;

const neighborhoods = [
  "Bayview",
  "Crocker Amazon",
  "Dogpatch",
  "Excelsior",
  "Hunter's Point",
  "Ocean View",
  "Potrero Hill",
  "Potrero Terrace",
  "Visitacion Valley"
]

const shamann = 'shamann.walton@sfgov.org';
const ahsha = 'ahsha.safai@sfgov.org';
const carla = 'carla.short@sfgov.org';
const percy = 'Percy.Burch@sfgov.org';
const tracy = 'Tracy.Gallardo@sfgov.org';
const natalie = 'Natalie.Gee@sfgov.org';
const abe = 'Abe.Evans@sfgov.org';
const lauren = 'Lauren.L.Chung@sfgov.org';
const ernest = 'Ernest.E.Jones@sfgov.org';
const sam = 'Sam.Berenson@sfgov.org';
const bill = 'Bill.Barnes@sfgov.org';


const addE2 = (copy) => {
  return copy.replaceAll(/[\n\r]/g, "%0A");
}
const subject = 'I Support the Automated Trash Detection and Reporting Project';
const createMailtoLink = (firstName, lastName, email,
                          district) => `mailto:${shamann},${ahsha},${carla},${percy},${tracy},${natalie},${abe},${lauren},${ernest},${sam},${bill}?cc=brian@westcoastdynamics.com&subject=${subject}&body=${emailBody(
  firstName, lastName, email, district)}`;

const manualEmails = `${shamann},${ahsha},${carla},${percy},${tracy},${natalie},${abe},${lauren},${ernest},${sam},${bill},brian@westcoastdynamics.com`;

export default function EmailLeadership() {
  const history = useNavigate();
  const [firstName, setFirstName] = useState("First Name");
  const [lastName, setLastName] = useState("Last Name");
  const [email, setEmail] = useState("concerned@resident.com");
  const [district, setDistrict] = useState("Bayview");
  const {
    register,
    handleSubmit,
    setError,
    formState: {errors}
  } = useForm({criteriaMode: 'all'});

  const onError = (errors, e) => {
    if (errors['lastName']) setError("lastName", {
      types: {
        required: "This is required"
      },
      message: "* required field"
    });
    if (errors['firstName']) setError("firstName", {
      types: {
        required: "This is required"
      },
      message: "* required field"
    });
    if (errors['email']) setError("email", {
      types: {
        required: "This is required"
      },
      message: "* required field"
    });
  }

  const submitHandler = async (data) => {
    const mailto = createMailtoLink(data.firstName, data.lastName, data.email, data.district);
    data.subscribed = false;
    const subscriber = {
      subscribed: false,
      email: data.email
    }
    window.open(addE2(mailto));
    await API.graphql({
      query: createSubscriber,
      variables: {input: subscriber},
      authMode: "API_KEY"
    });
    history("/thanks");
  };
  return (<main>
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-1/2">
        <div className="relative h-full text-lg max-w-prose mx-auto"
             aria-hidden="true">
          <svg className="absolute top-12 left-full transform translate-x-32"
               width={404}
               height={384}
               fill="none"
               viewBox="0 0 404 384">
            <defs>
              <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                       x={0}
                       y={0}
                       width={20}
                       height={20}
                       patternUnits="userSpaceOnUse">
                <rect x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"/>
              </pattern>
            </defs>
            <rect width={404}
                  height={384}
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
          </svg>
          <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
               width={404}
               height={384}
               fill="none"
               viewBox="0 0 404 384">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                       x={0}
                       y={0}
                       width={20}
                       height={20}
                       patternUnits="userSpaceOnUse">
                <rect x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"/>
              </pattern>
            </defs>
            <rect width={404}
                  height={384}
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
          </svg>
          <svg className="absolute bottom-12 left-full transform translate-x-32"
               width={404}
               height={384}
               fill="none"
               viewBox="0 0 404 384">
            <defs>
              <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034"
                       x={0}
                       y={0}
                       width={20}
                       height={20}
                       patternUnits="userSpaceOnUse">
                <rect x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"/>
              </pattern>
            </defs>
            <rect width={404}
                  height={384}
                  fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8 w-3/4 mx-auto">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Show Support for Automated Trash Reporting
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Email the San Francisco Board of Supervisors
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Our pilot program would bring automated trash detection and reporting program to all of Districts 10 and 11
            for six months. It would ensure that all garbage piles are reported, helping keep our streets clean.
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Please show your support with an email to the Supervisor's office.
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Just fill in your info and click the button below to quickly compose a new email.
          </p>
        </div>
        <div className="mt-2 prose prose-indigo w-3/4 prose-lg text-gray-500 mx-auto min-w-full">
          <div className="py-2 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <form onSubmit={handleSubmit(submitHandler, onError)}
                  className="w-3/4 mx-auto mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div>
                <label htmlFor="first-name"
                       className="block text-sm font-medium text-warm-gray-900"> First name </label>
                <div className="mt-1">
                  <input type="text"
                         name="first-name"
                         placeholder="Caring"
                         id="first-name"{...register("firstName", {required: true})}
                         onChange={(e) => {
                           setFirstName(e.target.value)
                         }}
                         autoComplete="given-name"
                         className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"/> {errors.firstName &&
                  <p>{errors.firstName.message}</p>}
                </div>
              </div>
              <div>
                <label htmlFor="last-name"
                       className="block text-sm font-medium text-warm-gray-900"> Last name </label>
                <div className="mt-1">
                  <input type="text"
                         name="last-name"
                         placeholder="Neighbor"
                         id="last-name"{...register("lastName", {required: true})}
                         onChange={(e) => {
                           setLastName(e.target.value)
                         }}
                         autoComplete="family-name"
                         className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"/>{errors.lastName &&
                  <p>{errors.lastName.message}</p>}
                </div>
              </div>
              <div>
                <label htmlFor="email"
                       className="block text-sm font-medium text-warm-gray-900"> Email </label>
                <div className="mt-1">
                  <input id="email"
                         name="email"{...register("email", {required: true})}
                         onChange={(e) => {
                           setEmail(e.target.value)
                         }}
                         type="email"
                         autoComplete="email"
                         className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"/>{errors.email &&
                  <p>{errors.email.message}</p>}
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <label htmlFor="district"
                         className="block text-sm font-medium text-warm-gray-900"> Neighborhood </label>
                </div>
                <div className="mt-1">
                  <select name="district"
                          id="district" {...register("district", {required: true})}
                          onChange={(e) => {
                            setDistrict(e.target.value)
                          }}
                          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
                          aria-describedby="district-optional">
                    {neighborhoods.map(n => <option key={n}
                                                    value={n}>{n}</option>)}
                  </select>
                </div>
              </div>
              <button type={"submit"}
                      className="mt-6 w-full mx-auto block bg-gradient-to-r from-orange-500 to-pink-500 border border-transparent rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-pink-600"
                      target="supervisor_email">Send Pre-Written Email
              </button>
            </form>
          </div>
          <div className="text-lg max-w-prose min-w-full mx-auto">
            <p>To (Email it to these email addresses): <textarea rows={5}
                                                                 cols={100}
                                                                 className={"w-full text-sm rounded-2xl border-2 bg-gray-100 text-black"}
                                                                 readOnly
                                                                 value={manualEmails}/>
            </p>
            <p>CC (CC me so I can track sends): <textarea rows={1}
                                                          cols={100}
                                                          className={"w-full text-sm rounded-2xl border-2 bg-gray-100 text-black"}
                                                          readOnly
                                                          value={`brian@westcoastdynamics.com`}/>
            </p>
            <p>Subject (Use this subject): <textarea rows={3}
                                                     cols={100}
                                                     className={"w-full text-sm rounded-2xl border-2 bg-gray-100 text-black"}
                                                     readOnly
                                                     value={`${subject}`}/>
            </p>
            <p>Message (Use this precompiled message body): <textarea rows={35}
                                                                      cols={100}
                                                                      className={"w-full text-sm rounded-2xl border-2 bg-gray-100 text-black"}
                                                                      readOnly
                                                                      value={decodeURI(
                                                                        emailBody(firstName, lastName, email,
                                                                          district))}/>
            </p>
          </div>
        </div>
        <div className="bg-white">
          <div className="max-w-4xl mx-auto py-5 px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
              <span className="block">Want to learn more?</span>
              <span className="block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">Check
                                                                                                                   out
                                                                                                                   our
                                                                                                                   about
                                                                                                                   page
              </span>
            </h2>
            <div className="ml-3 mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
              <Link to="/about"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-200 hover:bg-indigo-100"> About
                                                                                                                                                                                                   Us </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>)
}
