import React from "react";

import {
  BeakerIcon,
  CameraIcon,
  CheckIcon,
  DownloadIcon,
  EyeOffIcon,
  TrashIcon,
  TruckIcon,
  XIcon,
} from "@heroicons/react/solid";
import {API, graphqlOperation} from "aws-amplify";
import {
  createCleanupRequest,
  createTrainingDatapoint,
  deleteTrainingDatapoint,
  updateDetection
} from "./graphql/mutations";

const markIsTrash = async (id, isTrash) => {
  const result = await API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id,
        isTrash,
        status: "processed",
      },
    })
  );
  return result;
};

const updateDetect = (id, detectionCleanupRequestId, isTrash) =>
  API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id,
        detectionCleanupRequestId,
        isTrash,
        status: "processed",
      },
    })
  );

const createCleanup = async (detection, code, description) => {
  const result = await API.graphql(
    graphqlOperation(createCleanupRequest, {
      input: {
        code,
        description,
        cleanupRequestDetectionId: detection.id,
      },
    })
  );
  const cleanupRequest = result.data.createCleanupRequest;
  const updateResult = await updateDetect(
    detection.id,
    cleanupRequest.id,
    true
  );
  return [result, updateResult];
};

const removeDetectionFromDataset = async (detection) => {
  const remove = await API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id: detection.id,
        detectionTrainingDatapointId: null,
      },
    })
  );
  const del = await API.graphql(
    graphqlOperation(deleteTrainingDatapoint, {
      input: {id: detection.detectionTrainingDatapointId},
    })
  );
};

const addDetectionToDataset = async (detection) => {
  if (!detection?.id) {
    return;
  }
  const manifestKey = detection.imageURL.replace(
    /size-large-detection.*/,
    "size-large-training"
  );
  const manifestURL = `public/training/${manifestKey}.json`;
  const variables = {
    input: {
      manifestURL,
      trainingDatapointDetectionId: detection.id,
    },
  };
  const result = await API.graphql(
    graphqlOperation(createTrainingDatapoint, variables)
  );
  const detectionTrainingDatapointId =
    result?.data?.createTrainingDatapoint?.id;

  const detectionResult = await API.graphql(
    graphqlOperation(updateDetection, {
      input: {
        id: detection.id,
        detectionTrainingDatapointId,
      },
    })
  );
  return {
    result,
  };
};

export default function Detection({
                                    detection,
                                    showArrows = false,
                                    ignoreCallback = () => {
                                    },
                                    trashCallback = () => {
                                    },
                                  }) {
  const closed = detection?.cleanupRequest?.status === "closed";
  return (
    <div
      key={detection.id}
      id={detection.imageURL}
      className="relative w-full h-full "
    >
      <span
        className="top-1 right-2 absolute mt-2 px-2 py-0 rounded-full pr-5 block z-50 text-sm font-medium text-white text-right truncate pointer-events-none bg-yellow-500 opacity-80">
        {new Date(detection.captureDatetime).toDateString()}
      </span>
      <span
        className="top-10 left-2 absolute mt-2 px-2 py-0 rounded-full block z-50 text-sm font-medium text-white left truncate pointer-events-none bg-yellow-500 opacity-80">
        {Number(detection?.confidence?.toFixed(2))}
      </span>
      <span
        className="w-full bottom left absolute mt-7 pr-5 block z-50 text-sm font-medium text-white text-right truncate pointer-events-none"
        style={{
          background: `rgba(${closed ? "200, 100, 100" : "100, 200, 100"
          }, 0.5)`,
        }}
      >
        <p>
          {detection?.cleanupRequest?.status
            ? detection?.cleanupRequest?.status
            : ""}
        </p>
        <p>
          {detection?.cleanupRequest?.statusNotes
            ? detection?.cleanupRequest?.statusNotes
            : ""}
        </p>
      </span>
      <span
        style={{zIndex: 50}}
        className="absolute bottom-0 right-0 z-0 inline-flex shadow-sm rounded-md overflow-visible"
      >
        {!!detection.cleanupRequest?.id &&
        detection.cleanupRequest?.serviceToken ? (
          <a
            href={`https://mobile311.sfgov.org/reports/${detection.cleanupRequest?.serviceToken}`}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="311-List-Check-Status"
            target={"external"}
            type="button"
            className={`-ml-px relative inline-flex
                                       items-center px-2 py-1 border
                                       bg-green-500 hover:bg-indigo-600 text-white
                                        text-sm font-medium hover:z-10 hover:outline-none
                                        hover:ring-1 hover:ring-indigo-500
                                        hover:border-indigo-500`}
          >
            <TruckIcon className="ml-1 mr-1 h-5 w-5" aria-hidden="true"/>{" "}
          </a>
        ) : <>
          <button onClick={() => createCleanup(detection, "City_garbage_can_overflowing", detection.description)}
                  className={`-ml-px rounded-l-md inline-flex items-center px-2 py-2
                            border border-gray-300 text-sm font-medium
                            focus:z-10 focus:outline-none focus:ring-1
                            focus:ring-gray-300 focus:border-gray-300
                            bg-gray-500 hover:bg-gray-400 text-white`}>
            overflow
          </button>
          <button
            onClick={() => createCleanup(detection, "Other_bagged_boxed_contained_garbage", detection.description)}
            className={`-ml-px inline-flex items-center px-2 py-2
                            border border-gray-300 text-sm font-medium
                            focus:z-10 focus:outline-none focus:ring-1
                            focus:ring-gray-300 focus:border-gray-300
                            bg-gray-500 hover:bg-gray-400 text-white`}>
            bagged
          </button>
          <button
            onClick={() => createCleanup(detection, "Other_loose_garbage_debris_yard_waste", detection.description)}
            className={`-ml-px inline-flex items-center px-2 py-2
                            border border-gray-300 text-sm font-medium
                            focus:z-10 focus:outline-none focus:ring-1
                            focus:ring-gray-300 focus:border-gray-300
                            bg-gray-500 hover:bg-gray-400 text-white`}>
            loose
          </button>
        </>}
        <a
          href={`/in/panzoom/${detection.id}`}
          target={`/in/panzoom/${detection.id}`}
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="311-List-Ignore"
          type="button"
          className={`-ml-px inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-500 hover:bg-indigo-600 text-white`}
        >
          <CameraIcon className="ml-1 mr-1 h-5 w-5"/>
        </a>
        {detection.detectionTrainingDatapointId ? (
          <>
            <a
              href={`/in/annotator/${detection.detectionTrainingDatapointId}`}
              target="annotate"
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Ignore"
              type="button"
              className={`-ml-px inline-flex items-center px-2 py-2
                   border border-gray-300 text-sm font-medium
                   focus:z-10 focus:outline-none focus:ring-1
                   focus:ring-indigo-500 focus:border-indigo-500
                   bg-green-500 hover:bg-indigo-600 text-white`}
            >
              <CheckIcon className="ml-1 mr-1 h-5 w-5"/>{" "}
            </a>
            <a
              onClick={() => removeDetectionFromDataset(detection)}
              target="annotate"
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="311-List-Ignore"
              type="button"
              className={`-ml-px inline-flex items-center px-2 py-2
                 border border-gray-300 text-sm font-medium
                 focus:z-10 focus:outline-none focus:ring-1
                 focus:ring-indigo-500 focus:border-indigo-500
                 bg-green-500 hover:bg-indigo-600 text-white`}
            >
              <XIcon className="ml-1 mr-1 h-5 w-5"/>{" "}
            </a>
          </>
        ) : <a
          onClick={() => addDetectionToDataset(detection)}
          target="annotate"
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="311-List-Ignore"
          type="button"
          className={`-ml-px inline-flex items-center px-2 py-2
           border border-gray-300 text-sm font-medium cursor-pointer
           focus:z-10 focus:outline-none focus:ring-1
           focus:ring-indigo-500 focus:border-indigo-500
           bg-green-500 hover:bg-indigo-600 text-white`}
        >
          <BeakerIcon className="ml-1 mr-1 h-5 w-5"/>{" "}
        </a>}
        {detection.isTrash ? (
          <button
            onClick={() => {
              markIsTrash(detection.id, false);
              ignoreCallback(detection.id);
            }}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="311-List-Ignore"
            type="button"
            className={`-ml-px inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-green-500 hover:bg-indigo-600 text-white`}
          >
            <TrashIcon className="ml-1 mr-1 h-5 w-5"/>
          </button>
        ) : (
          <button
            onClick={() => {
              markIsTrash(detection.id, true);
              trashCallback(detection.id);
            }}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="311-List-Ignore"
            type="button"
            className={`-ml-px inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-indigo-500 hover:bg-indigo-600 text-white`}
          >
            <TrashIcon className="ml-1 mr-1 h-5 w-5"/>
          </button>
        )}
        <button
          onClick={() => {
            markIsTrash(detection.id, false);
            ignoreCallback(detection.id);
          }}
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="311-List-Ignore"
          type="button"
          className={`-ml-px inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-indigo-500 hover:bg-indigo-600 text-white`}
        >
          <EyeOffIcon className="ml-1 mr-1 h-5 w-5"/>
        </button>
        <a
          href={
            "https://d2cwu4hex8j4mq.cloudfront.net/public/digest/" +
            detection.imageURL
          }
          type="button"
          className={`-ml-px inline-flex rounded-r-md items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-indigo-500 hover:bg-indigo-600 text-white`}
          download={`test.jpg`}
          target="_blank"
        >
          <DownloadIcon className="ml-1 mr-1 h-5 w-5"/>
        </a>
      </span>
      <div
        onClick={() => window.open(`/in/filerequest/${detection.id}`)}
        className="relative block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
      >
        <div
          className="hover:h-full hover:opacity-60 z-50 text-white p-5 opacity-0 bg-black h-8 overflow-hidden cursor-pointer">
          {detection.description}
        </div>
        <img
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="311-List-Click-Image"
          style={{
            border: detection.detectionCleanupRequestId
              ? "5px #00FF00 solid"
              : detection.isTrash
                ? "5px #FFBB00 solid"
                : detection.isTrash === false
                  ? "5px #FF0000 solid"
                  : "5px #000000 solid",
            opacity: detection.isTrash === false ? "0.5" : "1.0",
          }}
          src={
            "https://d2cwu4hex8j4mq.cloudfront.net/public/digest/" +
            detection.imageURL
          }
          alt=""
          className="pointer-events-none group-hover:opacity-75 shadow-xl shadow-gray-500"
        />
      </div>
    </div>
  );
}
