import React, { useEffect, useRef, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getDetection } from "./graphql/queries";
import Panzoom from "@panzoom/panzoom";
import "./PanzoomAerial.css";
import { useParams } from "react-router-dom";
import { Annotorious } from "@recogito/annotorious";

const stopOtherEvents = (event) => {
  event.detail.originalEvent.stopPropagation();
  event.detail.originalEvent.preventDefault();
};

const allowOtherEvents = () => {};

const panOnOptions = {
  handleStartEvent: (event) => {},
  canvas: true,
  contain: "outside",
  overflow: "show",
  maxScale: 40,
  minScale: 0.01,
  disablePan: true,
};

const mapManifestToAnnotation = (height, width) => (annotation) => {
  const {
    Left: x,
    Top: y,
    Width: w,
    Height: h,
  } = annotation?.Geometry?.BoundingBox;

  return {
    "@context": "http://www.w3.org/ns/anno.jsonld",
    id: `annotation-${x}-${y}-${w}-${h}`,
    type: "Annotation",
    body: [
      {
        type: "TextualBody",
        value: annotation.Name,
      },
      {
        type: "TextualBody",
        value: annotation.Confidence,
      },
    ],
    target: {
      selector: {
        type: "FragmentSelector",
        conformsTo: "http://www.w3.org/TR/media-frags/",
        value: `xywh=pixel:${width * x},${height * y},${width * w},${
          height * h
        }`,
      },
    },
  };
};

export const PanzoomAerial = () => {
  const { id } = useParams();
  const imgRef = useRef(null);
  const annotatorRef = useRef(null);
  const panzoomRef = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const [manifestKey, setManifestKey] = useState("");
  const [captureDate, setCaptureDate] = useState("");

  useEffect(() => {
    if (!!!id || manifestKey.length === 0) return;
    let storagePromise;
    (async () => {
      try {
        storagePromise = Storage.get(manifestKey);
        const manifestURL = await storagePromise;
        const manifestResponse = await fetch(manifestURL);
        const manifest = await manifestResponse.json();
        annotatorRef.current.setAnnotations(
          manifest?.map(
            mapManifestToAnnotation(
              imgRef.current.naturalHeight,
              imgRef.current.naturalWidth
            )
          )
        );
      } catch (e) {
        if (API.isCancel(e)) {
          console.log("Cancelled API");
        }
      }
    })();
    return () => {
      Storage.cancel(storagePromise, "Cancelling manifest request");
    };
  }, [manifestKey]);

  useEffect(() => {
    if (
      !imgRef?.current ||
      !!!imageURL ||
      imageURL === "https://d2cwu4hex8j4mq.cloudfront.net/public/hires/"
    )
      return;
    panzoomRef.current = Panzoom(imgRef.current.parentElement, panOnOptions);
    panzoomRef.current.zoom(1, { animate: true });
    imgRef.current.parentElement.addEventListener("wheel", function (event) {
      panzoomRef.current.zoomWithWheel(event);
    });
    imgRef.current.parentElement.removeEventListener(
      "panzoomstart",
      stopOtherEvents
    );
    imgRef.current.parentElement.addEventListener(
      "panzoomstart",
      allowOtherEvents
    );
    return () => {
      if (
        !imgRef?.current ||
        !!!imageURL ||
        imageURL === "https://d2cwu4hex8j4mq.cloudfront.net/public/hires/"
      )
        return;
      panzoomRef.current.destroy();
    };
  }, [imageURL]);

  useEffect(() => {
    if (
      !imgRef?.current ||
      !!!imageURL ||
      imageURL === "https://d2cwu4hex8j4mq.cloudfront.net/public/hires/"
    )
      return;
    imgRef.current.parentElement.removeEventListener(
      "panzoomstart",
      stopOtherEvents
    );
    imgRef.current.parentElement.addEventListener(
      "panzoomstart",
      allowOtherEvents
    );
  }, []);

  useEffect(() => {
    if (
      !imgRef?.current ||
      !!!imageURL ||
      imageURL === "https://d2cwu4hex8j4mq.cloudfront.net/public/hires/"
    )
      return;
    if (!imgRef.current) return;
    annotatorRef.current = new Annotorious({
      image: imgRef.current,
      readOnly: false,
      disableEditor: true,
    });

    annotatorRef.current.on("createSelection", async (selection) => {
      const value = selection.target.selector.value;
      const matches = value.match(
        /xywh=pixel:([0-9]*)\..*,([0-9]*)\..*,([0-9]*)\..*,([0-9]*)\..*/
      );
      // "xywh=pixel:1853.4561767578125,1167.3828125,422.4083251953125,995.859375";
      const [, x, y, w, h] = matches;
    });
    return () => {
      annotatorRef.current.destroy();
    };
  }, [imageURL]);

  useEffect(() => {
    (async () => {
      const detectionResult = await API.graphql(
        graphqlOperation(getDetection, { id })
      );
      const result = detectionResult?.data?.getDetection;
      setCaptureDate(result.captureDatetime);
      setImageURL(
        "https://d2cwu4hex8j4mq.cloudfront.net/public/highres/" +
          result.imageURL
            .replace(/^.+?-/, "")
            .replace(/large-detection.*/, "hires.jpg")
      );
      setManifestKey(
        "detections/" +
          result.imageURL.replace(/-detection.*/, "-detection.json")
      );
    })();
  }, [id]);

  return (
    <div>
      <div className="h-full w-auto max-w-full max-h-full">
        <span className="absolute top-0 left-0 z-50 bg-amber-500">
          {captureDate}
        </span>
        {!!!imageURL ||
        imageURL ===
          "https://d2cwu4hex8j4mq.cloudfront.net/public/hires/" ? null : (
          <img id="annotating" ref={imgRef} src={imageURL} alt="sample" />
        )}
      </div>
    </div>
  );
};
