/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCheckAddress = /* GraphQL */ `
  subscription OnCreateCheckAddress(
    $filter: ModelSubscriptionCheckAddressFilterInput
  ) {
    onCreateCheckAddress(filter: $filter) {
      id
      address
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCheckAddress = /* GraphQL */ `
  subscription OnUpdateCheckAddress(
    $filter: ModelSubscriptionCheckAddressFilterInput
  ) {
    onUpdateCheckAddress(filter: $filter) {
      id
      address
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCheckAddress = /* GraphQL */ `
  subscription OnDeleteCheckAddress(
    $filter: ModelSubscriptionCheckAddressFilterInput
  ) {
    onDeleteCheckAddress(filter: $filter) {
      id
      address
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSignupInterest = /* GraphQL */ `
  subscription OnCreateSignupInterest(
    $filter: ModelSubscriptionSignupInterestFilterInput
  ) {
    onCreateSignupInterest(filter: $filter) {
      id
      firstName
      lastName
      email
      street
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSignupInterest = /* GraphQL */ `
  subscription OnUpdateSignupInterest(
    $filter: ModelSubscriptionSignupInterestFilterInput
  ) {
    onUpdateSignupInterest(filter: $filter) {
      id
      firstName
      lastName
      email
      street
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSignupInterest = /* GraphQL */ `
  subscription OnDeleteSignupInterest(
    $filter: ModelSubscriptionSignupInterestFilterInput
  ) {
    onDeleteSignupInterest(filter: $filter) {
      id
      firstName
      lastName
      email
      street
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCheckAvailability = /* GraphQL */ `
  subscription OnCreateCheckAvailability(
    $filter: ModelSubscriptionCheckAvailabilityFilterInput
  ) {
    onCreateCheckAvailability(filter: $filter) {
      id
      zip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCheckAvailability = /* GraphQL */ `
  subscription OnUpdateCheckAvailability(
    $filter: ModelSubscriptionCheckAvailabilityFilterInput
  ) {
    onUpdateCheckAvailability(filter: $filter) {
      id
      zip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCheckAvailability = /* GraphQL */ `
  subscription OnDeleteCheckAvailability(
    $filter: ModelSubscriptionCheckAvailabilityFilterInput
  ) {
    onDeleteCheckAvailability(filter: $filter) {
      id
      zip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact($filter: ModelSubscriptionContactFilterInput) {
    onCreateContact(filter: $filter) {
      id
      firstName
      lastName
      email
      phone
      subject
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact($filter: ModelSubscriptionContactFilterInput) {
    onUpdateContact(filter: $filter) {
      id
      firstName
      lastName
      email
      phone
      subject
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact($filter: ModelSubscriptionContactFilterInput) {
    onDeleteContact(filter: $filter) {
      id
      firstName
      lastName
      email
      phone
      subject
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSubscriber = /* GraphQL */ `
  subscription OnCreateSubscriber(
    $filter: ModelSubscriptionSubscriberFilterInput
  ) {
    onCreateSubscriber(filter: $filter) {
      id
      email
      subscribed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSubscriber = /* GraphQL */ `
  subscription OnUpdateSubscriber(
    $filter: ModelSubscriptionSubscriberFilterInput
  ) {
    onUpdateSubscriber(filter: $filter) {
      id
      email
      subscribed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSubscriber = /* GraphQL */ `
  subscription OnDeleteSubscriber(
    $filter: ModelSubscriptionSubscriberFilterInput
  ) {
    onDeleteSubscriber(filter: $filter) {
      id
      email
      subscribed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAerial = /* GraphQL */ `
  subscription OnCreateAerial($filter: ModelSubscriptionAerialFilterInput) {
    onCreateAerial(filter: $filter) {
      id
      filename
      lat
      long
      rotation
      altitude
      captureDatetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAerial = /* GraphQL */ `
  subscription OnUpdateAerial($filter: ModelSubscriptionAerialFilterInput) {
    onUpdateAerial(filter: $filter) {
      id
      filename
      lat
      long
      rotation
      altitude
      captureDatetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAerial = /* GraphQL */ `
  subscription OnDeleteAerial($filter: ModelSubscriptionAerialFilterInput) {
    onDeleteAerial(filter: $filter) {
      id
      filename
      lat
      long
      rotation
      altitude
      captureDatetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateImageVersions = /* GraphQL */ `
  subscription OnCreateImageVersions(
    $filter: ModelSubscriptionImageVersionsFilterInput
  ) {
    onCreateImageVersions(filter: $filter) {
      id
      originalKey
      detectionKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateImageVersions = /* GraphQL */ `
  subscription OnUpdateImageVersions(
    $filter: ModelSubscriptionImageVersionsFilterInput
  ) {
    onUpdateImageVersions(filter: $filter) {
      id
      originalKey
      detectionKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteImageVersions = /* GraphQL */ `
  subscription OnDeleteImageVersions(
    $filter: ModelSubscriptionImageVersionsFilterInput
  ) {
    onDeleteImageVersions(filter: $filter) {
      id
      originalKey
      detectionKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDetection = /* GraphQL */ `
  subscription OnCreateDetection(
    $filter: ModelSubscriptionDetectionFilterInput
  ) {
    onCreateDetection(filter: $filter) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const onUpdateDetection = /* GraphQL */ `
  subscription OnUpdateDetection(
    $filter: ModelSubscriptionDetectionFilterInput
  ) {
    onUpdateDetection(filter: $filter) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const onDeleteDetection = /* GraphQL */ `
  subscription OnDeleteDetection(
    $filter: ModelSubscriptionDetectionFilterInput
  ) {
    onDeleteDetection(filter: $filter) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const onCreatePilotSignup = /* GraphQL */ `
  subscription OnCreatePilotSignup(
    $filter: ModelSubscriptionPilotSignupFilterInput
  ) {
    onCreatePilotSignup(filter: $filter) {
      id
      firstName
      lastName
      email
      country
      streetAddress
      city
      region
      postalCode
      license
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePilotSignup = /* GraphQL */ `
  subscription OnUpdatePilotSignup(
    $filter: ModelSubscriptionPilotSignupFilterInput
  ) {
    onUpdatePilotSignup(filter: $filter) {
      id
      firstName
      lastName
      email
      country
      streetAddress
      city
      region
      postalCode
      license
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePilotSignup = /* GraphQL */ `
  subscription OnDeletePilotSignup(
    $filter: ModelSubscriptionPilotSignupFilterInput
  ) {
    onDeletePilotSignup(filter: $filter) {
      id
      firstName
      lastName
      email
      country
      streetAddress
      city
      region
      postalCode
      license
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateServiceRequest = /* GraphQL */ `
  subscription OnCreateServiceRequest(
    $filter: ModelSubscriptionServiceRequestFilterInput
  ) {
    onCreateServiceRequest(filter: $filter) {
      service_request_id
      status
      status_notes
      service_name
      service_code
      description
      requested_datetime
      updated_datetime
      address
      lat
      long
      media_url
      duplicateId
      statusType
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      id
      createdAt
      updatedAt
      cleanupRequestServiceRequestsId
      __typename
    }
  }
`;
export const onUpdateServiceRequest = /* GraphQL */ `
  subscription OnUpdateServiceRequest(
    $filter: ModelSubscriptionServiceRequestFilterInput
  ) {
    onUpdateServiceRequest(filter: $filter) {
      service_request_id
      status
      status_notes
      service_name
      service_code
      description
      requested_datetime
      updated_datetime
      address
      lat
      long
      media_url
      duplicateId
      statusType
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      id
      createdAt
      updatedAt
      cleanupRequestServiceRequestsId
      __typename
    }
  }
`;
export const onDeleteServiceRequest = /* GraphQL */ `
  subscription OnDeleteServiceRequest(
    $filter: ModelSubscriptionServiceRequestFilterInput
  ) {
    onDeleteServiceRequest(filter: $filter) {
      service_request_id
      status
      status_notes
      service_name
      service_code
      description
      requested_datetime
      updated_datetime
      address
      lat
      long
      media_url
      duplicateId
      statusType
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      id
      createdAt
      updatedAt
      cleanupRequestServiceRequestsId
      __typename
    }
  }
`;
export const onCreateCleanupRequest = /* GraphQL */ `
  subscription OnCreateCleanupRequest(
    $filter: ModelSubscriptionCleanupRequestFilterInput
  ) {
    onCreateCleanupRequest(filter: $filter) {
      id
      code
      description
      url
      serviceRequestId
      serviceToken
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      statusNotes
      status
      statusType
      serviceRequests {
        items {
          service_request_id
          status
          status_notes
          service_name
          service_code
          description
          requested_datetime
          updated_datetime
          address
          lat
          long
          media_url
          duplicateId
          statusType
          id
          createdAt
          updatedAt
          cleanupRequestServiceRequestsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      cleanupRequestDetectionId
      __typename
    }
  }
`;
export const onUpdateCleanupRequest = /* GraphQL */ `
  subscription OnUpdateCleanupRequest(
    $filter: ModelSubscriptionCleanupRequestFilterInput
  ) {
    onUpdateCleanupRequest(filter: $filter) {
      id
      code
      description
      url
      serviceRequestId
      serviceToken
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      statusNotes
      status
      statusType
      serviceRequests {
        items {
          service_request_id
          status
          status_notes
          service_name
          service_code
          description
          requested_datetime
          updated_datetime
          address
          lat
          long
          media_url
          duplicateId
          statusType
          id
          createdAt
          updatedAt
          cleanupRequestServiceRequestsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      cleanupRequestDetectionId
      __typename
    }
  }
`;
export const onDeleteCleanupRequest = /* GraphQL */ `
  subscription OnDeleteCleanupRequest(
    $filter: ModelSubscriptionCleanupRequestFilterInput
  ) {
    onDeleteCleanupRequest(filter: $filter) {
      id
      code
      description
      url
      serviceRequestId
      serviceToken
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      statusNotes
      status
      statusType
      serviceRequests {
        items {
          service_request_id
          status
          status_notes
          service_name
          service_code
          description
          requested_datetime
          updated_datetime
          address
          lat
          long
          media_url
          duplicateId
          statusType
          id
          createdAt
          updatedAt
          cleanupRequestServiceRequestsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      cleanupRequestDetectionId
      __typename
    }
  }
`;
export const onCreateTrainingDatapoint = /* GraphQL */ `
  subscription OnCreateTrainingDatapoint(
    $filter: ModelSubscriptionTrainingDatapointFilterInput
  ) {
    onCreateTrainingDatapoint(filter: $filter) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const onUpdateTrainingDatapoint = /* GraphQL */ `
  subscription OnUpdateTrainingDatapoint(
    $filter: ModelSubscriptionTrainingDatapointFilterInput
  ) {
    onUpdateTrainingDatapoint(filter: $filter) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const onDeleteTrainingDatapoint = /* GraphQL */ `
  subscription OnDeleteTrainingDatapoint(
    $filter: ModelSubscriptionTrainingDatapointFilterInput
  ) {
    onDeleteTrainingDatapoint(filter: $filter) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const onCreateOriginalPhoto = /* GraphQL */ `
  subscription OnCreateOriginalPhoto(
    $filter: ModelSubscriptionOriginalPhotoFilterInput
  ) {
    onCreateOriginalPhoto(filter: $filter) {
      id
      bucket
      key
      Make
      Model
      width
      height
      GpsStatus
      FocalLengthIn35mmFormat
      FocalLength
      ISO
      ExposureTime
      AltitudeType
      GpsLatitude
      GpsLongitude
      AbsoluteAltitude
      RelativeAltitude
      GimbalRollDegree
      GimbalYawDegree
      GimbalPitchDegree
      FlightRollDegree
      FlightYawDegree
      FlightPitchDegree
      FlightXSpeed
      FlightYSpeed
      FlightZSpeed
      CamReverse
      GimbalReverse
      HasSettings
      HasCrop
      AlreadyApplied
      FNumber
      ShutterSpeedValue
      ApertureValue
      ExposureCompensation
      MaxApertureValue
      SubjectDistance
      DigitalZoomRatio
      ExposureProgram
      GroundHeight
      SerialNumber
      chromaSubsampling
      resolutionUnit
      density
      depth
      channels
      size
      format
      orientation
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOriginalPhoto = /* GraphQL */ `
  subscription OnUpdateOriginalPhoto(
    $filter: ModelSubscriptionOriginalPhotoFilterInput
  ) {
    onUpdateOriginalPhoto(filter: $filter) {
      id
      bucket
      key
      Make
      Model
      width
      height
      GpsStatus
      FocalLengthIn35mmFormat
      FocalLength
      ISO
      ExposureTime
      AltitudeType
      GpsLatitude
      GpsLongitude
      AbsoluteAltitude
      RelativeAltitude
      GimbalRollDegree
      GimbalYawDegree
      GimbalPitchDegree
      FlightRollDegree
      FlightYawDegree
      FlightPitchDegree
      FlightXSpeed
      FlightYSpeed
      FlightZSpeed
      CamReverse
      GimbalReverse
      HasSettings
      HasCrop
      AlreadyApplied
      FNumber
      ShutterSpeedValue
      ApertureValue
      ExposureCompensation
      MaxApertureValue
      SubjectDistance
      DigitalZoomRatio
      ExposureProgram
      GroundHeight
      SerialNumber
      chromaSubsampling
      resolutionUnit
      density
      depth
      channels
      size
      format
      orientation
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOriginalPhoto = /* GraphQL */ `
  subscription OnDeleteOriginalPhoto(
    $filter: ModelSubscriptionOriginalPhotoFilterInput
  ) {
    onDeleteOriginalPhoto(filter: $filter) {
      id
      bucket
      key
      Make
      Model
      width
      height
      GpsStatus
      FocalLengthIn35mmFormat
      FocalLength
      ISO
      ExposureTime
      AltitudeType
      GpsLatitude
      GpsLongitude
      AbsoluteAltitude
      RelativeAltitude
      GimbalRollDegree
      GimbalYawDegree
      GimbalPitchDegree
      FlightRollDegree
      FlightYawDegree
      FlightPitchDegree
      FlightXSpeed
      FlightYSpeed
      FlightZSpeed
      CamReverse
      GimbalReverse
      HasSettings
      HasCrop
      AlreadyApplied
      FNumber
      ShutterSpeedValue
      ApertureValue
      ExposureCompensation
      MaxApertureValue
      SubjectDistance
      DigitalZoomRatio
      ExposureProgram
      GroundHeight
      SerialNumber
      chromaSubsampling
      resolutionUnit
      density
      depth
      channels
      size
      format
      orientation
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
  }
`;
