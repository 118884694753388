import SubscribeForm from "./SubscribeForm";

export default function MCDSignup() {
  return (
    <div className="bg-gradient-to-r from-purple-800 to-indigo-700">
      <div className="mx-auto py-5 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
        <h2 className="text-3xl font-extrabold tracking-tight text-white my-6">
          Volunteer to Help Clean Up San Francisco with Drones!
        </h2>
        <p className="text-xl text-white p-3">
          Want to make a difference in San Francisco? Join the West Coast
          Dynamics team and help clean up our city! We're looking for volunteer
          drone pilots who are passionate about making a positive impact.
        </p>
        <p className="text-xl text-white p-3">
          We've developed an automated reporting service that allows you to
          quickly identify dumping and abandoned waste. Plus, we'll teach you
          the skills you need to fly a drone and make the most of our reporting
          service.
        </p>
        <p className="text-xl text-white p-3">
          Don't miss this chance to be part of something bigger! Signup here to
          receive event announcements and learn more about how you can help
          clean up San Francisco. Together, we can make a difference!
        </p>
      </div>
      <div className="mx-auto py-5 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
      <SubscribeForm />
      </div>
    </div>
  );
}
