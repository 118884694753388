/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import React from "react";

export default function Video() {
  return (
    <div className="aspect-w-16 aspect-h-9 ">
      <iframe
        className=" shadow-xl rounded-3xl bg-indigo-700 p-0 lg:flex lg:items-center"
        src="https://www.youtube.com/embed/i-9RKqtxglk"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}
