import { useState, useCallback, useEffect } from "react";
import {
  CogIcon,
  HeartIcon,
  HomeIcon,
  PhotographIcon,
  PlusIcon as PlusIconOutline,
  ChartSquareBarIcon as ChartSquareBarIconOutline,
  UserGroupIcon,
} from "@heroicons/react/outline";
import {
  SearchIcon,
  PencilIcon,
  PlusIcon as PlusIconMini,
  MapIcon,
} from "@heroicons/react/solid";
import Geohash from "ngeohash";
import { API, Geo, graphqlOperation } from "aws-amplify";
import { useParams, useSearchParams } from "react-router-dom";
import { searchDetections } from "./graphql/queries";
import AddressSearch from "./AddressSearch";
import MyBlockMap from "./MyBlockMap";
import { createCheckAddress } from "./graphql/mutations";
import ReportedDumpsite from "./ReportedDumpsite";

const addDay = (dateInput, increment) => {
  const dateFormatToTime = new Date(dateInput);
  const newDate = new Date(dateFormatToTime.getTime() + increment * 86400000);
  return newDate.toISOString().split("T")[0];
};

const getGeoHashFromLatLong = async ({ lat, long }) => {
  const latDiff = 0.003;
  const longDiff = 0.004;
  return Geohash.bboxes(
    lat - latDiff,
    long - longDiff,
    lat + latDiff,
    long + longDiff,
    7
  );
};

const getLatLongFromAddress = async (text) => {
  if (!text?.length) return;
  const result = await Geo.searchByText(text);
  const [long, lat] = result[0]?.geometry?.point;
  return { lat, long };
};

const saveMyBlock = async (address, email) => {
  // return API.graphql({query: createCheckAddress, variables: {input: {address, email}}, authMode: "API_KEY"})
};

export default function MyBlockReportWithMap() {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [date, setDate] = useState("2022-01-01"); //
  const [rawDetections, setRawDetections] = useState([]);
  const [endDate, setEndDate] = useState(
    addDay(new Date().toISOString().replace(/T.*/, ""), 1)
  );
  const [waiting, setWaiting] = useState(false);
  const [geoHashFilter, setGeoHashFilter] = useState([]);
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(true);
  const [center, setCenter] = useState({ lat: 37.723448, long: -122.391438 });
  const [showMap, setShowMap] = useState(true);

  const getPagedResults = useCallback(
    async (date, endDate) => {
      if (!geoHashFilter?.length) return;
      setWaiting(true);

      const getResults = async (nextToken = false) => {
        let variables = {
          filter: {
            captureDatetime: {
              range: [date, endDate],
            },
            isTrash: { eq: true },
          },
          limit: 100,
        };
        if (geoHashFilter.length) {
          variables.filter.or = geoHashFilter.map((hash) => ({
            geoHash: { matchPhrasePrefix: hash },
          }));
        }
        if (nextToken) {
          variables.nextToken = nextToken;
        }
        return API.graphql({
          query: searchDetections,
          variables,
          authMode: "API_KEY",
        });
      };
      await (async () => {
        let rawResults = [];
        let nextToken = null;
        const limit = 20;
        let loops = 0;
        while (true) {
          const nextResults = await getResults(nextToken);
          rawResults = [].concat(
            rawResults,
            nextResults.data.searchDetections.items
          );
          nextToken = nextResults.data.searchDetections.nextToken;
          setRawDetections(rawResults);
          if (!nextToken || loops >= limit) {
            break;
          }
          loops++;
        }
        setRawDetections(rawResults);
        setWaiting(false);
      })();
    },
    [setRawDetections, geoHashFilter]
  );

  useEffect(() => {
    (async () => await getPagedResults(date, endDate))();
  }, [getPagedResults, date, endDate]);

  useEffect(() => {
    if (address?.length === 0) return;
    (async () => {
      const cent = await getLatLongFromAddress(address);
      const hash = await getGeoHashFromLatLong(cent);
      setCenter(cent);
      setGeoHashFilter(hash);
      await saveMyBlock(address, email);
    })();
  }, [address]);

  return (
    <>
      <div className="flex h-full w-full">
        {open && (
          <AddressSearch
            setAddress={setAddress}
            initOpen={true}
            propogateOpen={setOpen}
          />
        )}
        <div className="flex flex-1 flex-col overflow-hidden h-full w-full">
          <header className="w-full mt-3">
            <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200">
              <div className="flex flex-1 justify-center px-4 sm:px-6">
                <span className="isolate inline-flex rounded-md shadow-sm">
                  {waiting ? (
                    <button
                      disabled
                      type="button"
                      className="w-full inline-flex items-center rounded-l-md  border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#1C64F2"
                        />
                      </svg>
                      Loading...{" "}
                    </button>
                  ) : (
                    <button
                      className="w-full inline-flex items-center rounded-l-md  border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(open + 1)}
                    >
                      location
                    </button>
                  )}
                  <button
                    className={`w-full inline-flex items-center border border-transparent ${
                      showMap ? "bg-green-600" : "bg-green-700"
                    } px-6 py-3 text-base font-medium text-white shadow-sm" +
                  " hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    onClick={() => setShowMap(false)}
                  >
                    <PhotographIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />{" "}
                    photos
                  </button>
                  <button
                    className={`w-full inline-flex items-center rounded-r-md border border-transparent ${
                      !showMap ? "bg-green-600" : "bg-green-700"
                    } px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    onClick={() => setShowMap(true)}
                  >
                    <MapIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />{" "}
                    map
                  </button>
                </span>
              </div>
            </div>
            <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
              <div className="flex flex-1">
                <div className="mt-0 sm:flex items-center p-2">
                  {rawDetections?.length} piles of garbage reported and picked
                  up by DPW around {address?.replace(/, San Francisco.*/, "")}{" "}
                  in 2022.
                </div>
              </div>
            </div>
          </header>
          {showMap ? (
            <div className="space-y-0 p-0 w-full h-full">
              <MyBlockMap detections={rawDetections} centerDetection={center} />
            </div>
          ) : (
            <div className="flex flex-1 items-stretch overflow-hidden">
              <main className="flex-1 overflow-y-scroll">
                <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
                  {waiting ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <section
                      className="mt-8 pb-16"
                      aria-labelledby="gallery-heading"
                    >
                      <ul
                        role="list"
                        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                      >
                        {rawDetections
                          .sort((a, b) => {
                            return (
                              new Date(b.createdAt) - new Date(a.createdAt)
                            );
                          })
                          .map((file) => (
                            <li key={file.id} className="relative">
                              <ReportedDumpsite detection={file} />
                            </li>
                          ))}
                      </ul>
                    </section>
                  )}
                </div>
              </main>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
