import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import "./tailwind.output.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@aws-amplify/ui-react/styles.css";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import { Amplify, Analytics } from "aws-amplify";
import awsconfig from "./aws-exports";
import Thanks from "./Thanks";
import Public from "./Public";
import UploadPhotos from "./UploadPhotos";
import UserHome from "./UserHome";
import Authenticated from "./Authenticated";
import ThreeOneOneRequests from "./ThreeOneOneRequests";
import Signup from "./Signup";
import PricingPlans from "./PricingPlans";
import MapWhatever from "./MapWhatever";
import GarbageStats from "./GarbageStatistics";
import EmailLeadership from "./EmailLeadership";
import BudgetRequest from "./BudgetRequest";
import { ImageAnnotator } from "./ImageAnnotator";
import Dataset from "./Dataset";
import AnnotationWheel from "./AnnotationWheel";
import { PanzoomAerial } from "./PanzoomAerial";
import PrivacyPolicy from "./PrivacyPolicy";
import SignupMap from "./SignupMap";
import CheckAvailability from "./CheckAvailability";
import NotAvailable from "./NotAvailable";
import WereAvailable from "./WereAvailable";
import PilotSignup from "./PilotSignup";
import SignedUp from "./SignedUp";
import Subscribers from "./Subscribers";
import Pilots from "./Pilots";
import Contacts from "./Contacts";
import AvailabilityChecks from "./AvailabilityChecks";
import AnnotatorParam from "./AnnotatorParam";
import GarbageStatistics from "./GarbageStatistics";
import IllegalDumping from "./IllegalDumping";
import DroneAIData from "./DroneAIData";
import SixtyDays from "./SixtyDays";
import Blog from "./Blog";
import RequestMaker from "./RequestMaker";
import DetectionFromRouter from "./DetectionFromRouter";
import MyBlockReport from "./MyBlockReport";
import MyBlockReportWithMap from "./MyBlockReportWithMap";
import ImperfectF1Score from "./ImperfectF1Score";
import MCDSignup from "./MCDSignup";

const enableAnalytics = window.location.origin !== "http://localhost:3000";
Analytics.autoTrack("session", {
  enable: enableAnalytics,
  provider: "AWSPinpoint",
});
Analytics.autoTrack("pageView", {
  enable: enableAnalytics,
  type: "SPA",
});
Analytics.autoTrack("event", {
  enable: enableAnalytics,
});

Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      {" "}
      <Routes>
        {" "}
        <Route path="/in" element={<Authenticated />}>
          {" "}
          <Route path="panzoom/:id" element={<PanzoomAerial />} />{" "}
          <Route element={<UserHome />}>
            {" "}
            <Route path="" element={"Home"} />{" "}
            <Route
              path="signupmap"
              element={
                <SignupMap
                  center={{
                    lat: 37.73536,
                    long: -122.40717,
                  }}
                />
              }
            />{" "}
            <Route path="home" element={"Home"} />{" "}
            <Route path="311" element={<ThreeOneOneRequests />} />{" "}
            <Route path="311/geohash/:hash" element={<ThreeOneOneRequests />} />{" "}
            <Route path="stats" element={<GarbageStatistics />} />{" "}
            <Route path="upload" element={<UploadPhotos />} />{" "}
            <Route path="annotator/:id" element={<AnnotatorParam />} />{" "}
            <Route path="annotate/:id" element={<ImageAnnotator />} />{" "}
            <Route path="dataset" element={<Dataset />} />{" "}
            <Route path="dataset/:id" element={<Dataset />} />{" "}
            <Route path="annowheel" element={<AnnotationWheel />} />{" "}
            <Route
              path="annowheel/:approved/:from"
              element={<AnnotationWheel />}
            />{" "}
            <Route path="subscribers" element={<Subscribers />} />{" "}
            <Route path="pilots" element={<Pilots />} />{" "}
            <Route path="contacts" element={<Contacts />} />{" "}
            <Route path="availability" element={<AvailabilityChecks />} />{" "}
            <Route
              path="filerequest/:id"
              element={
                <DetectionFromRouter>
                  <RequestMaker />
                </DetectionFromRouter>
              }
            />{" "}
          </Route>{" "}
        </Route>{" "}
        <Route path="thanks" element={<Thanks />} />{" "}
        <Route path="/" element={<Public />}>
          {" "}
          <Route index element={<Home />} />{" "}
          <Route path="report" element={<MyBlockReportWithMap />} />{" "}
          <Route path="budget" element={<BudgetRequest />} />{" "}
          <Route path="bayview" element={<Signup />} />{" "}
          <Route path="pricing" element={<PricingPlans />} />{" "}
          <Route path="about" element={<AboutUs />} />{" "}
          <Route path="availability" element={<CheckAvailability />} />{" "}
          <Route path="contact" element={<ContactUs />} />{" "}
          <Route path="business" element={<MapWhatever />} />{" "}
          <Route path="stats" element={<GarbageStats />} />{" "}
          <Route path="leaders" element={<EmailLeadership />} />{" "}
          <Route path="privacy" element={<PrivacyPolicy />} />{" "}
          <Route path="notyet" element={<NotAvailable />} />{" "}
          <Route path="available" element={<WereAvailable />} />{" "}
          <Route path="pilots" element={<PilotSignup />} />{" "}
          <Route path="blog" element={<Blog />} />{" "}
          <Route path="illegal-dumping" element={<IllegalDumping />} />{" "}
          <Route path="drone-ai-data" element={<DroneAIData />} />{" "}
          <Route path="sixty-days" element={<SixtyDays />} />{" "}
          <Route path="imperfect-f1-score" element={<ImperfectF1Score />} />{" "}
          <Route path="signed-up" element={<SignedUp />} />{" "}
          <Route path="mcd" element={<MCDSignup />} />{" "}
          <Route path="*" element={<Home />} />{" "}
        </Route>{" "}
      </Routes>{" "}
    </Router>
  );
}

export default App;
