
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
export default function SixtyDays() {
    return (
        <div className="relative overflow-hidden bg-white py-16">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-prose text-lg">
                    <h1>
                        <span className="block text-center text-lg font-semibold text-indigo-600">Case Study</span>
                        <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            How 60 days of daily reporting led to a drastic decrease in illegal dumping in San Francisco's Bayview neighborhood</span>
                    </h1>
                    <p className="mt-8 text-xl leading-8 text-gray-500">
                        West Coast Dynamics, a community organization in San Francisco's Bayview neighborhood, decided to take action against the illegal dumping that was plaguing their community. For 60 days, they reported every dumpsite they came across to the city's Public Works Department. The result? The number of illegal dumpsites present each day in the public right of way went from 180 to just 8. This is a powerful example of how continuous reporting can lead to real, tangible results.
                    </p>
                    <div className="prose max-w-prose mx-auto mt-6 text-gray-500">
                        <h2>
                            <span className="mt-2  mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                                The Problem of Illegal Dumping in Bayview
                            </span>
                        </h2>
                        <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
                            Illegal dumping has been a long-standing problem in San Francisco's Bayview neighborhood. Every day, residents would find piles of trash and debris in the public right of way—on sidewalks, streets, and even in parks. This not only made the area look unsightly, but it also created health and safety hazards for residents. Something needed to be done.
                        </p>
                        <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
                            Enter West Coast Dynamics. This community organization decided to take matters into their own hands by documenting and reporting every illegal dumpsite they came across for 60 days. They then shared this information with the Public Works Department, which dispatched crews to clean up the sites.
                        </p>
                        <figure className="m-6">
                            <img
                                className="w-full rounded-lg"
                                src="/assets/map-before.png"
                                alt=""
                                width={1310}
                                height={873}
                            />
                            <figcaption className="text-center">Before daily reporting, garbage piled up.</figcaption>
                        </figure>
                        <h2>
                            <span className="mt-2 mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                                The Results Are In
                                </span>
                        </h2>
                        <p className="mt-8 text-xl leading-8 text-gray-500">
                            The results of this 60-day experiment were astounding. Prior to West Coast Dynamics' involvement, there were an average of 180 illegal dumpsites present each day in Bayview. But after just 60 days of continuous reporting, that number had decreased to just 8—a 95% reduction!
                            </p>
                        <figure className="m-6">
                            <img
                                className="w-full rounded-lg"
                                src="/assets/map-after.png"
                                alt=""
                                width={1310}
                                height={873}
                            />
                            <figcaption className="text-center">After 60 days of continuous reporting the number of piles of waste decreased by 95%.</figcaption>
                        </figure>
                        <p className="mt-8 text-xl leading-8 text-gray-500">
                            This goes to show that continuous reporting can lead to real, tangible results. It also highlights the importance of collaboration between community organizations and municipal departments. By working together, we can make our communities cleaner and safer for everyone.
                        </p>
                        <h2>
                            <span className="mt-2 mb-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                                Conclusion
                                </span>
                        </h2>
                        <p className="mt-8 text-xl leading-8 text-gray-500">
                            West Coast Dynamics' 60-day experiment is a powerful example of what can be accomplished when people work together for the good of their community. If you're concerned about illegal dumping in your area, reach out to your local municipality or community organization and see how you can get involved. Remember: change starts with you!
                            </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


