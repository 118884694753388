import React, { useCallback, useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { addToDataset, searchDetections } from "./graphql/queries";
import {
  ArrowRightIcon,
  BeakerIcon,
  CheckIcon,
  EyeOffIcon,
  LocationMarkerIcon,
  MapIcon,
  SelectorIcon,
} from "@heroicons/react/solid";
import {
  batchUpdateDetections,
  deleteTrainingDatapoint,
  updateDetection,
} from "./graphql/mutations";
import {
  onUpdateCleanupRequest,
  onUpdateDetection,
} from "./graphql/subscriptions";
import { Combobox } from "@headlessui/react";
import ThreeOneOneMapSpread from "./ThreeOneOneMapSpread";
import Detection from "./Detection";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftIcon, OfficeBuildingIcon } from "@heroicons/react/outline";
import { IconStar } from "@aws-amplify/ui-react";
import { findCentroid, kMeansCluster } from "./clustering";

const filterValues = [
  {
    id: 1,
    name: "all",
    filter: {},
  },
  {
    id: 2,
    name: "un-processed",
    filter: {
      status: {
        ne: "processed",
      },
    },
  },
  {
    id: 3,
    name: "filed",
    filter: {
      detectionCleanupRequestId: {
        exists: true,
      },
    },
  },
  {
    id: 4,
    name: "ignored",
    filter: {
      isTrash: {
        eq: false,
      },
    },
  },
  {
    id: 5,
    name: "filed and unfiled",
    filter: {
      or: [
        { status: { ne: "processed" } },
        { detectionCleanupRequestId: { exists: true } },
      ],
    },
  },
  {
    id: 6,
    name: "trash",
    filter: {
      isTrash: { eq: true },
    },
  },
  {
    id: 7,
    name: "training",
    filter: {
      detectionTrainingDatapointId: {
        exists: true,
      },
    },
  },
  {
    id: 8,
    name: "trash/not-training",
    filter: {
      detectionTrainingDatapointId: {
        exists: false,
      },
      isTrash: {
        eq: true,
      },
    },
  },
  {
    id: 9,
    name: "not-training",
    filter: {
      detectionTrainingDatapointId: {
        exists: false,
      },
    },
  },
];

const detectionKey = (detection) => detection?.geoHash?.slice(0, 7);

const getUnassociatedDatapoints = async () => {
  const addResult = await API.graphql(graphqlOperation(addToDataset));
};

const reshapeAsNestedDetections = (array) =>
  array.reduce((obj, item) => {
    const key = detectionKey(item);
    if (!obj[key]) {
      obj[key] = [];
    }
    obj[key].push(item);
    obj[key].sort((a, b) => a.confidence - b.confidence);
    return obj;
  }, {});

const addDay = (dateInput, increment) => {
  const dateFormatToTime = new Date(dateInput);
  const newDate = new Date(dateFormatToTime.getTime() + increment * 86400000);
  return newDate.toISOString().split("T")[0];
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ThreeOneOneRequests() {
  const { hash } = useParams();
  const [date, setDate] = useState(
    addDay(new Date().toISOString().replace(/T.*/, ""), 0)
  );
  const [rawDetections, setRawDetections] = useState([]);
  const [clustersOn, setClustersOn] = useState(false);
  const [numClusters, setNumClusters] = useState(60);
  const [clusters, setClusters] = useState([]);
  const [centroids, setCentroids] = useState([]);
  const [endDate, setEndDate] = useState(
    addDay(new Date().toISOString().replace(/T.*/, ""), 1)
  );
  const [centerDetection, setCenterDetection] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filterValues[1]);
  const [selectedMinConfidence, setSelectedMinConfidence] = useState(98);
  const [selectedMaxConfidence, setSelectedMaxConfidence] = useState(100);
  const [useConfidence, setUseConfidence] = useState(false);
  const [geoHashFilter, setGeoHashFilter] = useState(hash);

  const rotateDetectionInCluster = useCallback(
    (index, direction) => {
      const detections = clusters[index];
      if (!detections) {
        return;
      }
      switch (direction) {
        case "right":
          detections.unshift(detections.pop());
          break;
        case "left":
          detections.push(detections.shift());
          break;
      }
      const tempClusters = [...clusters];
      tempClusters[index] = detections;
      setClusters(tempClusters);
    },
    [clusters, setClusters]
  );

  function chunkArray(arr) {
    const result = [];
    for (let i = 0; i < arr.length; i += 25) {
      result.push(arr.slice(i, i + 25));
    }
    return result;
  }

  const ignoreAllInGroup = useCallback(async (allDetections) => {
    const detectionChunks = chunkArray(allDetections);
    const result = await Promise.all(
      detectionChunks.map(async (detections) => {
        const result = await API.graphql(
          graphqlOperation(batchUpdateDetections, {
            detections: detections.map((d) => {
              const {
                id,
                imageURL,
                lat,
                long,
                rotation,
                address,
                captureDatetime,
                geoHash,
                detectionCleanupRequestId,
                trainingDatapointDetectionId,
              } = d;
              return {
                id,
                imageURL,
                lat,
                long,
                rotation,
                address,
                captureDatetime,
                geoHash,
                detectionCleanupRequestId,
                trainingDatapointDetectionId,
                status: "processed",
                isTrash: false,
              };
            }),
          })
        );
        return result?.data?.batchUpdateDetections;
      })
    );
    const detections = result.reduce((prev, curr) => prev.concat(curr), []);
    return detections;
  }, []);

  const addRawDetection = useCallback(
    async (detection) => {
      const dets = [...rawDetections];
      dets.map((item) => {
        if (item.id === detection.id) {
          return detection;
        }
        return item;
      });
      setRawDetections(dets);
    },
    [rawDetections]
  );

  useEffect(() => {
    const sub = API.graphql(graphqlOperation(onUpdateCleanupRequest)).subscribe(
      {
        next: async ({ provider, value }) => {
          await addRawDetection(value.data.onUpdateCleanupRequest.detection);
        },
      }
    );
    return () => sub.unsubscribe();
  }, [addRawDetection]);

  const updateClusteredDetections = useCallback(
    async (detection) => {
      const newClusters = clusters.map((cluster, index) => {
        const detections = cluster.map((item) => {
          if (item.id === detection.id) {
            return detection;
          }
          return item;
        });
        return detections;
      });
      setClusters(newClusters);
    },
    [clusters, centroids]
  );

  useEffect(() => {
    const sub = API.graphql(graphqlOperation(onUpdateDetection)).subscribe({
      next: async ({ provider, value }) => {
        await addRawDetection(value.data.onUpdateDetection);
        const result = kMeansCluster(value.data.onUpdateDetection, numClusters);
        setClusters(sortClusters(result.clusters));
        setCentroids(result.centroids);
      },
    });
    return () => sub.unsubscribe();
  }, [addRawDetection]);

  const getPagedResults = useCallback(
    async (date, endDate) => {
      setWaiting(true);
      const confidence = useConfidence
        ? {
            gte: selectedMinConfidence,
            lte: selectedMaxConfidence,
          }
        : null;
      let variables = {
        filter: {
          ...selectedFilter.filter,
          captureDatetime: {
            range: [date, endDate],
          },
        },
        limit: 100,
        sort: [
          {
            field: "confidence",
            direction: "desc"
          }
        ]
      };
      if (geoHashFilter) {
        variables.filter.geoHash = { wildcard: `${geoHashFilter}*` };
      }
      if (confidence) {
        variables.filter.confidence = confidence;
      }
      const getResults = async (nextToken = false) => {
        if (nextToken) {
          variables.nextToken = nextToken;
        }
        return API.graphql(graphqlOperation(searchDetections, variables));
      };
      await (async () => {
        let rawResults = [];
        let nextToken = null;
        const limit = 100;
        let loops = 0;
        while (true) {
          const nextResults = await getResults(nextToken);
          rawResults = [].concat(
            rawResults,
            nextResults.data.searchDetections.items
          );
          nextToken = nextResults.data.searchDetections.nextToken;
          if (!nextToken || loops >= limit) {
            break;
          }
          loops++;
        }
        setRawDetections(rawResults);
        const result = kMeansCluster(rawResults, numClusters);
        setClusters(sortClusters(result.clusters));
        setCentroids(result.centroids);
        setWaiting(false);
      })();
    },
    [
      numClusters,
      selectedFilter,
      selectedMinConfidence,
      selectedMaxConfidence,
      useConfidence,
      geoHashFilter,
    ]
  );

  const sortClusters = (clusters) => {
    return clusters.map((cluster) => {
      return cluster.sort((a, b) => {
        if (!a.cleanupRequest?.id && b.cleanupRequest?.id) {
          return 1;
        }
        if (a.cleanupRequest?.id && !b.cleanupRequest?.id) {
          return -1;
        }
        return b.confidence > a.confidence ? 1 : -1;
      });
    });
  };

  useEffect(() => {
    const result = kMeansCluster(rawDetections, numClusters);
    setClusters(result.clusters);
    setCentroids(result.centroids);
  }, [numClusters, setCentroids, setClusters]);

  useEffect(() => {
    (async () => await getPagedResults(date, endDate))();
  }, [getPagedResults]);

  const mapClusterTransform = (clusters, centroids) => {
    return centroids.map((centroid, index) => {
      const cluster = clusters[index];
      const count = cluster.length;
      const imageURL = cluster[0]?.imageURL;
      const rotation = cluster[0]?.rotation;
      return {
        ...centroid,
        count,
        imageURL,
        rotation,
      };
    });
  };

  return (
    <>
      {!!centerDetection ? (
        <div
          className={
            "w-full h-full fixed top-0 right-0 border-l border-black shadow-xl shadown-black"
          }
          style={{
            zIndex: 1000,
          }}
        >
          <button
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="311-Map-Close"
            style={{ zIndex: 101 }}
            onClick={() => setCenterDetection(false)}
            className="fixed top-20 right-0 inline-flex items-center rounded-md border bg-red-600 px-0 py-0 text-sm font-medium text-gray-800 hover:z-10 hover:border-red-500 hover:bg-red-700 hover:outline-none hover:ring-1 hover:ring-red-500"
          >
            close
          </button>
          <ThreeOneOneMapSpread
            detections={rawDetections}
            clusters={
              clustersOn ? mapClusterTransform(clusters, centroids) : []
            }
            centerDetection={centerDetection}
          />
        </div>
      ) : null}
      <div className="px-10 py-12">
        <div
          className="fixed top-0 bg-white shadow sm:rounded-lg"
          style={{ zIndex: 2000 }}
        >
          <div className="px-0 py-0 sm:p-0">
            <form className="mt-0 sm:flex sm:items-center">
              <div className="mt-0 rounded-md bg-white shadow-sm -space-y-px">
                <div className="flex -space-x-px">
                  <div className="min-w-0 flex-1">
                    <input
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="311-Date-Change"
                      onChange={(event) => setDate(event.target.value)}
                      type="date"
                      name="date"
                      id="date"
                      className="relative block rounded-none rounded-l-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      defaultValue={date}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <input
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="311-End-Date-Change"
                      onChange={(event) => setEndDate(event.target.value)}
                      type="date"
                      name="endDate"
                      id="endDate"
                      defaultValue={endDate}
                      className="relative block rounded-none border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <input
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="311-GeoHash-Change"
                      onChange={(event) => setGeoHashFilter(event.target.value)}
                      type="text"
                      name="geoHash"
                      id="geoHash"
                      value={geoHashFilter}
                      className="relative block rounded-none border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <Combobox
                    as="div"
                    value={selectedFilter}
                    onChange={(sel) =>
                      setSelectedFilter(filterValues.find((f) => f.id === sel))
                    }
                  >
                    <div className="relative z-40" style={{ zIndex: 1000 }}>
                      <Combobox.Input
                        className="border border-gray-300 bg-white py-2 pr-10 pl-3 shadow-sm focus:border-indigo-500 focus:outline-none 
                        focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        displayValue={(filter) => filter.name}
                      />{" "}
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />{" "}
                      </Combobox.Button>{" "}
                      {filterValues.length > 0 && (
                        <Combobox.Options
                          style={{ zIndex: 1000 }}
                          className="absolute z-50 p-3 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1
                        text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                          {filterValues.map((filter) => (
                            <Combobox.Option
                              key={filter.id}
                              value={filter.id}
                              style={{ zIndex: 1000 }}
                              className={({ active }) =>
                                classNames(
                                  "relative cursor-default select-none py-2 z-50" +
                                    "pl-3 pr-9",
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      "block truncate z-50",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {filter.name}
                                  </span>
                                  {selected && (
                                    <span
                                      className={classNames(
                                        "absolute inset-y-0 right-0 flex z-50" +
                                          "items-center pr-4",
                                        active
                                          ? "text-white"
                                          : "text-indigo-600"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}
                    </div>
                  </Combobox>
                  <div className="w-1/2 min-w-0 flex-1 p-1">
                    <label htmlFor="date"> use confidence</label>
                    <input
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="useConfidenceButton"
                      onClick={(event) => {
                        setUseConfidence(!useConfidence);
                      }}
                      type="checkbox"
                      name="useConfidence"
                      id="useConfidence"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      checked={useConfidence}
                    />
                  </div>
                  {useConfidence && (
                    <Combobox
                      as="div"
                      className="w-[100px]"
                      value={selectedMinConfidence}
                      onChange={(sel) => {
                        setSelectedMinConfidence(sel);
                      }}
                    >
                      <div className="relative">
                        <Combobox.Input
                          className=" border border-gray-300 bg-white py-2 pr-1 pl-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                          displayValue={(confidence) => confidence}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {[
                            0, 10, 20, 30, 40, 50, 60, 70, 80, 85, 90, 95, 98,
                            99, 99.5, 99.9,
                          ].map((confidence) => (
                            <Combobox.Option
                              key={`confidence-${confidence}`}
                              value={confidence}
                              className={({ active }) =>
                                classNames(
                                  "relative cursor-default select-none py-2 pl-3 pr-9 w-[100px]",
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      "block truncate",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {confidence}
                                  </span>
                                  {selected && (
                                    <span
                                      className={classNames(
                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                        active
                                          ? "text-white"
                                          : "text-indigo-600"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </div>
                    </Combobox>
                  )}{" "}
                  {useConfidence && (
                    <Combobox
                      as="div"
                      className="w-[100px]"
                      value={selectedMaxConfidence}
                      onChange={(sel) => setSelectedMaxConfidence(sel)}
                    >
                      <div className="relative">
                        <Combobox.Input
                          className=" border border-gray-300 bg-white py-2 pr-1 pl-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                          displayValue={(confidence) => confidence}
                        />{" "}
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />{" "}
                        </Combobox.Button>
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {[
                            10, 20, 30, 40, 50, 60, 70, 80, 85, 90, 95, 98, 99,
                            99.5, 99.9, 100,
                          ].map((confidence) => (
                            <Combobox.Option
                              key={`confidence-${confidence}`}
                              value={confidence}
                              className={({ active }) =>
                                classNames(
                                  "relative cursor-default select-none py-2 pl-3 pr-9",
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      "block truncate ",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {confidence}
                                  </span>
                                  {selected && (
                                    <span
                                      className={classNames(
                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                        active
                                          ? "text-white"
                                          : "text-indigo-600"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </div>
                    </Combobox>
                  )}
                  <button
                    type="button"
                    className={`ml-2 relative inline-flex items-center px-2 py-1 rounded-md border bg-green-500 hover:bg-green-600 text-gray-800 text-sm font-medium hover:z-10 hover:outline-none hover:ring-1 hover:ring-green-500 hover:border-green-500`}
                    onClick={() => getPagedResults(date, endDate)}
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="311-List-Get-Results"
                  >
                    {waiting && (
                      <svg
                        className="mr-3 -ml-1 h-5 w-5 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}{" "}
                    Get Results
                  </button>
                  <button
                    type="button"
                    className={`ml-2 relative inline-flex items-center px-2 py-1
                                rounded-md border bg-red-500 hover:bg-red-600 text-gray-800
                                text-sm font-medium hover:z-10 hover:outline-none
                                hover:ring-1 hover:ring-red-500 hover:border-red-500`}
                    onClick={() => getUnassociatedDatapoints()}
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="311-List-Erase-Bad"
                  >
                    <BeakerIcon className="mr-1 ml-1 h-5 w-5" />
                  </button>
                  <button
                    onClick={() => ignoreAllInGroup(rawDetections)}
                    type="button"
                    className={`ml-2 relative inline-flex items-center px-2 py-1
                              rounded-md border bg-red-500 hover:bg-red-600 text-gray-800
                              text-sm font-medium hover:z-10 hover:outline-none
                              hover:ring-1 hover:ring-red-500 hover:border-red-500`}
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="311-List-Erase-Bad"
                  >
                    <EyeOffIcon className="mr-1 ml-1 h-5 w-5" />
                  </button>
                </div>
              </div>
            </form>
            <div className="relative block mt-4 w-full" style={{ zIndex: 500 }}>
              <span className="mx-3">
                <input
                  type="checkbox"
                  onChange={(event) => setClustersOn(event.target.checked)}
                  checked={clustersOn}
                ></input>
              </span>
              <span>Clusters: ({numClusters})</span>
              <span className="ml-3 w-3/4 z-0">
                <input
                  type="range"
                  className="w-3/4"
                  min={1}
                  max={500}
                  onChange={(event) => setNumClusters(event.target.value)}
                  value={numClusters}
                ></input>
              </span>
            </div>
          </div>
        </div>
        <div
          className={`mt-8 flex flex-col z-50 ${
            centerDetection ? "w-1/2 pr-12" : ""
          }`}
          style={{ paddingRight: centerDetection ? "150px" : "" }}
        >
          <ul className="relative grid grid-cols-1 gap-x-4 gap-y-6 xs:grid-cols-1 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-3 xl:gap-x-8">
            {(clustersOn ? clusters : rawDetections.map((d) => [d])).map(
              (detections, index) => {
                return (
                  <li key={index} className="relative col-span-1 row-span-1">
                    {clustersOn &&
                    (
                      <>
                        <div className="absolute top-0 left-1/3 bg-blue-500 rounded-full px-3 py-1">
                          {detections.length} detections
                        </div>
                        <div
                          className="absolute left-0 top-1/2 bg-white rounded-full py-1 px-3 cursor-pointer"
                          style={{ zIndex: 500 }}
                          onClick={() => {
                            rotateDetectionInCluster(index, "left");
                          }}
                        >
                          <ArrowLeftIcon className="h-5 w-5" />
                        </div>
                        <div
                          className="absolute right-0 top-1/2 bg-white rounded-full py-1 px-3 cursor-pointer"
                          style={{ zIndex: 500 }}
                          onClick={() => {
                            rotateDetectionInCluster(index, "right");
                          }}
                        >
                          <ArrowRightIcon className="h-5 w-5" />
                        </div>
                      </>
                    )}
                    {detections[0] ? (
                      <div key={0} className=" w-full h-full">
                        <span
                          style={{ zIndex: 50 }}
                          className="z-0 inline-flex overflow-visible rounded-md shadow-sm"
                        >
                          <button
                            onClick={() => setCenterDetection(detections[0])}
                            target="zoomImage"
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="311-List-Ignore"
                            type="button"
                            className={`-ml-px rounded-l-md inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-500 hover:bg-indigo-600 text-white`}
                          >
                            <MapIcon className="mr-1 ml-1 h-5 w-5" />
                          </button>
                          <button
                            onClick={async () =>
                              window.open(
                                `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${detections[0].lat},${detections[0].long}`,
                                "map"
                              )
                            }
                            target="streetImage"
                            type="button"
                            className={`-ml-px inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-500 hover:bg-indigo-600 text-white`}
                          >
                            <OfficeBuildingIcon className="mr-1 ml-1 h-5 w-5" />
                          </button>
                          <button
                            onClick={() =>
                              window.open(
                                `https://maps.google.com/?q=${detections[0]?.lat},${detections[0]?.long}&ll=${detections[0]?.lat},${detections[0]?.long}&z=20&t=k`
                              )
                            }
                            target="zoomImage"
                            type="button"
                            className={`-ml-px inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-500 hover:bg-indigo-600 text-white`}
                          >
                            <IconStar className="mr-1 ml-1 h-7 w-7" />
                          </button>
                          <Link
                            to={`/in/311/geohash/${detections[0]?.geoHash}`}
                            onClick={() =>
                              setGeoHashFilter(detections[0]?.geoHash)
                            }
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="311-List-Ignore"
                            type="button"
                            className={`-ml-px rounded-r-md inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-500 hover:bg-indigo-600 text-white`}
                          >
                            <LocationMarkerIcon className="mr-1 ml-1 h-5 w-5" />
                          </Link>
                        </span>
                        <Detection
                          showArrows={clustersOn}
                          detection={detections[0]}
                          ignoreCallback={() =>
                            rotateDetectionInCluster(index, "right")
                          }
                          trashCallback={() =>
                            rotateDetectionInCluster(index, "right")
                          }
                        />
                      </div>
                    ) : null}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
