/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const executeStateMachine = /* GraphQL */ `
  mutation ExecuteStateMachine($arguments: String!) {
    executeStateMachine(arguments: $arguments)
  }
`;
export const batchUpdateDetections = /* GraphQL */ `
  mutation BatchUpdateDetections($detections: [UpdateDetectionInput]) {
    batchUpdateDetections(detections: $detections) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const batchUpdateTrainingDatapoints = /* GraphQL */ `
  mutation BatchUpdateTrainingDatapoints(
    $trainingDatapoints: [UpdateTrainingDatapointInput]
  ) {
    batchUpdateTrainingDatapoints(trainingDatapoints: $trainingDatapoints) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const createCheckAddress = /* GraphQL */ `
  mutation CreateCheckAddress(
    $input: CreateCheckAddressInput!
    $condition: ModelCheckAddressConditionInput
  ) {
    createCheckAddress(input: $input, condition: $condition) {
      id
      address
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCheckAddress = /* GraphQL */ `
  mutation UpdateCheckAddress(
    $input: UpdateCheckAddressInput!
    $condition: ModelCheckAddressConditionInput
  ) {
    updateCheckAddress(input: $input, condition: $condition) {
      id
      address
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCheckAddress = /* GraphQL */ `
  mutation DeleteCheckAddress(
    $input: DeleteCheckAddressInput!
    $condition: ModelCheckAddressConditionInput
  ) {
    deleteCheckAddress(input: $input, condition: $condition) {
      id
      address
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSignupInterest = /* GraphQL */ `
  mutation CreateSignupInterest(
    $input: CreateSignupInterestInput!
    $condition: ModelSignupInterestConditionInput
  ) {
    createSignupInterest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      street
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSignupInterest = /* GraphQL */ `
  mutation UpdateSignupInterest(
    $input: UpdateSignupInterestInput!
    $condition: ModelSignupInterestConditionInput
  ) {
    updateSignupInterest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      street
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSignupInterest = /* GraphQL */ `
  mutation DeleteSignupInterest(
    $input: DeleteSignupInterestInput!
    $condition: ModelSignupInterestConditionInput
  ) {
    deleteSignupInterest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      street
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCheckAvailability = /* GraphQL */ `
  mutation CreateCheckAvailability(
    $input: CreateCheckAvailabilityInput!
    $condition: ModelCheckAvailabilityConditionInput
  ) {
    createCheckAvailability(input: $input, condition: $condition) {
      id
      zip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCheckAvailability = /* GraphQL */ `
  mutation UpdateCheckAvailability(
    $input: UpdateCheckAvailabilityInput!
    $condition: ModelCheckAvailabilityConditionInput
  ) {
    updateCheckAvailability(input: $input, condition: $condition) {
      id
      zip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCheckAvailability = /* GraphQL */ `
  mutation DeleteCheckAvailability(
    $input: DeleteCheckAvailabilityInput!
    $condition: ModelCheckAvailabilityConditionInput
  ) {
    deleteCheckAvailability(input: $input, condition: $condition) {
      id
      zip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      subject
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      subject
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      subject
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriber = /* GraphQL */ `
  mutation CreateSubscriber(
    $input: CreateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    createSubscriber(input: $input, condition: $condition) {
      id
      email
      subscribed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscriber = /* GraphQL */ `
  mutation UpdateSubscriber(
    $input: UpdateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    updateSubscriber(input: $input, condition: $condition) {
      id
      email
      subscribed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscriber = /* GraphQL */ `
  mutation DeleteSubscriber(
    $input: DeleteSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    deleteSubscriber(input: $input, condition: $condition) {
      id
      email
      subscribed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAerial = /* GraphQL */ `
  mutation CreateAerial(
    $input: CreateAerialInput!
    $condition: ModelAerialConditionInput
  ) {
    createAerial(input: $input, condition: $condition) {
      id
      filename
      lat
      long
      rotation
      altitude
      captureDatetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAerial = /* GraphQL */ `
  mutation UpdateAerial(
    $input: UpdateAerialInput!
    $condition: ModelAerialConditionInput
  ) {
    updateAerial(input: $input, condition: $condition) {
      id
      filename
      lat
      long
      rotation
      altitude
      captureDatetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAerial = /* GraphQL */ `
  mutation DeleteAerial(
    $input: DeleteAerialInput!
    $condition: ModelAerialConditionInput
  ) {
    deleteAerial(input: $input, condition: $condition) {
      id
      filename
      lat
      long
      rotation
      altitude
      captureDatetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImageVersions = /* GraphQL */ `
  mutation CreateImageVersions(
    $input: CreateImageVersionsInput!
    $condition: ModelImageVersionsConditionInput
  ) {
    createImageVersions(input: $input, condition: $condition) {
      id
      originalKey
      detectionKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImageVersions = /* GraphQL */ `
  mutation UpdateImageVersions(
    $input: UpdateImageVersionsInput!
    $condition: ModelImageVersionsConditionInput
  ) {
    updateImageVersions(input: $input, condition: $condition) {
      id
      originalKey
      detectionKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImageVersions = /* GraphQL */ `
  mutation DeleteImageVersions(
    $input: DeleteImageVersionsInput!
    $condition: ModelImageVersionsConditionInput
  ) {
    deleteImageVersions(input: $input, condition: $condition) {
      id
      originalKey
      detectionKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDetection = /* GraphQL */ `
  mutation CreateDetection(
    $input: CreateDetectionInput!
    $condition: ModelDetectionConditionInput
  ) {
    createDetection(input: $input, condition: $condition) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const updateDetection = /* GraphQL */ `
  mutation UpdateDetection(
    $input: UpdateDetectionInput!
    $condition: ModelDetectionConditionInput
  ) {
    updateDetection(input: $input, condition: $condition) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const deleteDetection = /* GraphQL */ `
  mutation DeleteDetection(
    $input: DeleteDetectionInput!
    $condition: ModelDetectionConditionInput
  ) {
    deleteDetection(input: $input, condition: $condition) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const createPilotSignup = /* GraphQL */ `
  mutation CreatePilotSignup(
    $input: CreatePilotSignupInput!
    $condition: ModelPilotSignupConditionInput
  ) {
    createPilotSignup(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      country
      streetAddress
      city
      region
      postalCode
      license
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePilotSignup = /* GraphQL */ `
  mutation UpdatePilotSignup(
    $input: UpdatePilotSignupInput!
    $condition: ModelPilotSignupConditionInput
  ) {
    updatePilotSignup(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      country
      streetAddress
      city
      region
      postalCode
      license
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePilotSignup = /* GraphQL */ `
  mutation DeletePilotSignup(
    $input: DeletePilotSignupInput!
    $condition: ModelPilotSignupConditionInput
  ) {
    deletePilotSignup(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      country
      streetAddress
      city
      region
      postalCode
      license
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createServiceRequest = /* GraphQL */ `
  mutation CreateServiceRequest(
    $input: CreateServiceRequestInput!
    $condition: ModelServiceRequestConditionInput
  ) {
    createServiceRequest(input: $input, condition: $condition) {
      service_request_id
      status
      status_notes
      service_name
      service_code
      description
      requested_datetime
      updated_datetime
      address
      lat
      long
      media_url
      duplicateId
      statusType
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      id
      createdAt
      updatedAt
      cleanupRequestServiceRequestsId
      __typename
    }
  }
`;
export const updateServiceRequest = /* GraphQL */ `
  mutation UpdateServiceRequest(
    $input: UpdateServiceRequestInput!
    $condition: ModelServiceRequestConditionInput
  ) {
    updateServiceRequest(input: $input, condition: $condition) {
      service_request_id
      status
      status_notes
      service_name
      service_code
      description
      requested_datetime
      updated_datetime
      address
      lat
      long
      media_url
      duplicateId
      statusType
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      id
      createdAt
      updatedAt
      cleanupRequestServiceRequestsId
      __typename
    }
  }
`;
export const deleteServiceRequest = /* GraphQL */ `
  mutation DeleteServiceRequest(
    $input: DeleteServiceRequestInput!
    $condition: ModelServiceRequestConditionInput
  ) {
    deleteServiceRequest(input: $input, condition: $condition) {
      service_request_id
      status
      status_notes
      service_name
      service_code
      description
      requested_datetime
      updated_datetime
      address
      lat
      long
      media_url
      duplicateId
      statusType
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      id
      createdAt
      updatedAt
      cleanupRequestServiceRequestsId
      __typename
    }
  }
`;
export const createCleanupRequest = /* GraphQL */ `
  mutation CreateCleanupRequest(
    $input: CreateCleanupRequestInput!
    $condition: ModelCleanupRequestConditionInput
  ) {
    createCleanupRequest(input: $input, condition: $condition) {
      id
      code
      description
      url
      serviceRequestId
      serviceToken
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      statusNotes
      status
      statusType
      serviceRequests {
        items {
          service_request_id
          status
          status_notes
          service_name
          service_code
          description
          requested_datetime
          updated_datetime
          address
          lat
          long
          media_url
          duplicateId
          statusType
          id
          createdAt
          updatedAt
          cleanupRequestServiceRequestsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      cleanupRequestDetectionId
      __typename
    }
  }
`;
export const updateCleanupRequest = /* GraphQL */ `
  mutation UpdateCleanupRequest(
    $input: UpdateCleanupRequestInput!
    $condition: ModelCleanupRequestConditionInput
  ) {
    updateCleanupRequest(input: $input, condition: $condition) {
      id
      code
      description
      url
      serviceRequestId
      serviceToken
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      statusNotes
      status
      statusType
      serviceRequests {
        items {
          service_request_id
          status
          status_notes
          service_name
          service_code
          description
          requested_datetime
          updated_datetime
          address
          lat
          long
          media_url
          duplicateId
          statusType
          id
          createdAt
          updatedAt
          cleanupRequestServiceRequestsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      cleanupRequestDetectionId
      __typename
    }
  }
`;
export const deleteCleanupRequest = /* GraphQL */ `
  mutation DeleteCleanupRequest(
    $input: DeleteCleanupRequestInput!
    $condition: ModelCleanupRequestConditionInput
  ) {
    deleteCleanupRequest(input: $input, condition: $condition) {
      id
      code
      description
      url
      serviceRequestId
      serviceToken
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      statusNotes
      status
      statusType
      serviceRequests {
        items {
          service_request_id
          status
          status_notes
          service_name
          service_code
          description
          requested_datetime
          updated_datetime
          address
          lat
          long
          media_url
          duplicateId
          statusType
          id
          createdAt
          updatedAt
          cleanupRequestServiceRequestsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      cleanupRequestDetectionId
      __typename
    }
  }
`;
export const createTrainingDatapoint = /* GraphQL */ `
  mutation CreateTrainingDatapoint(
    $input: CreateTrainingDatapointInput!
    $condition: ModelTrainingDatapointConditionInput
  ) {
    createTrainingDatapoint(input: $input, condition: $condition) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const updateTrainingDatapoint = /* GraphQL */ `
  mutation UpdateTrainingDatapoint(
    $input: UpdateTrainingDatapointInput!
    $condition: ModelTrainingDatapointConditionInput
  ) {
    updateTrainingDatapoint(input: $input, condition: $condition) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const deleteTrainingDatapoint = /* GraphQL */ `
  mutation DeleteTrainingDatapoint(
    $input: DeleteTrainingDatapointInput!
    $condition: ModelTrainingDatapointConditionInput
  ) {
    deleteTrainingDatapoint(input: $input, condition: $condition) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const createOriginalPhoto = /* GraphQL */ `
  mutation CreateOriginalPhoto(
    $input: CreateOriginalPhotoInput!
    $condition: ModelOriginalPhotoConditionInput
  ) {
    createOriginalPhoto(input: $input, condition: $condition) {
      id
      bucket
      key
      Make
      Model
      width
      height
      GpsStatus
      FocalLengthIn35mmFormat
      FocalLength
      ISO
      ExposureTime
      AltitudeType
      GpsLatitude
      GpsLongitude
      AbsoluteAltitude
      RelativeAltitude
      GimbalRollDegree
      GimbalYawDegree
      GimbalPitchDegree
      FlightRollDegree
      FlightYawDegree
      FlightPitchDegree
      FlightXSpeed
      FlightYSpeed
      FlightZSpeed
      CamReverse
      GimbalReverse
      HasSettings
      HasCrop
      AlreadyApplied
      FNumber
      ShutterSpeedValue
      ApertureValue
      ExposureCompensation
      MaxApertureValue
      SubjectDistance
      DigitalZoomRatio
      ExposureProgram
      GroundHeight
      SerialNumber
      chromaSubsampling
      resolutionUnit
      density
      depth
      channels
      size
      format
      orientation
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOriginalPhoto = /* GraphQL */ `
  mutation UpdateOriginalPhoto(
    $input: UpdateOriginalPhotoInput!
    $condition: ModelOriginalPhotoConditionInput
  ) {
    updateOriginalPhoto(input: $input, condition: $condition) {
      id
      bucket
      key
      Make
      Model
      width
      height
      GpsStatus
      FocalLengthIn35mmFormat
      FocalLength
      ISO
      ExposureTime
      AltitudeType
      GpsLatitude
      GpsLongitude
      AbsoluteAltitude
      RelativeAltitude
      GimbalRollDegree
      GimbalYawDegree
      GimbalPitchDegree
      FlightRollDegree
      FlightYawDegree
      FlightPitchDegree
      FlightXSpeed
      FlightYSpeed
      FlightZSpeed
      CamReverse
      GimbalReverse
      HasSettings
      HasCrop
      AlreadyApplied
      FNumber
      ShutterSpeedValue
      ApertureValue
      ExposureCompensation
      MaxApertureValue
      SubjectDistance
      DigitalZoomRatio
      ExposureProgram
      GroundHeight
      SerialNumber
      chromaSubsampling
      resolutionUnit
      density
      depth
      channels
      size
      format
      orientation
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOriginalPhoto = /* GraphQL */ `
  mutation DeleteOriginalPhoto(
    $input: DeleteOriginalPhotoInput!
    $condition: ModelOriginalPhotoConditionInput
  ) {
    deleteOriginalPhoto(input: $input, condition: $condition) {
      id
      bucket
      key
      Make
      Model
      width
      height
      GpsStatus
      FocalLengthIn35mmFormat
      FocalLength
      ISO
      ExposureTime
      AltitudeType
      GpsLatitude
      GpsLongitude
      AbsoluteAltitude
      RelativeAltitude
      GimbalRollDegree
      GimbalYawDegree
      GimbalPitchDegree
      FlightRollDegree
      FlightYawDegree
      FlightPitchDegree
      FlightXSpeed
      FlightYSpeed
      FlightZSpeed
      CamReverse
      GimbalReverse
      HasSettings
      HasCrop
      AlreadyApplied
      FNumber
      ShutterSpeedValue
      ApertureValue
      ExposureCompensation
      MaxApertureValue
      SubjectDistance
      DigitalZoomRatio
      ExposureProgram
      GroundHeight
      SerialNumber
      chromaSubsampling
      resolutionUnit
      density
      depth
      channels
      size
      format
      orientation
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
  }
`;
