/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addToDataset = /* GraphQL */ `
  query AddToDataset {
    addToDataset
  }
`;
export const getCheckAddress = /* GraphQL */ `
  query GetCheckAddress($id: ID!) {
    getCheckAddress(id: $id) {
      id
      address
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCheckAddresses = /* GraphQL */ `
  query ListCheckAddresses(
    $filter: ModelCheckAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCheckAddresses = /* GraphQL */ `
  query SearchCheckAddresses(
    $filter: SearchableCheckAddressFilterInput
    $sort: [SearchableCheckAddressSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCheckAddressAggregationInput]
  ) {
    searchCheckAddresses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        address
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSignupInterest = /* GraphQL */ `
  query GetSignupInterest($id: ID!) {
    getSignupInterest(id: $id) {
      id
      firstName
      lastName
      email
      street
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSignupInterests = /* GraphQL */ `
  query ListSignupInterests(
    $filter: ModelSignupInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignupInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        street
        plan
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckAvailability = /* GraphQL */ `
  query GetCheckAvailability($id: ID!) {
    getCheckAvailability(id: $id) {
      id
      zip
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCheckAvailabilities = /* GraphQL */ `
  query ListCheckAvailabilities(
    $filter: ModelCheckAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        zip
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      firstName
      lastName
      email
      phone
      subject
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        subject
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriber = /* GraphQL */ `
  query GetSubscriber($id: ID!) {
    getSubscriber(id: $id) {
      id
      email
      subscribed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscribers = /* GraphQL */ `
  query ListSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        subscribed
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAerial = /* GraphQL */ `
  query GetAerial($id: ID!) {
    getAerial(id: $id) {
      id
      filename
      lat
      long
      rotation
      altitude
      captureDatetime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAerials = /* GraphQL */ `
  query ListAerials(
    $filter: ModelAerialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAerials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        lat
        long
        rotation
        altitude
        captureDatetime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImageVersions = /* GraphQL */ `
  query GetImageVersions($id: ID!) {
    getImageVersions(id: $id) {
      id
      originalKey
      detectionKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImageVersions = /* GraphQL */ `
  query ListImageVersions(
    $filter: ModelImageVersionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        originalKey
        detectionKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchImageVersions = /* GraphQL */ `
  query SearchImageVersions(
    $filter: SearchableImageVersionsFilterInput
    $sort: [SearchableImageVersionsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableImageVersionsAggregationInput]
  ) {
    searchImageVersions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        originalKey
        detectionKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDetection = /* GraphQL */ `
  query GetDetection($id: ID!) {
    getDetection(id: $id) {
      id
      imageURL
      lat
      long
      rotation
      address
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      trainingDatapoint {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      captureDatetime
      isTrash
      status
      geoHash
      training
      confidence
      volume
      description
      createdAt
      updatedAt
      detectionCleanupRequestId
      detectionTrainingDatapointId
      __typename
    }
  }
`;
export const listDetections = /* GraphQL */ `
  query ListDetections(
    $filter: ModelDetectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDetections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDetections = /* GraphQL */ `
  query SearchDetections(
    $filter: SearchableDetectionFilterInput
    $sort: [SearchableDetectionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDetectionAggregationInput]
  ) {
    searchDetections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPilotSignup = /* GraphQL */ `
  query GetPilotSignup($id: ID!) {
    getPilotSignup(id: $id) {
      id
      firstName
      lastName
      email
      country
      streetAddress
      city
      region
      postalCode
      license
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPilotSignups = /* GraphQL */ `
  query ListPilotSignups(
    $filter: ModelPilotSignupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPilotSignups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        country
        streetAddress
        city
        region
        postalCode
        license
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchPilotSignups = /* GraphQL */ `
  query SearchPilotSignups(
    $filter: SearchablePilotSignupFilterInput
    $sort: [SearchablePilotSignupSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePilotSignupAggregationInput]
  ) {
    searchPilotSignups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        firstName
        lastName
        email
        country
        streetAddress
        city
        region
        postalCode
        license
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getServiceRequest = /* GraphQL */ `
  query GetServiceRequest($id: ID!) {
    getServiceRequest(id: $id) {
      service_request_id
      status
      status_notes
      service_name
      service_code
      description
      requested_datetime
      updated_datetime
      address
      lat
      long
      media_url
      duplicateId
      statusType
      cleanupRequest {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      id
      createdAt
      updatedAt
      cleanupRequestServiceRequestsId
      __typename
    }
  }
`;
export const listServiceRequests = /* GraphQL */ `
  query ListServiceRequests(
    $filter: ModelServiceRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        service_request_id
        status
        status_notes
        service_name
        service_code
        description
        requested_datetime
        updated_datetime
        address
        lat
        long
        media_url
        duplicateId
        statusType
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        id
        createdAt
        updatedAt
        cleanupRequestServiceRequestsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchServiceRequests = /* GraphQL */ `
  query SearchServiceRequests(
    $filter: SearchableServiceRequestFilterInput
    $sort: [SearchableServiceRequestSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableServiceRequestAggregationInput]
  ) {
    searchServiceRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        service_request_id
        status
        status_notes
        service_name
        service_code
        description
        requested_datetime
        updated_datetime
        address
        lat
        long
        media_url
        duplicateId
        statusType
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        id
        createdAt
        updatedAt
        cleanupRequestServiceRequestsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCleanupRequest = /* GraphQL */ `
  query GetCleanupRequest($id: ID!) {
    getCleanupRequest(id: $id) {
      id
      code
      description
      url
      serviceRequestId
      serviceToken
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      statusNotes
      status
      statusType
      serviceRequests {
        items {
          service_request_id
          status
          status_notes
          service_name
          service_code
          description
          requested_datetime
          updated_datetime
          address
          lat
          long
          media_url
          duplicateId
          statusType
          id
          createdAt
          updatedAt
          cleanupRequestServiceRequestsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      cleanupRequestDetectionId
      __typename
    }
  }
`;
export const listCleanupRequests = /* GraphQL */ `
  query ListCleanupRequests(
    $filter: ModelCleanupRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCleanupRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCleanupRequests = /* GraphQL */ `
  query SearchCleanupRequests(
    $filter: SearchableCleanupRequestFilterInput
    $sort: [SearchableCleanupRequestSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCleanupRequestAggregationInput]
  ) {
    searchCleanupRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        code
        description
        url
        serviceRequestId
        serviceToken
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        statusNotes
        status
        statusType
        serviceRequests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        cleanupRequestDetectionId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTrainingDatapoint = /* GraphQL */ `
  query GetTrainingDatapoint($id: ID!) {
    getTrainingDatapoint(id: $id) {
      id
      manifestURL
      datasetARN
      isApproved
      isFinished
      status
      detection {
        id
        imageURL
        lat
        long
        rotation
        address
        cleanupRequest {
          id
          code
          description
          url
          serviceRequestId
          serviceToken
          statusNotes
          status
          statusType
          createdAt
          updatedAt
          cleanupRequestDetectionId
          __typename
        }
        trainingDatapoint {
          id
          manifestURL
          datasetARN
          isApproved
          isFinished
          status
          version
          createdAt
          updatedAt
          trainingDatapointDetectionId
          __typename
        }
        captureDatetime
        isTrash
        status
        geoHash
        training
        confidence
        volume
        description
        createdAt
        updatedAt
        detectionCleanupRequestId
        detectionTrainingDatapointId
        __typename
      }
      version
      createdAt
      updatedAt
      trainingDatapointDetectionId
      __typename
    }
  }
`;
export const listTrainingDatapoints = /* GraphQL */ `
  query ListTrainingDatapoints(
    $filter: ModelTrainingDatapointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingDatapoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTrainingDatapoints = /* GraphQL */ `
  query SearchTrainingDatapoints(
    $filter: SearchableTrainingDatapointFilterInput
    $sort: [SearchableTrainingDatapointSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTrainingDatapointAggregationInput]
  ) {
    searchTrainingDatapoints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        manifestURL
        datasetARN
        isApproved
        isFinished
        status
        detection {
          id
          imageURL
          lat
          long
          rotation
          address
          captureDatetime
          isTrash
          status
          geoHash
          training
          confidence
          volume
          description
          createdAt
          updatedAt
          detectionCleanupRequestId
          detectionTrainingDatapointId
          __typename
        }
        version
        createdAt
        updatedAt
        trainingDatapointDetectionId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOriginalPhoto = /* GraphQL */ `
  query GetOriginalPhoto($id: ID!) {
    getOriginalPhoto(id: $id) {
      id
      bucket
      key
      Make
      Model
      width
      height
      GpsStatus
      FocalLengthIn35mmFormat
      FocalLength
      ISO
      ExposureTime
      AltitudeType
      GpsLatitude
      GpsLongitude
      AbsoluteAltitude
      RelativeAltitude
      GimbalRollDegree
      GimbalYawDegree
      GimbalPitchDegree
      FlightRollDegree
      FlightYawDegree
      FlightPitchDegree
      FlightXSpeed
      FlightYSpeed
      FlightZSpeed
      CamReverse
      GimbalReverse
      HasSettings
      HasCrop
      AlreadyApplied
      FNumber
      ShutterSpeedValue
      ApertureValue
      ExposureCompensation
      MaxApertureValue
      SubjectDistance
      DigitalZoomRatio
      ExposureProgram
      GroundHeight
      SerialNumber
      chromaSubsampling
      resolutionUnit
      density
      depth
      channels
      size
      format
      orientation
      DateTimeOriginal
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOriginalPhotos = /* GraphQL */ `
  query ListOriginalPhotos(
    $filter: ModelOriginalPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOriginalPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        key
        Make
        Model
        width
        height
        GpsStatus
        FocalLengthIn35mmFormat
        FocalLength
        ISO
        ExposureTime
        AltitudeType
        GpsLatitude
        GpsLongitude
        AbsoluteAltitude
        RelativeAltitude
        GimbalRollDegree
        GimbalYawDegree
        GimbalPitchDegree
        FlightRollDegree
        FlightYawDegree
        FlightPitchDegree
        FlightXSpeed
        FlightYSpeed
        FlightZSpeed
        CamReverse
        GimbalReverse
        HasSettings
        HasCrop
        AlreadyApplied
        FNumber
        ShutterSpeedValue
        ApertureValue
        ExposureCompensation
        MaxApertureValue
        SubjectDistance
        DigitalZoomRatio
        ExposureProgram
        GroundHeight
        SerialNumber
        chromaSubsampling
        resolutionUnit
        density
        depth
        channels
        size
        format
        orientation
        DateTimeOriginal
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchOriginalPhotos = /* GraphQL */ `
  query SearchOriginalPhotos(
    $filter: SearchableOriginalPhotoFilterInput
    $sort: [SearchableOriginalPhotoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOriginalPhotoAggregationInput]
  ) {
    searchOriginalPhotos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        bucket
        key
        Make
        Model
        width
        height
        GpsStatus
        FocalLengthIn35mmFormat
        FocalLength
        ISO
        ExposureTime
        AltitudeType
        GpsLatitude
        GpsLongitude
        AbsoluteAltitude
        RelativeAltitude
        GimbalRollDegree
        GimbalYawDegree
        GimbalPitchDegree
        FlightRollDegree
        FlightYawDegree
        FlightPitchDegree
        FlightXSpeed
        FlightYSpeed
        FlightZSpeed
        CamReverse
        GimbalReverse
        HasSettings
        HasCrop
        AlreadyApplied
        FNumber
        ShutterSpeedValue
        ApertureValue
        ExposureCompensation
        MaxApertureValue
        SubjectDistance
        DigitalZoomRatio
        ExposureProgram
        GroundHeight
        SerialNumber
        chromaSubsampling
        resolutionUnit
        density
        depth
        channels
        size
        format
        orientation
        DateTimeOriginal
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
