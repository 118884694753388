import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API, graphqlOperation } from "aws-amplify";
import { createSignupInterest } from "./graphql/mutations";

export default function SignupInterestForm({plan}) {
  const history = useNavigate();

  const { register, handleSubmit } = useForm();

  const submitHandler = async (data) => {
    await API.graphql(graphqlOperation(createSignupInterest, { input: data }));
    history("/thanks");
  };

  return (
    <>
      <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
        Stay in the know
      </h3>
      <p className="mt-4 text-base text-gray-500">
        Tell us your name and the address you want us to keep clean. We'll send
        you an update in a few weeks when we're ready to start cleaning.
      </p>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="mt-4 sm:max-w-md"
      >
        <div className="py-2">
          <label htmlFor="first-name" className="sr-only">
            First Name
          </label>
          <input
            type="text"
            name="first-name"
            id="first-name"
            {...register("firstName")}
            required
            className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
            placeholder="Enter your first name"
          />
        </div>
        <div className="py-2">
          <label htmlFor="last-name" className="sr-only">
            First Name
          </label>
          <input
            type="text"
            name="last-name"
            id="last-name"
            {...register("lastName")}
            required
            className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
            placeholder="Enter your last name"
          />
        </div>
        <div className="py-2">
          <label htmlFor="street" className="sr-only">
            First Name
          </label>
          <input
            type="text"
            name="street"
            id="street"
            {...register("street")}
            required
            className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
            placeholder="Enter your street name"
          />
        </div>
        <input type="hidden" name="plan" value={plan} {...register("plan")}/>
        <div className="py-2">
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            name="email-address"
            id="email-address"
            {...register("email")}
            autoComplete="email"
            required
            className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400"
            placeholder="Enter your email"
          />
        </div>
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            data-amplify-analytics-on="click"
            data-amplify-analytics-name={`Click Subscribe`}
            className="w-full flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
          >
            Subscribe
          </button>
        </div>
      </form>
    </>
  );
}
