import React, {useState} from "react";
import {useEffect, useRef} from "react";
import {createAmplifyGeocoder, createMap} from "maplibre-gl-js-amplify";
import "maplibre-gl/dist/maplibre-gl.css";
import "./maps.css";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";

const rotatePoint = ([cx, cy], [x, y]) => (degrees) => {
  const pi = Math.PI;
  degrees = degrees * (pi / 180.0);
  const s = Math.sin(degrees);
  const c = Math.cos(degrees);
  const newX = x * c - y * s;
  const newY = x * s + y * c;
  return [newX + cx, newY + cy];
};

const transformTrash = (detections) => ({
  type: "FeatureCollection", cluster: false, features: detections.map((detection) => ({
    type: "Feature", geometry: {
      type: "Point", coordinates: [detection.long, detection.lat],
    }, properties: {
      cleanup: detection?.cleanupRequest?.url, color: detection?.cleanupRequest?.url ? 'green' : 'white', imageURL: detection?.imageURL
    }
  })),
});

const transformCenterDetection = (detection) => ({
  type: "FeatureCollection", cluster: false, features: [{
    type: "Feature", geometry: {
      type: "Point", coordinates: [detection.long, detection.lat],
    },
  },],
});

function MyBlockMap({detections, centerDetection}) {

  const mapRef = useRef(null);
  const [trashMap, setTrashMap] = useState(null);

  useEffect(() => {
    let map;

    async function initializeMap() {
      if (mapRef.current != null) {
        map = await createMap({
          container: mapRef.current, center: [centerDetection.long, centerDetection.lat], zoom: 15,
        });
        setTrashMap(map);
        map.resize();
        map.on("load", function () {
          map.addSource("trash", {
            type: "geojson", cluster: true, clusterMaxZoom: 14, clusterRadius: 50, data: {
              type: "FeatureCollection", features: [],
            },
          });
          map.addLayer({
            id: "trash", type: "circle", source: "trash", filter: ["has", "point_count"], paint: {
              "circle-color": ["step", ["get", "point_count"], "#880000", 2, "#AA0000", 5, "#FF0000",], "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40,],
            },
          });
          map.addLayer({
            id: "unclustered-trash", type: "circle", source: "trash", filter: ["!", ["has", "point_count"]], paint: {
              "circle-color": "#880000", "circle-radius": 10, "circle-stroke-width": 1, "circle-stroke-color": "#fff",
            },
          });
          map?.getSource("trash")?.setData(transformCenterDetection(centerDetection));
          map?.getSource("trash")?.setData(transformTrash(detections));
        });
      }
    }
    initializeMap();

    return function cleanup() {
      if (map != null) map.remove();
    };
  }, []);

  useEffect(() => {
    if (trashMap && mapRef.current != null) {
      trashMap?.getSource("trash")?.setData(transformTrash(detections));
    }
  }, [detections]);

  useEffect(() => {
    if (trashMap && mapRef.current != null) {
      trashMap?.flyTo({
        center: [centerDetection.long, centerDetection.lat], zoom: 15
      });
    }
  }, [centerDetection]);

  return (<div className="max-w-full mx-auto px-0 h-full w-full">
    <div className="bg-white h-full w-full rounded-full">
      <div className="py-0 h-full w-full rounded-full">
        <div ref={mapRef}
          id="map"
          className="h-full w-full rounded-lg"/>
      </div>
    </div>
  </div>);
}

export default MyBlockMap;
