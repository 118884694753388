import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    ApolloProvider
} from "@apollo/client";

import {useApolloClient} from "./use-apollo-client"
import amplifyConfig from "./aws-exports";
const url = amplifyConfig.aws_appsync_graphqlEndpoint;
const region = amplifyConfig.aws_appsync_region;
const container = document.getElementById('root');
const root = createRoot(container);
const appSyncConfig = {region, url};



const Index = () => {
    const client = useApolloClient(appSyncConfig);
    return (<ApolloProvider client={client}> <App/> </ApolloProvider>)
}
root.render(<Index/>);
reportWebVitals();
