import { ImageAnnotator } from "./ImageAnnotator";
import { useParams } from "react-router-dom";

export default function AnnotatorParam() {
  const { id } = useParams();
  return (
    <div>
      <ImageAnnotator id={id} />
    </div>
  );
}
