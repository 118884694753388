import React from "react";
import Video from "./Video";

export default function Home() {
  return (
    <>
      <main>
        {/* Hero section */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"/>
          <div className="mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <video className="h-full w-full object-cover" autoPlay muted>
                  <source src="assets/takeoff.mp4" type="video/mp4"/>
                </video>
                <div
                  className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply"/>
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-12 lg:py-12 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white py-6">
                      Automated Data Provider for the Real World
                  </span>
                </h1>
                <h2 className="text-center py-2-4 text-3xl font-extrabold tracking-tight sm:text-4xl lg:text-5xl">
                  <span className="block text-indigo-200">
                      Using AI and Drones
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
            <Video/>
          </div>
        </div>
        {/* Alternating Feature Sections */}
        <div className="relative pt-12 pb-10 overflow-hidden">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
          />
          <div className="relative">
            <div
              className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Starting with illegal dumping
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Illegal dumping is a major problem for cities around the world, and though
                      there have been attempts to address it through various solutions, the issue
                      remains. City-cleaning crews are forced to patrol streets in search of
                      illegal dumping, but they can only take in so much. What they need is a
                      complete picture of every dumped item throughout their entire city so that
                      they can act quickly and efficiently.
                    </p>

                    <p className="mt-4 text-lg text-gray-500">
                      To this end, our service provides high frequency reporting of every illegal
                      dumping or abandoned waste occurrence in your entire city with exact
                      locations as well as detailed aerial images. By giving cleaning crews this
                      all-encompassing insight into illegal dumping sites, they can then act with
                      confidence to maximize their efforts and resources.

                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      Along with helping to reduce crime, protecting the environment will be an
                      important step taken by all those involved in order to provide cleaner
                      streets, more vibrant neighborhoods, and promote healthier societies for
                      everyone's benefit. In this way, our service plays an integral role in
                      helping cities clean up their spaces and ensure that residents everywhere
                      can feel safe and secure.
                    </p>
                    <div className="mt-6">
                      <a
                        href="https://usemotion.com/meet/everyone-codes/f9239n6?d=30"
                        className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border
                          px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white
                          hover:from-purple-700 hover:to-indigo-700"
                      >
                        Let's start the process
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 sm:mt-2 lg:mt-0">
                <img
                  className="overflow-hidden w-full h-auto rounded-xl border-transparent ring-1 ring-transparent lg:right-0 bg-transparent"
                  src="assets/Trash_visual.png"
                  alt="Inbox user interface"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative pt-10 pb-10 overflow-hidden">
          <div className="mt-5">
            <div
              className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div
                className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      See the big picture
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Keeping cities clean and free of illegal dumping is essential for a healthy
                      environment and community. Traditional methods of cleaning up can be
                      labor-intensive and time-consuming. To address this problem, our technology
                      helps to create a more efficient and effective route for cleaning crews.
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      Through our technology, cities can receive comprehensive and irrefutable
                      location and imagery data on illegal dumping cases, allowing them to take
                      immediate action. Our mapping tools provide a detailed visualization of the
                      area by pinpointing exact locations while providing the capacity to analyze
                      trends over time. This way, cities can easily identify heavy usage areas or
                      neighborhoods in need of extra effort when going on clean-up duty.
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      Plus, our technology integrates with Open311, SeeClickFix, or ArcGIS so city
                      cleaning crews don’t need to modify their existing processes. By leveraging
                      our technology, cities nationwide can be better equipped to handle illegal
                      dumping cases quickly and effectively as well as make informed decisions
                      about allocation of resources for day-to-day operations. Ultimately, we are
                      giving cities the power to protect their environment for generations to
                      come.
                    </p>

                    <p className="mt-4 text-lg text-gray-500"></p>
                    <div className="mt-6">
                      <a
                        href="https://usemotion.com/meet/everyone-codes/f9239n6?d=30"
                        className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border
                          px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white
                          hover:from-purple-700 hover:to-indigo-700"
                      >
                        Learn how we can help
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <div
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none">
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                      alt="static Mapbox map of the San Francisco bay area"
                      src="assets/5-20-22_piles.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative pt-12 pb-10 overflow-hidden">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
          />
          <div className="relative">
            <div
              className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Residents are relieved
                  </h2>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        &ldquo;Thanks for helping to keep our neighborhood clean
                        😊&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="text-base font-medium text-gray-700">
                          Anastasia W., neighbor
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        &ldquo;This is a fantastic project! &rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="text-base font-medium text-gray-700">
                          Linda M., neighbor
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
                <div className="mt-6">
                  <a
                    href="https://usemotion.com/meet/everyone-codes/f9239n6?d=30"
                    className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border
                          px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white
                          hover:from-purple-700 hover:to-indigo-700"
                  >
                    Find out more
                  </a>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div
                  className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full w-full ">
                  <img
                    className="w-full rounded-xl border-transparent ring-1 ring-transparent lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none bg-transparent"
                    src="assets/people.jpg"
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
