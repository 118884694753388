/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
export default function IllegalDumping() {
    return (
        <div className="relative overflow-hidden bg-white py-16">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-prose text-lg">
                    <h1>
                        <span className="block text-center text-lg font-semibold text-indigo-600">Prevention</span>
                        <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            Preventing Illegal Dumping:</span>
                    </h1>
                    <h2>
                        <span className="mt-2  mb-2 block text-center text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-3xl">
                            The Importance of Prompt Cleanup
                            </span>
                    </h2>
                    <p className="mt-8 text-xl leading-8 text-gray-500">
                        Keeping our environment clean is a joint effort. We can all do our part by properly disposing of our waste and recycling as much as possible. However, there are always those people who choose to illegally dump their trash instead of disposing of it properly. This not only pollutes our environment but also creates an eyesore in our neighborhoods. That's why it's so important for everyone to pitch in and help clean up illegal dumpsites as soon as they're discovered.</p>
                    <div className="prose max-w-prose mx-auto mt-6 text-gray-500">
                        <h2>
                            <span className="mt-2  mb-2 block text-center text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-3xl">
                                What is Illegal Dumping?
                                </span>
                        </h2>
                        <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
                            Illegal dumping is the act of depositing solid waste on public or private property without the consent of the owner/operator. Common examples of illegal dumping include dumping old furniture, appliances, tires, construction debris, and household trash in vacant lots, parks, forests, along roadsides, or in waterways. Not only is illegal dumping unsightly, but it can also lead to environmental contamination and degradation, entrapment and injury of wildlife, and spread of disease.</p>
                        <figure>
                            <img
                                className="w-full rounded-lg"
                                src="/assets/img.png"
                                alt=""
                                width={1310}
                                height={873}
                            />
                            <figcaption className="text-center">Large illegal dumpsites exist often just out of sight.</figcaption>
                        </figure>
                        <h2>
                            <span className="mt-2 mb-2 block text-center text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-3xl">
                                Why is Prompt Cleanup Important?
                                </span>
                        </h2>
                        <p className="mt-8 text-xl leading-8 text-gray-500">Cleaning up illegal dumpsites promptly is important for several reasons. First, the longer the waste sits there, the greater the chances that it will spread and contaminate other areas. Second, if the site is not cleaned up quickly, it becomes more difficult and expensive to do so later on. Third, prompt cleanup sends a strong message to would-be illegal dumpers that their actions will not be tolerated. Finally, by working together to clean up these sites, we can help keep our environment and neighborhoods healthy and beautiful places to live.</p>
                        <h2>
                            <span className="mt-2 mb-2 block text-center text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-3xl">
                                Conclusion
                                </span>
                                </h2>
                        <p className="mt-8 text-xl leading-8 text-gray-500">No one likes seeing litter in their neighborhood or finding an illegal dumpsite on their morning jog. Not only are these eyesores, but they can also lead to environmental contamination and degradation. That's why it's so important for everyone to help with the cleanup effort by pitching in and removing any trash they see as soon as possible. By working together, we can keep our environment clean and safe for everyone.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

